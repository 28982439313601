import { useCallback, useEffect, useRef, useState } from "react";

interface UseCountdown {
  initialValue: number;
  tickMs: number;
  onFinish: () => void;
}

export const useCountdown = ({ initialValue, tickMs, onFinish }: UseCountdown) => {
  const [counter, setCounter] = useState(initialValue);
  const interval = useRef<NodeJS.Timeout | null>(null);

  const startTimer = useCallback(() => {
    interval.current = setInterval(() => {
      setCounter((prev) => prev - 1);
    }, tickMs);
  }, [tickMs]);

  const stopTimer = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
      setCounter(initialValue);
      interval.current = null;
    }
    onFinish();
  }, [initialValue, onFinish]);

  useEffect(() => {
    if (interval.current && counter > 0) {
      startTimer();
    }

    if (counter <= 0) {
      stopTimer();
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [counter, startTimer, stopTimer]);

  return [counter, startTimer, stopTimer] as const;
};
