import { ReactNode } from "react";
import clsx from "clsx";
import "./contest-card.scss";

interface ContestCardProps {
  className?: string;
  children: ReactNode;
}

export const ContestCard: React.FC<ContestCardProps> = ({ className, children }) => {
  return <div className={clsx("contest-card", className)}>{children}</div>;
};
