import { SVGProps } from "react";

export const OnboardingBudgetCupboardSVG = ({
  ...restProps
}: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={144.64} height={169.999} {...restProps}>
    <g data-name="Group 330" transform="translate(-2083.11 -4151.045)">
      <path
        data-name="Rectangle 312"
        d="M2087.872 4151.045h34.8v97.851h-34.8a4.762 4.762 0 0 1-4.762-4.762v-88.327a4.762 4.762 0 0 1 4.762-4.762Z"
        fill="#86a2e2"
      />
      <path
        data-name="Rectangle 313"
        d="M2122.67 4151.045h100.318a4.762 4.762 0 0 1 4.762 4.762v88.327a4.762 4.762 0 0 1-4.762 4.762H2122.67v-97.851Z"
        fill="#aec3ff"
      />
      <path
        data-name="Rectangle 314"
        fill="#d2dcff"
        d="M2133.623 4165.1h83.174v31.205h-83.174z"
      />
      <path
        data-name="Rectangle 315"
        fill="#d2dcff"
        d="M2133.623 4203.635h83.174v31.205h-83.174z"
      />
      <rect
        data-name="Rectangle 316"
        width={32.256}
        height={2.66}
        rx={1.107}
        transform="translate(2159.083 4187.826)"
        fill="#aec3ff"
      />
      <rect
        data-name="Rectangle 317"
        width={32.256}
        height={2.66}
        rx={1.107}
        transform="translate(2159.083 4226.361)"
        fill="#aec3ff"
      />
      <path
        data-name="Path 949"
        d="m2096.671 4248.9-1.559 11.042-8.627 61.106h-3.375l5.761-61.3 1.019-10.844Z"
        fill="#d2dcff"
      />
      <path
        data-name="Path 950"
        d="m2136.911 4248.9-1.739 12.313-8.448 59.834h-3.375l5.641-60.031 1.139-12.117Z"
        fill="#d2dcff"
      />
      <path
        data-name="Path 951"
        d="M2227.75 4321.044h-3.375l-8.084-57.261-2.1-14.887h6.78l1.417 15.08Z"
        fill="#d2dcff"
      />
      <path
        data-name="Path 952"
        d="M2187.51 4321.044h-3.375l-8.265-58.543-1.921-13.6h6.781l1.3 13.8Z"
        fill="#d2dcff"
      />
      <path
        data-name="Path 953"
        d="M2089.891 4248.9h6.78l-1.559 11.042-6.241-.2Z"
        fill="#aec3ff"
      />
      <path
        data-name="Path 954"
        d="M2130.131 4248.9h6.781l-1.739 12.313-6.181-.2Z"
        fill="#aec3ff"
      />
      <path
        data-name="Path 955"
        d="m2180.73 4248.9 1.3 13.8-6.157-.2-1.921-13.6Z"
        fill="#aec3ff"
      />
      <path
        data-name="Path 956"
        d="m2222.387 4263.976-6.1-.193-2.1-14.887h6.78Z"
        fill="#aec3ff"
      />
    </g>
  </svg>
);
