import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "components/button";
import { setSelectedSlate } from "store/app-slice";
import { selectAuthPayload } from "store/auth";
import {
  fetchMakePicksUsersDetailsAndParlay,
  selectMakePicksRequestState,
  selectParlay,
  selectSelectedMyContest,
  selectUsersDetails,
} from "store/my-contest-slice";
import { DateFormats } from "helpers/constants";
import { dateToLocalTime } from "helpers/date-to-local-time";
import { formatToCurrency } from "helpers/format-to-currency";
import { formatToPoints } from "helpers/format-to-points";
import { formatValueToDisplay } from "helpers/format-value-to-display";
import { moveCursorToEnd } from "helpers/move-cursor";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useEscapePress } from "hooks/use-escape-press";
import { TeamType, WagerTypes } from "types/contest";
import { ContestsPickDetails, RequestState } from "types/request";
import "./select-odd.scss";

interface SelectOddProps {
  betType: WagerTypes;
  team: TeamType;
  tournament: ContestsPickDetails;
  contestId: number;
}

export const SelectOdd: React.FC<SelectOddProps> = ({
  betType,
  team,
  tournament,
  contestId,
}) => {
  const [oddValue, setOddValue] = useState("");
  const { username } = useAppSelector(selectAuthPayload);
  const usersDetails = useAppSelector(selectUsersDetails);
  const selectedContest = useAppSelector(selectSelectedMyContest);
  const makePicksRequestState = useAppSelector(selectMakePicksRequestState);
  const isLoading = RequestState.PENDING === makePicksRequestState;
  const parlay = useAppSelector(selectParlay);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const {
    gameId,
    homeTeamPointSpread,
    homeTeamMoneyline,
    awayTeamMoneyline,
    noSpreadHomeTeamMoneyline,
    noSpreadAwayTeamMoneyline,
    awayTeamPointSpread,
    overUnder,
    overPayout,
    underPayout,
  } = tournament;

  useEffect(() => {
    if (parlay && selectedContest) {
      const alreadyPlacedBet = parlay.wagers.find(
        (wager) =>
          parseInt(wager.gameId) === gameId &&
          wager.wagerType === `${Object.values(WagerTypes).indexOf(betType)}` &&
          wager.chosenTeam === team &&
          parlay.parlay.name === selectedContest.contest.name
      );
      if (alreadyPlacedBet) {
        setOddValue(
          formatToCurrency({ value: alreadyPlacedBet.amount, withDollar: false })
        );
      }
    }
  }, [betType, parlay, team, gameId, selectedContest]);

  const convertLocaleStringToNumber = (value: string) => {
    return parseFloat(value.replaceAll(",", ""));
  };

  const availableTD = useMemo(() => {
    if (usersDetails) {
      const currentUser = usersDetails.find((user) => user.userName === username);
      if (currentUser) {
        return currentUser.remainingBudget;
      }
    }

    return 0;
  }, [username, usersDetails]);

  const minBet = useMemo(() => {
    if (selectedContest) {
      const contestBudget = selectedContest.contest.perContestantBudget;

      let minimal = availableTD;
      if ((availableTD / contestBudget) * 100 >= 10) {
        minimal = Math.round(contestBudget * 0.05);
      }

      return minimal;
    }
    return 0;
  }, [availableTD, selectedContest]);

  const minimalAmountToNextBet = useMemo(() => {
    if (selectedContest) {
      const contestBudget = selectedContest.contest.perContestantBudget;
      const minimalBet = availableTD - contestBudget * 0.05;
      if (minimalBet > minBet) {
        return minimalBet;
      } else if (minimalBet <= minBet) {
        return minBet;
      }
    }

    return 0;
  }, [availableTD, minBet, selectedContest]);

  const handleOddValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const valueText = event.target.value.replace(/,/g, "");
    const parsedValue = parseFloat(valueText);

    let resultValue;
    if (valueText === "") {
      resultValue = "";
    } else if (parsedValue >= availableTD) {
      resultValue = availableTD;
    } else if (isNaN(Number(valueText))) {
      return;
    } else {
      const [integer, decimal] = valueText.split(".");

      resultValue = Math.abs(parseInt(integer)).toLocaleString("en-Us");

      if (decimal !== undefined) {
        if (integer === "") {
          resultValue = `0.${decimal.substring(0, 3)}`;
        } else {
          resultValue = `${resultValue}.${decimal.substring(0, 3)}`;
        }
      }
    }

    const convertedResultValue = resultValue.toLocaleString("en-Us");

    setOddValue(convertedResultValue);
    moveCursorToEnd(inputRef);
  };

  let leftValue, rightValue;

  switch (betType) {
    case WagerTypes.MONEYLINE:
      leftValue = "MoneyLine";
      rightValue =
        team === TeamType.HOME ? noSpreadHomeTeamMoneyline : noSpreadAwayTeamMoneyline;
      break;
    case WagerTypes.POINTS_SPREAD:
      leftValue =
        team === TeamType.HOME
          ? formatValueToDisplay(homeTeamPointSpread)
          : formatValueToDisplay(awayTeamPointSpread);
      rightValue = team === TeamType.HOME ? homeTeamMoneyline : awayTeamMoneyline;
      break;
    case WagerTypes.TOTAL:
      leftValue = team === TeamType.OVER ? `Over ${overUnder}` : `Under ${overUnder}`;
      rightValue = team === TeamType.OVER ? overPayout : underPayout;
      break;
  }

  const calcPayout = (modifier: number, bet: string) => {
    if (bet === "") {
      bet = "0";
    }

    if (modifier >= 0) {
      return (convertLocaleStringToNumber(bet) * Math.abs(modifier)) / 100;
    }
    return convertLocaleStringToNumber(bet) / (Math.abs(modifier) / 100);
  };

  const isOddValueCorrect = (value: string) => {
    const placedValue = convertLocaleStringToNumber(value);
    if (+value === 0) {
      toast.error("You have to bet at least 5% of your total budget");
      return false;
    }
    if (placedValue < minBet) {
      if (minBet === availableTD) {
        toast.error(
          "You won’t be able to make next bet, use your whole remaining budget"
        );
      } else if (minBet !== availableTD) {
        toast.error("You have to bet at least 5% of your total budget");
      }
      return false;
    } else if (placedValue < availableTD && placedValue > minimalAmountToNextBet) {
      toast.error(
        "You won’t be able to make next bet, use the entire remaining budget or a smaller value"
      );
      return false;
    }
    if (isNaN(placedValue)) {
      toast.error("You have to set correct amount");
      return false;
    } else {
      return true;
    }
  };

  useEscapePress(() => dispatch(setSelectedSlate(null)));

  const onPlaceBet = async () => {
    if (isOddValueCorrect(oddValue)) {
      const wagerType = Object.values(WagerTypes).indexOf(betType);
      const contestWagers = [
        {
          gameId: gameId,
          chosenTeam: team,
          wagerType: wagerType,
          wagerAmount: convertLocaleStringToNumber(oddValue),
        },
      ];
      await dispatch(fetchMakePicksUsersDetailsAndParlay({ contestId, contestWagers }));
      dispatch(setSelectedSlate(null));
    }
  };

  return (
    <div className="select-odd">
      <div className="select-odd__details">
        <div className="select-odd__bet-name">
          <span>{leftValue}</span>
          <span>{rightValue}</span>
        </div>
        <div className="select-odd__match-details">
          <span>{`${tournament.homeName} @ ${tournament.awayName}`}</span>
          <div className="select-odd__bet-details-date">
            {dateToLocalTime(tournament.gameDate).format(DateFormats.TIME)}
          </div>
        </div>
      </div>
      <div className="select-odd__budget">
        <div className="select-odd__budget-label">AVAILABLE</div>
        <div className="select-odd__budget-value">
          {formatToPoints({
            value: availableTD,
            separator: "comma",
            withSuffix: true,
          })}
        </div>
      </div>
      <div className="select-odd__content">
        <div className="select-odd__form-input-wrapper">
          <div>AMOUNT</div>
          <div className="select-odd__form-input">
            <input
              placeholder="0"
              className="select-odd__form-input-field"
              ref={inputRef}
              value={oddValue}
              onChange={(event) => handleOddValueChange(event)}
              maxLength={12}
            />
            TD
          </div>
        </div>
        <div className="select-odd__form-button-wrapper">
          <Button
            className="select-odd__form-button"
            onClick={() => {
              setOddValue(formatToCurrency({ value: minBet, withDollar: false }));
            }}
          >
            MIN
          </Button>
          <Button
            className="select-odd__form-button"
            onClick={() => {
              setOddValue(formatToCurrency({ value: availableTD, withDollar: false }));
            }}
          >
            MAX
          </Button>
        </div>

        <div className="select-odd__summary-wrapper">
          <div className="select-odd__summary">
            <div className="select-odd__bet-value">
              <span className="select-odd__value">{`${
                oddValue === "" ? "0" : oddValue
              } TD`}</span>
              <span className="select-odd__label">TOTAL WAGER</span>
            </div>
            <div className="select-odd__bet-value">
              <span className="select-odd__value">
                {formatToPoints({
                  value: calcPayout(rightValue, oddValue),
                  separator: "comma",
                  withSuffix: true,
                })}
              </span>
              <span className="select-odd__label">TO WIN</span>
            </div>
          </div>
        </div>
      </div>
      <div className="select-odd__footer">
        <Button
          variant="link"
          onClick={() => dispatch(setSelectedSlate(null))}
          className="select-odd__cancel"
        >
          CANCEL
        </Button>
        <Button loader={isLoading} className="select-odd__place-bet" onClick={onPlaceBet}>
          PLACE BET
        </Button>
      </div>
    </div>
  );
};
