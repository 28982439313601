import { ContestContent, ContestsList } from "components/contest";
import { RouterNavbar } from "components/router-navbar";
import { PathsMyContests } from "types/router";
import "./my-contests-md-content.scss";

export const MyContestsMdContent: React.FC = () => {
  return (
    <div className="my-contests-md-content">
      <div className="my-contests-md-content__contests-list">
        <RouterNavbar
          tabs={[
            {
              label: "In progress",
              url: PathsMyContests.IN_PROGRESS_ABSOLUTE,
              uniqueStringPath: PathsMyContests.ACTIVE,
            },
            {
              label: "Previous",
              url: PathsMyContests.PREVIOUS_ABSOLUTE,
              uniqueStringPath: PathsMyContests.PREVIOUS,
            },
          ]}
        />
        <ContestsList />
      </div>
      <div className="my-contests-md-content__contest-details">
        <ContestContent />
      </div>
    </div>
  );
};
