import { getSelectedTeamName } from "helpers/get-selected-team-name";
import { APIWagerTypes, TeamType } from "types/contest";
import { Wager } from "types/request";

export type WagerModifierFields = Pick<
  Wager,
  "wagerType" | "pointSpread" | "chosenTeam" | "overUnder" | "homeTeam" | "awayTeam"
>;

export const getWagerModifier = (wager: WagerModifierFields) => {
  const selectedTeamName = getSelectedTeamName(wager);

  switch (wager.wagerType) {
    case APIWagerTypes.SPREAD:
      return wager.pointSpread > 0
        ? `${selectedTeamName}: +${wager.pointSpread}`
        : `${selectedTeamName}: ${wager.pointSpread}`;
    case APIWagerTypes.MONEYLINE:
      return selectedTeamName;
    case APIWagerTypes.TOTAL:
      if (wager.chosenTeam === TeamType.OVER) {
        return `Total: O ${wager.overUnder}`;
      }
      return `Total: U ${wager.overUnder}`;

    default:
      return "";
  }
};
