import { SVGProps } from "react";

export const WaveTransition = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={256}
    height={51}
    viewBox="0 0 256 51"
    {...restProps}
    fill="currentColor"
  >
    <path d="M256 1.6c-4 3.1-17.9 9.6-26.6 12.4-4.9 1.6-16.4 4.3-25.6 6-19 3.5-32.4 4.4-86.3 6-44.2 1.3-60.9 3.4-84.9 10.6C25.3 39.3 9.5 45.7 3.2 49l-3.7 2H260V25.5c0-14-.1-25.5-.2-25.5-.2 0-1.2.8-2.3 1.6z" />
  </svg>
);
