import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from "dayjs";
import { BottomNavigation } from "components/bottom-navigation";
import { CommonForm } from "components/common-form";
import { CodeInput } from "components/inputs";
import { selectRegisterUser, setBirthDate } from "store/register-slice";
import { REGEX_DAY, REGEX_MONTH, REGEX_YEAR } from "helpers/constants";
import { hasLeast18Years } from "helpers/has-least-18-years";
import { isDateValid } from "helpers/is-date-valid";
import { useAppDispatch } from "hooks/store";
import { Paths } from "types/router";
import "./birthdate-selector.scss";

interface BirthdateInput {
  month: string;
  day: string;
  year: string;
}

interface Errors {
  isUnder18: boolean;
  isInvalidDate: boolean;
}

interface BirthdateSelectorProps {
  onNextClicked: () => void;
}

export const BirthdateSelector: React.FC<BirthdateSelectorProps> = ({
  onNextClicked,
}) => {
  const [isNextButtonActive, setIsNextButtonActive] = useState(false);
  const dispatch = useAppDispatch();
  const { birthDate } = useSelector(selectRegisterUser);

  const [dateErrors, setDateErrors] = useState<Errors>({
    isInvalidDate: false,
    isUnder18: false,
  });

  const {
    register,
    getValues,
    setFocus,
    trigger,
    setValue,
    handleSubmit,
    formState,
    formState: { errors },
    reset,
  } = useForm<BirthdateInput>({
    defaultValues: { day: "", month: "", year: "" },
    mode: "onTouched",
    criteriaMode: "firstError",
  });

  const validateDateErrors = useCallback(
    (year: string, month: string, day: string) => {
      if (!isDateValid(year, month, day)) {
        setDateErrors({ ...dateErrors, isInvalidDate: true });
      } else if (!hasLeast18Years(year, month, day)) {
        setDateErrors({ ...dateErrors, isUnder18: true });
      } else {
        setDateErrors({ isInvalidDate: false, isUnder18: false });
      }
    },
    [dateErrors]
  );

  const validateInputs = useCallback(() => {
    const { month, day, year } = getValues();
    if (month && day && year) {
      if (
        isDateValid(year, month, day) &&
        hasLeast18Years(year, month, day) &&
        Object.entries(errors).length === 0
      ) {
        dispatch(setBirthDate({ year, month, day }));
        setIsNextButtonActive(true);
        setDateErrors({ isInvalidDate: false, isUnder18: false });
      } else {
        validateDateErrors(year, month, day);
      }
    }
  }, [dispatch, errors, getValues, validateDateErrors]);

  const handleBlur = (inputType: keyof BirthdateInput) => {
    const { month, day } = getValues();
    if (inputType === "month" && month.length === 1) setValue("month", `0${month}`);
    else if (inputType === "day" && day.length === 1) setValue("day", `0${day}`);
    validateInputs();
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length >= 2) {
      setFocus("day");
      validateInputs();
    } else {
      setIsNextButtonActive(false);
    }
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length >= 2) {
      setFocus("year");
      validateInputs();
    } else {
      setIsNextButtonActive(false);
    }
  };

  const handleYearChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length === 4) {
      await trigger("year");
      validateInputs();
    } else {
      setIsNextButtonActive(false);
    }
  };

  const getFirstMessage = () => Object.entries(errors).at(0)?.[1].message;

  const handleNextClick = (
    event?: React.BaseSyntheticEvent<object, unknown, unknown>
  ) => {
    if (event) {
      event.preventDefault();
    }
    validateInputs();
    if (
      !Object.entries(errors).length &&
      Object.values(dateErrors).every((error) => !error)
    ) {
      onNextClicked();
    }
  };

  const getErrorMessage = () => {
    if (dateErrors.isInvalidDate) {
      return "Invalid date";
    } else if (dateErrors.isUnder18) {
      return "Age is under 18";
    }
    return "";
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState.isSubmitSuccessful, reset]);

  useEffect(() => {
    const { day, month, year } = birthDate;
    if (day && month && year) {
      setIsNextButtonActive(true);
    }
  }, [birthDate]);

  return (
    <div className="birthdate-selector">
      <CommonForm
        title="What's your date of birth?"
        subtitle="BMA is a Free-to-Play daily tournament sports betting application. You
          must be at least 18 years of age to play"
        onSubmit={handleSubmit((_, event) => handleNextClick(event))}
        footer={
          <BottomNavigation
            nextButtonActive={isNextButtonActive}
            cancelPath={Paths.SIGN_UP}
            cancelText="CANCEL"
            isSubmitType
          />
        }
      >
        <div className="birthdate-selector__selector">
          <CodeInput
            placeholder="MM"
            maxLength={2}
            className={clsx("birthdate-selector__input", {
              "birthdate-selector__input--error": errors.month,
            })}
            replaceMode
            {...register("month", {
              onChange: handleMonthChange,
              onBlur: () => handleBlur("month"),
              required: {
                value: true,
                message: "Month cannot be empty",
              },
              pattern: {
                value: REGEX_MONTH,
                message: "Invalid month",
              },
            })}
          />
          <CodeInput
            placeholder="DD"
            maxLength={2}
            className={clsx("birthdate-selector__input", {
              "birthdate-selector__input--error": errors.day,
            })}
            replaceMode
            {...register("day", {
              onChange: handleDayChange,
              onBlur: () => handleBlur("day"),
              pattern: { value: REGEX_DAY, message: "Invalid day" },
              required: {
                value: true,
                message: "Day cannot be empty",
              },
            })}
          />
          <CodeInput
            placeholder="YYYY"
            maxLength={4}
            className={clsx(
              "birthdate-selector__input",
              "birthdate-selector__input--large",
              {
                "birthdate-selector__input--error": errors.year,
              }
            )}
            replaceMode
            {...register("year", {
              onChange: handleYearChange,
              onBlur: () => handleBlur("year"),
              pattern: { value: REGEX_YEAR, message: "Invalid year" },
              min: {
                value: dayjs().year() - 150,
                message: "Age cannot be higher than 150",
              },
              max: { value: dayjs().year(), message: "Year is out of range" },
              required: {
                value: true,
                message: "Year cannot be empty",
              },
            })}
          />
        </div>
        <p className="birthdate-selector__error-label">
          {getFirstMessage() || getErrorMessage()}
        </p>
      </CommonForm>
    </div>
  );
};
