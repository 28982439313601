import { ContestCard, ContestFooter } from "components/contest";
import { ScrollWrapper } from "components/scroll-wrapper";
import { selectSelectedContest } from "store/app-slice";
import { formatToCurrency } from "helpers/format-to-currency";
import { formatToPosition } from "helpers/format-to-position";
import { parsePrizesString } from "helpers/parse-prizes";
import { useAppSelector } from "hooks/store";
import "./contest-prizes.scss";

export const ContestPrizes: React.FC = () => {
  const selectedContest = useAppSelector(selectSelectedContest);
  if (!selectedContest) {
    return null;
  }

  const parsedPrizes = parsePrizesString(selectedContest.contest.distributedPrizes || "");
  return (
    <div className="contest-prizes">
      <ScrollWrapper variant="dark" className="contest-prizes__content">
        {parsedPrizes.length > 0 ? (
          parsedPrizes.map(({ place, prize }) => (
            <ContestCard key={place} className="contest-prizes__card">
              <div className="contest-prizes__wrapper">
                <div className="contest-prizes__standing">{formatToPosition(place)}</div>
                <div className="contest-prizes__label">PLACE</div>
              </div>
              <div className="contest-prizes__prize">
                {formatToCurrency({ value: prize, withDollar: true })}
              </div>
            </ContestCard>
          ))
        ) : (
          <div className="contest-prizes__content contest-prizes__content--empty">
            No prizes provided
          </div>
        )}
      </ScrollWrapper>
      <ContestFooter free />
    </div>
  );
};
