import clsx from "clsx";
import "./loader-spinner.scss";

export interface LoaderSpinnerProps {
  size?: "small" | "tiny";
}

export const LoaderSpinner: React.FC<LoaderSpinnerProps> = ({ size }) => {
  return (
    <div className={clsx("loader-spinner", `loader-spinner--${size}`)}>
      <div className="loader-spinner__spinner-wrapper">
        <div className="loader-spinner__circle loader-spinner__circle--yellow" />
        <div className="loader-spinner__circle loader-spinner__circle--red" />
        <div className="loader-spinner__circle loader-spinner__circle--yellow" />
        <svg className="loader-spinner__head">
          <circle className="loader-spinner__dot" cx="13" cy="13" r="13" />
        </svg>
      </div>
    </div>
  );
};
