import { Outlet, useNavigate } from "react-router-dom";
import { ContestsView } from "components/profile-contests-view";
import { ProfileImage } from "components/profile-image";
import { StatsView } from "components/profile-stats-view";
import { RouterNavbar } from "components/router-navbar";
import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { Paths, PathsProfile } from "types/router";
import { PencilIcon } from "assets/icons";
import "./profile.scss";

export const ProfilePage: React.FC = () => {
  const { firstName, lastName, username } = useAppSelector(selectAuthPayload);
  const navigate = useNavigate();

  return (
    <div className="profile">
      <div className="profile__top" />
      <div className="profile__content">
        <div className="profile__left-side">
          <div className="profile__user-tab">
            <ProfileImage className="profile__picture" />
            <div className="profile__user">
              <span className="profile__name">{`${firstName} ${lastName}`}</span>
              <span className="profile__username">@{username}</span>
            </div>
            <div className="profile__buttons-wrapper">
              <button
                className="profile__button"
                onClick={() => navigate(Paths.EDIT_PROFILE)}
              >
                <PencilIcon className="profile__button-icon" />
                Edit Profile
              </button>
            </div>
          </div>
          <ContestsView className="profile__show-desktop" wide />
        </div>
        <StatsView className="profile__hide-charts" />
      </div>
      <div className="profile__content-router">
        <RouterNavbar
          navigationClassName="profile__navbar"
          tabs={[
            {
              label: "Contests",
              url: Paths.PROFILE,
              uniqueStringPath: PathsProfile.CONTESTS,
            },
            {
              label: "Stats",
              url: PathsProfile.STATS_ABSOLUTE,
              uniqueStringPath: PathsProfile.STATS,
            },
          ]}
        />
        <Outlet />
      </div>
    </div>
  );
};
