import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { Alert } from "components/alert";
import { BottomNavigation } from "components/bottom-navigation";
import { CommonForm } from "components/common-form";
import { PasswordError } from "components/password-error";
import { ProfileLabeledInput } from "components/profile-details/profile-labeled-input";
import {
  ERROR_PASSWORD_EMPTY,
  ERROR_PASSWORD_INVALID,
  ERROR_PASSWORD_NOT_MATCH,
  REGEX_PASSWORD,
} from "helpers/constants";
import { useOnClickAway } from "hooks/use-on-click-away";
import "./change-password.scss";

interface ChangePasswordInputs {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface ChangePasswordProps {
  onClose: () => void;
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    getFieldState,
    watch,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<ChangePasswordInputs>({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onTouched",
  });

  const [error, setError] = useState<string | null>(null);
  const changePasswordRef = useRef<HTMLDivElement>(null);

  const onSubmit = async ({ oldPassword, newPassword }: ChangePasswordInputs) => {
    const user = await Auth.currentAuthenticatedUser();
    Auth.changePassword(user, oldPassword, newPassword)
      .then(() => {
        toast.success("Password has been changed");
        onClose();
      })
      .catch(() => setError("Wrong password"));
  };

  const handlePasswordChange = () => {
    const confirmPasswordState = getFieldState("confirmPassword");
    const passwordState = getFieldState("newPassword");
    if (passwordState.isTouched) {
      trigger("newPassword");
    }
    if (confirmPasswordState.isTouched) {
      trigger("confirmPassword");
    }
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  useOnClickAway([changePasswordRef], onCancel);

  return (
    <div ref={changePasswordRef} className="change-password">
      <CommonForm
        title="Change your password"
        onSubmit={handleSubmit((data) => onSubmit(data))}
        className="change-password__form"
        footer={
          <BottomNavigation
            nextButtonActive={!Object.values(errors).length}
            isSubmitType
            onCancelAction={onCancel}
            cancelText="CANCEL"
          />
        }
      >
        <input type="text" name="username" hidden autoComplete="username" />
        <div className="change-password__input-wrapper">
          <ProfileLabeledInput
            {...register("oldPassword", {
              required: ERROR_PASSWORD_EMPTY,
            })}
            labelText="Current password"
            placeholder="enter the old password"
            type="password"
            error={!!errors.oldPassword}
            errorText={errors.oldPassword?.message}
            autoComplete="current-password"
          />
          <ProfileLabeledInput
            {...register("newPassword", {
              pattern: {
                value: REGEX_PASSWORD,
                message: ERROR_PASSWORD_INVALID,
              },
              onChange: handlePasswordChange,
            })}
            labelText="New password"
            placeholder="enter the new password"
            type="password"
            error={!!errors.newPassword}
            errorText={errors.newPassword?.message}
            autoComplete="new-password"
            customPasswordError={
              <PasswordError passwordValue={getValues("newPassword")} />
            }
          />
          <ProfileLabeledInput
            {...register("confirmPassword", {
              validate: (value) => {
                if (watch("newPassword") !== value) {
                  return ERROR_PASSWORD_NOT_MATCH;
                }
              },
              onChange: handlePasswordChange,
            })}
            className="change-password__input"
            labelText="Confirm new password"
            placeholder="re-enter the password"
            type="password"
            error={!!errors.confirmPassword}
            errorText={errors.confirmPassword?.message}
            autoComplete="new-password"
          />
        </div>
      </CommonForm>
      <div className="change-password__alert">
        {error && <Alert message={error} setError={setError} />}
      </div>
    </div>
  );
};
