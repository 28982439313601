import { UserDetailsResponse, UserStatsResponse } from "types/request";

export const userDetailsMock: UserDetailsResponse = {
  userId: "1c002527-250c-44f1-91c4-0fd15c163c29",
  availablePoints: 100000,
};

export const userStatsMock: UserStatsResponse = {
  sportsChart: {
    chartCategory: "sportChart",
    chartPoints: [
      { name: "MLB", value: 26 },
      { name: "NFL", value: 20 },
      { name: "NBA", value: 54 },
    ],
  },
  categoryCharts: [
    {
      chartCategory: "NBA",
      chartPoints: [
        {
          name: "M",
          value: 5,
        },
        {
          name: "T",
          value: 15,
        },
        {
          name: "W",
          value: 30,
        },
        {
          name: "T",
          value: 35,
        },
        {
          name: "F",
          value: 20,
        },
        {
          name: "S",
          value: 45,
        },
        {
          name: "S",
          value: 40,
        },
      ],
      income: 9378,
      percentageIncome: 12,
    },
    {
      chartCategory: "NFL",
      chartPoints: [
        {
          name: "M",
          value: 45,
        },
        {
          name: "T",
          value: 15,
        },
        {
          name: "W",
          value: 30,
        },
        {
          name: "T",
          value: 55,
        },
        {
          name: "F",
          value: 20,
        },
        {
          name: "S",
          value: 60,
        },
        {
          name: "S",
          value: 45,
        },
      ],
      income: 9378,
      percentageIncome: 12,
    },
  ],
};
