import { PaginatedContests } from "components/contest/contests-pagination";
import { NoContestsMessage } from "components/no-contests-message";
import { selectMyContestRequestState } from "store/my-contest-slice";
import { useAppSelector } from "hooks/store";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { ContestsItemType, RequestState } from "types/request";
import { SectionItems } from "../section-items";

interface ContestSectionContentProps {
  contests: ContestsItemType[];
  wide?: boolean;
}

export const ContestSectionContent: React.FC<ContestSectionContentProps> = ({
  contests,
  wide,
}) => {
  const requestState = useAppSelector(selectMyContestRequestState);
  const showEmptyMessage = !contests.length && requestState === RequestState.SUCCESS;
  const isDesktop = useMediaQuery(MediaQueryBreakpoints.DESKTOP);
  const isTablet = useMediaQuery(MediaQueryBreakpoints.WIDE);
  const PAGINATION_ITEMS_PER_PAGE = 5;

  if (showEmptyMessage) {
    return <NoContestsMessage message={`You don't have any contests in this section`} />;
  }
  if (isDesktop || isTablet) {
    return (
      <PaginatedContests
        contests={contests}
        itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
        wide={wide}
      />
    );
  }
  return <SectionItems contests={contests} />;
};
