import { forwardRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ProfileImage } from "components/profile-image";
import { selectPreviousPage, selectTopbarTitle } from "store/app-slice";
import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { Paths } from "types/router";
import { MenuIcon } from "assets/icons";
import { ArrowIcon } from "assets/icons/arrow-icon";
import "./topbar.scss";

interface TopbarProps {
  noMenu?: boolean;
  mobileHidden?: boolean;
  arrow?: boolean;
  handleMenuButtonClick?: () => void;
}

export const Topbar = forwardRef<HTMLButtonElement, TopbarProps>(
  ({ noMenu, mobileHidden, handleMenuButtonClick, arrow }, ref) => {
    const title = useAppSelector(selectTopbarTitle);
    const previousPage = useAppSelector(selectPreviousPage);
    const navigate = useNavigate();
    const isMobileAndPreviousPage =
      useMediaQuery(MediaQueryBreakpoints.MOBILE) && previousPage;
    const isDesktop = useMediaQuery(MediaQueryBreakpoints.DESKTOP);
    const location = useLocation();
    const { userId } = useAppSelector(selectAuthPayload);

    const handleMenuClick = () => {
      if (isMobileAndPreviousPage) {
        navigate(previousPage);
        return;
      }
      if (handleMenuButtonClick) {
        handleMenuButtonClick();
      }
    };
    return (
      <>
        <div className="topbar__content-filler" />
        <div
          data-testid="topbar"
          className={clsx("topbar", {
            "topbar--hidden": mobileHidden,
          })}
        >
          <button
            data-testid="topbar-button"
            ref={ref}
            className={clsx("topbar__menu-collapse-button", {
              "topbar__menu-collapse-button--invisible":
                noMenu || (isDesktop && location.pathname !== Paths.TERMS),
            })}
            onClick={handleMenuClick}
            type="button"
            aria-label="menu-button"
          >
            {isMobileAndPreviousPage || arrow ? (
              <ArrowIcon data-testid="arrow-icon" className="topbar__menu-icon" />
            ) : (
              <MenuIcon data-testid="menu-icon" className="topbar__menu-icon" />
            )}
          </button>
          <span
            className={clsx("topbar__title", {
              "topbar__title--hidden": mobileHidden,
            })}
          >
            {title}
          </span>
          {userId ? (
            <ProfileImage className="topbar__user-image" />
          ) : (
            <Link className="topbar__sign-in" to={Paths.SIGN_IN}>
              Sign in
            </Link>
          )}
        </div>
      </>
    );
  }
);
Topbar.displayName = "Topbar";
