import { SVGProps } from "react";

export const BankIcon = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="21"
    viewBox="0 0 19 21"
    {...restProps}
  >
    <path
      id="ic_account_balance_24px"
      d="M4,10v7H7V10Zm6,0v7h3V10ZM2,22H21V19H2ZM16,10v7h3V10ZM11.5,1,2,6V8H21V6Z"
      transform="translate(-2 -1)"
      fill="currentColor"
    />
  </svg>
);
