import { ContestStatus, MyContestMainTab, MyContestSmTab } from "types/contest";
import { PathsMyContests } from "types/router";

export const getContestUrl = (
  contestType: ContestStatus,
  contestId: number,
  tab: MyContestSmTab = MyContestSmTab.SLATE
) => {
  const mainTab =
    contestType === ContestStatus.COMPLETED
      ? MyContestMainTab.PREVIOUS
      : MyContestMainTab.IN_PROGRESS;
  return `${PathsMyContests.ROOT}/${mainTab}/${contestId}/${tab}`;
};
