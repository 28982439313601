import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { MenuOption } from "components/menu";
import { ProfileImage } from "components/profile-image";
import { EXCEPTION_PATH } from "components/protected-layout";
import { ScrollWrapper } from "components/scroll-wrapper";
import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { usePaths } from "hooks/use-get-paths";
import { AllPaths, Paths } from "types/router";
import { PersonFilledIcon, ProfileBackground, WaveTransition } from "assets/icons";
import "./menu-content.scss";

interface MenuContentProps {
  onClose: (path: AllPaths) => void;
  isOpen: boolean;
}

export const MenuContent = React.forwardRef<HTMLDivElement, MenuContentProps>(
  ({ isOpen, onClose }, ref) => {
    const { firstName, lastName, isIntroduced } = useAppSelector(selectAuthPayload);
    const { pathname } = useLocation();
    const isLogged = !!firstName;
    const paths = usePaths();
    const availablePathsOnIntroduction = [Paths.SIGN_IN, Paths.TERMS];

    return (
      <div
        data-testid="menu-content"
        className={clsx("menu-content", { "menu-content--open": isOpen })}
        ref={ref}
      >
        <ScrollWrapper className="menu-content__wrapper" variant="light">
          <div className="menu-content__top">
            <div className="menu-content__profile-picture">
              <ProfileBackground className="menu-content__profile-picture-background" />
              {isLogged ? (
                <ProfileImage className="menu-content__profile" />
              ) : (
                <PersonFilledIcon className="menu-content__profile" />
              )}
              <img
                srcSet="images/logo-base.png, images/logo-base@2x.png 2x"
                src="images/logo-base.png"
                alt="logo"
                className="menu-content__logo"
              />
            </div>
            {isLogged ? (
              <span className="menu-content__name">{`${firstName} ${lastName}`}</span>
            ) : (
              <Link className="menu-content__name" to={Paths.SIGN_IN}>
                Sign in
              </Link>
            )}
            <div className="menu-content__wave">
              <WaveTransition className="menu-content__wave-svg" />
            </div>
          </div>
          <ul className="menu-content__list">
            {paths.map(
              ({
                rootPath,
                path,
                text,
                icon,
                hideIconOnMd,
                comingSoon,
                isVisible = true,
              }) => {
                const isDisabled =
                  !isIntroduced &&
                  !availablePathsOnIntroduction.some(
                    (availablePath) => availablePath === path
                  );
                return isVisible ? (
                  <Link
                    key={`${text}-menu-option`}
                    className={clsx("menu-content__link", {
                      "menu-content__link--disabled": comingSoon || isDisabled,
                      "menu-content__link--last-item": hideIconOnMd,
                    })}
                    to={
                      isLogged || pathname.includes(EXCEPTION_PATH) ? path : Paths.SIGN_IN
                    }
                    onClick={() => onClose(path)}
                  >
                    <MenuOption
                      icon={icon}
                      text={text}
                      hideIconOnMd={hideIconOnMd}
                      isDisabled={isDisabled}
                      rootPath={rootPath}
                      comingSoon={comingSoon}
                    />
                  </Link>
                ) : null;
              }
            )}
          </ul>
        </ScrollWrapper>
      </div>
    );
  }
);
MenuContent.displayName = "MenuContent";
