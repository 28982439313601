import { SVGProps } from "react";

export const ProfileBackground = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={134.635} height={84.858} {...restProps}>
    <defs>
      <filter
        id="a"
        x={24.685}
        y={0}
        width={86.617}
        height={84.858}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 398" transform="translate(0 6)">
      <g data-name="Group 397" fill="#ffc50a">
        <path data-name="Path 6406" d="m0 14.086 6.14 11.451h72.7V14.086Z" />
        <path data-name="Path 6407" d="m7.505 28.083 6.078 11.336h65.253V28.083Z" />
        <path data-name="Path 6408" d="m14.948 41.963 5.9 11h57.991v-11Z" />
        <path data-name="Path 6409" d="M66.276 14.086v11.451h62.221l6.138-11.451Z" />
        <path data-name="Path 6410" d="m121.052 39.419 6.078-11.336H66.276v11.336Z" />
        <path data-name="Path 6411" d="m120.253 41.963-4.983 11h-49v-11Z" />
      </g>
      <ellipse
        data-name="Ellipse 92"
        cx={36.198}
        cy={35.476}
        rx={36.198}
        ry={35.476}
        transform="translate(30.87 1.802)"
        fill="#ffc50a"
      />
      <g transform="translate(0 -6)" filter="url(#a)">
        <ellipse
          data-name="Ellipse 91"
          cx={33.33}
          cy={33.33}
          rx={33.33}
          ry={33.33}
          transform="translate(33.69 6)"
        />
      </g>
    </g>
  </svg>
);
