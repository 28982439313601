import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { ContentWithSubheader } from "components/content-with-subheader";
import { LeaderboardCurrentUser, LeaderboardUsers } from "components/leaderboard";
import { LoaderSpinner } from "components/loader-spinner";
import { ScreenDarken } from "components/screen-darken";
import { ScrollWrapper } from "components/scroll-wrapper";
import { SelectOdd } from "components/select-odd";
import { SlatesTable } from "components/slates-table";
import {
  selectSelectedSlate,
  selectShowScreenDarkenLeaderboard,
  selectShowScreenDarkenMenu,
  setSelectedSlate,
  setShowScreenDarkenLeaderboard,
  setShowScreenDarkenMenu,
} from "store/app-slice";
import { selectAuthPayload } from "store/auth";
import {
  selectMyContestRequestState,
  selectSelectedMyContest,
  selectUsersDetails,
} from "store/my-contest-slice";
import { formatToPoints } from "helpers/format-to-points";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useEscapePress } from "hooks/use-escape-press";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { MyContestItem, RequestState } from "types/request";
import "./contest-content.scss";

export const ContestContent: React.FC = () => {
  const selectedContest = useAppSelector(selectSelectedMyContest);
  const showScreenDarkenMenu = useAppSelector(selectShowScreenDarkenMenu);
  const showScreenDarkenLeaderboard = useAppSelector(selectShowScreenDarkenLeaderboard);
  const dispatch = useAppDispatch();
  const [userDetailsInContest, setUserDetailsInContest] = useState<MyContestItem | null>(
    null
  );
  const screenDarkenRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery(MediaQueryBreakpoints.DESKTOP);
  const selectedSlate = useAppSelector(selectSelectedSlate);
  const { username } = useAppSelector(selectAuthPayload);
  const usersDetails = useAppSelector(selectUsersDetails);

  useEffect(() => {
    const fetchUserDetails = () => {
      if (selectedContest && usersDetails) {
        const filteredUser = usersDetails.find((user) => user.userName === username);
        if (filteredUser) {
          setUserDetailsInContest(filteredUser);
        }
      }
    };
    fetchUserDetails();
  }, [selectedContest, username, usersDetails]);

  const leaderboardRef = useRef<HTMLDivElement | null>(null);
  const requestState = useAppSelector(selectMyContestRequestState);

  useEffect(() => {
    const overlay = screenDarkenRef.current;

    if (!overlay) {
      return () => {};
    }
    const handleLeaderboardClickAway = () => {
      if (!isDesktop && showScreenDarkenLeaderboard) {
        dispatch(setShowScreenDarkenLeaderboard(false));
      }
    };
    overlay.addEventListener("mousedown", handleLeaderboardClickAway);
    return () => {
      overlay.removeEventListener("mousedown", handleLeaderboardClickAway);
    };
  }, [dispatch, isDesktop, showScreenDarkenLeaderboard]);

  useEffect(() => {
    dispatch(setSelectedSlate(null));
  }, [dispatch, selectedContest]);

  useEscapePress(() => {
    dispatch(setShowScreenDarkenLeaderboard(false));
    dispatch(setShowScreenDarkenMenu(false));
  });

  const isLoading = [RequestState.PENDING, RequestState.IDLE].includes(requestState);
  return (
    <>
      <div className="contest-content">
        <div className="contest-content__slate">
          <div className="contest-content__content">
            {selectedContest && userDetailsInContest && (
              <LeaderboardCurrentUser
                className="contest-content__leaderboard-current-user"
                points={userDetailsInContest.remainingBudget}
                place={userDetailsInContest.currentPlaceByBudget}
                onLeaderboardClick={() => {
                  dispatch(setShowScreenDarkenLeaderboard(!showScreenDarkenLeaderboard));
                }}
              />
            )}
            {selectedContest && (
              <div className="contest-content__contest-details">
                {!selectedSlate ? (
                  <>
                    <div className="contest-content__scroll-wrapper">
                      <div className="contest-content__title-bar">
                        <div className="contest-content__title">
                          Slate: {selectedContest.contest.name}
                        </div>
                      </div>
                      <ScrollWrapper
                        variant="dark"
                        className="contest-content__slate contest-content__slate--scroll"
                      >
                        <SlatesTable
                          className={clsx({
                            "contest-content__slate-table": isLoading,
                          })}
                        />
                        {isLoading ? (
                          <div className="contest-content__slates-loader">
                            <LoaderSpinner size="small" />
                          </div>
                        ) : null}
                      </ScrollWrapper>
                      <div className="contest-content__points">
                        {isLoading ? (
                          <div className="contest-content__points-loader">
                            <LoaderSpinner size="small" />
                          </div>
                        ) : (
                          <ContentWithSubheader
                            content={formatToPoints({
                              value: userDetailsInContest?.remainingBudget
                                ? userDetailsInContest.remainingBudget
                                : 0,
                            })}
                            subheader="AVAILABLE"
                            boldContent
                          />
                        )}
                        <ContentWithSubheader
                          content={
                            <div className="contest-content__required-points">
                              {formatToPoints({
                                value: selectedContest.contest.perContestantBudget ?? 0,
                              })}
                            </div>
                          }
                          subheader="BUDGET"
                          subheaderClassName="contest-content__required-points"
                          boldContent
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <SelectOdd {...selectedSlate} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="contest-content__leaderboard">
          {selectedContest && (
            <LeaderboardUsers
              isModal={!isDesktop}
              open={showScreenDarkenLeaderboard}
              ref={leaderboardRef}
            />
          )}
        </div>
      </div>
      <ScreenDarken
        ref={screenDarkenRef}
        isActive={showScreenDarkenMenu || showScreenDarkenLeaderboard}
      />
    </>
  );
};
