import { Auth } from "aws-amplify";
import { AuthPayload } from "types/auth";
import { BooleanValue } from "types/boolean";
import { UserFields } from "types/user";

export const getUserInfo = async () => {
  const session = await Auth.currentSession();
  const roles = session.getIdToken().payload["cognito:groups"];
  let userDetails: Omit<AuthPayload, "profileImgUrl" | "availablePoints">;

  if (session && session.isValid()) {
    const user = await Auth.currentUserInfo();
    userDetails = {
      userId: null,
      username: user.attributes[UserFields.USERNAME],
      tokenId: session.getIdToken().getJwtToken(),
      email: user.attributes[UserFields.EMAIL],
      firstName: user.attributes[UserFields.FIRST_NAME],
      lastName: user.attributes[UserFields.LAST_NAME],
      phoneNumber: user.attributes[UserFields.PHONE_NUMBER],
      livesInUs: user.attributes[UserFields.LIVES_IN_US] === BooleanValue.YES,
      isVerified: user.attributes[UserFields.EMAIL_VERIFIED],
      isIntroduced: user.attributes[UserFields.IS_INTRODUCED] === BooleanValue.YES,
      preferredContact: user.attributes[UserFields.PREFERRED_CONTACT],
      profileImageUrl: null,
      roles: roles || [],
    };
    return userDetails;
  }
  return null;
};
