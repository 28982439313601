import React from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { TeamRow } from "components/team-row";
import { setSelectedSlate } from "store/app-slice";
import { selectSelectedMyContest } from "store/my-contest-slice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { TeamType } from "types/contest";
import "./slates-table.scss";

interface AdditionalSlatesTableProps {
  className?: string;
}

export const SlatesTable: React.FC<AdditionalSlatesTableProps> = ({ className }) => {
  const selectedContest = useAppSelector(selectSelectedMyContest);
  const dispatch = useAppDispatch();
  if (!selectedContest) {
    return null;
  }
  const sortedGames = selectedContest.contestPickDetails
    .slice()
    .sort((a, b) => (dayjs(a.gameDate).isSameOrBefore(dayjs(b.gameDate)) ? -1 : 1));

  return (
    <table className={clsx("slates-table", className)}>
      <thead className="slates-table__table-head-wrapper">
        <tr>
          <th className={clsx("slates-table__table-head", "slates-table__slate-type")}>
            {selectedContest.contest.contestType}
          </th>
          <th className="slates-table__table-head">SPREAD</th>
          <th className="slates-table__table-head">MONEY</th>
          <th className="slates-table__table-head">TOTAL</th>
        </tr>
      </thead>
      <tbody className="slates-table__games">
        {sortedGames.map((ContestPickDetails) => {
          const {
            gameId,
            homeName,
            awayName,
            gameDate,
            homeLogoUrl,
            awayLogoUrl,
            homeTeamMoneyline,
            overPayout,
            homeTeamPointSpread,
            awayTeamPointSpread,
            underPayout,
            awayTeamMoneyline,
            overUnder,
            noSpreadAwayTeamMoneyline,
            noSpreadHomeTeamMoneyline,
          } = ContestPickDetails;
          return (
            <React.Fragment key={`tournament-row-${homeName}-${awayName}-${gameId}`}>
              <TeamRow
                className={clsx("slates-table__table-row", {
                  "slates-table__table-row--closed": dayjs().isAfter(
                    dayjs.tz(gameDate, "utc")
                  ),
                })}
                startDate={gameDate}
                teamName={awayName}
                onSlateClick={(betType, selectedTeamType) =>
                  dispatch(
                    setSelectedSlate({
                      betType,
                      team: selectedTeamType,
                      tournament: ContestPickDetails,
                      contestId: selectedContest.contest.id,
                    })
                  )
                }
                moneyLine={awayTeamMoneyline}
                payout={overPayout}
                teamPointSpread={awayTeamPointSpread}
                teamImageUrl={awayLogoUrl}
                overUnder={overUnder}
                noSpreadTeamMoneyline={noSpreadAwayTeamMoneyline}
                teamType={TeamType.AWAY}
                gameId={gameId}
              />
              <TeamRow
                className={clsx("slates-table__table-row", {
                  "slates-table__table-row--closed": dayjs().isAfter(
                    dayjs.tz(gameDate, "utc")
                  ),
                })}
                isLastRowInSlate
                startDate={gameDate}
                teamName={homeName}
                onSlateClick={(betType, selectedTeamType) =>
                  dispatch(
                    setSelectedSlate({
                      betType,
                      team: selectedTeamType,
                      tournament: ContestPickDetails,
                      contestId: selectedContest.contest.id,
                    })
                  )
                }
                moneyLine={homeTeamMoneyline}
                payout={underPayout}
                teamPointSpread={homeTeamPointSpread}
                teamImageUrl={homeLogoUrl}
                overUnder={overUnder}
                noSpreadTeamMoneyline={noSpreadHomeTeamMoneyline}
                teamType={TeamType.HOME}
                gameId={gameId}
              />
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
