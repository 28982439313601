import "./progress-bar.scss";

interface ProgressBarProps {
  completed: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ completed }) => {
  const fillerStyles = { width: `${completed}%` };
  return (
    <div className="progress-bar">
      <div className="progress-bar__filler" style={fillerStyles} />
    </div>
  );
};
