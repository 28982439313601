export enum DateFormats {
  LONG_DATE = "dddd, MMMM D, YYYY",
  SHORT_DATE = "MMM D YYYY",
  DATE = "YYYY-MM-DD",
  TIME = "h:mmA Z",
  DATE_AND_TIME = "MMM, D h:mmA Z",
  WEEKDAY_AND_TIME_WITH_TIMEZONE = "ddd, h:mmA Z",
  WEEKDAY_AND_TIME = "ddd h:mmA",
  HOUR = "hA",
  DAY = "D",
}
