import { useEffect, useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { SlateCard, SlateCardProps } from "components/slate-card";
import { DateFormats } from "helpers/constants";
import { dateToLocalTime } from "helpers/date-to-local-time";
import { TeamSlate, TeamType, WagerTypes } from "types/contest";
import "./team-row.scss";

export interface TeamRowProps extends TeamSlate {
  isLastRowInSlate?: boolean;
  startDate: string;
  className?: string;
  onSlateClick: (slateType: WagerTypes, selectedTeamType: TeamType) => void;
  teamType: TeamType;
  gameId: number;
}

export const TeamRow: React.FC<TeamRowProps> = ({
  teamName,
  teamImageUrl,
  moneyLine,
  payout,
  overUnder,
  teamPointSpread,
  isLastRowInSlate = false,
  startDate,
  className,
  onSlateClick,
  noSpreadTeamMoneyline,
  teamType,
  gameId,
}) => {
  const isClosed = dayjs().isAfter(dayjs.tz(startDate, "utc"));
  const [imageError, setImageError] = useState(false);
  const cardsContent: SlateCardProps[] = [
    {
      content: teamPointSpread && moneyLine,
      topValue: teamPointSpread && `${teamPointSpread}`,
      selectedGameId: gameId,
      selectedTeamType: teamType,
      wagerType: WagerTypes.POINTS_SPREAD,
      gameClosed: isClosed,
    },
    {
      content: noSpreadTeamMoneyline,
      selectedGameId: gameId,
      selectedTeamType: teamType,
      wagerType: WagerTypes.MONEYLINE,
      gameClosed: isClosed,
    },
    {
      content: payout,
      topValue: overUnder && `${teamType === TeamType.AWAY ? "O" : "U"} ${overUnder}`,
      selectedGameId: gameId,
      selectedTeamType: teamType === TeamType.AWAY ? TeamType.OVER : TeamType.UNDER,
      wagerType: WagerTypes.TOTAL,
      gameClosed: isClosed,
    },
  ];

  useEffect(() => {
    setImageError(false);
  }, [teamImageUrl]);

  return (
    <tr className={clsx("team-row", className)}>
      <td className="team-row__team-cell">
        <div className="team-row__team">
          {!imageError && (
            <img
              onError={() => setImageError(true)}
              className="team-row__team-logo"
              src={teamImageUrl}
              alt="team-logo"
            />
          )}
          {teamName}
        </div>
        {isLastRowInSlate ? (
          <span className="team-row__team-cell-date">
            {dateToLocalTime(startDate).format(DateFormats.DATE_AND_TIME)}
          </span>
        ) : (
          <span className="team-row__separator" />
        )}
      </td>
      {cardsContent.map(
        ({
          content,
          topValue,
          selectedGameId,
          selectedTeamType,
          wagerType,
          gameClosed,
        }) => (
          <td className="team-row__card-cell" key={wagerType}>
            <SlateCard
              content={content}
              topValue={topValue}
              onSlateClick={() => {
                onSlateClick(wagerType, selectedTeamType);
              }}
              selectedGameId={selectedGameId}
              selectedTeamType={selectedTeamType}
              wagerType={wagerType}
              gameClosed={gameClosed}
            />
          </td>
        )
      )}
    </tr>
  );
};
