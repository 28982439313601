import { SVGProps } from "react";

export const HomeIcon = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="19"
    viewBox="0 0 22 19"
    {...restProps}
  >
    <path
      id="icons8-home"
      d="M12,2a1,1,0,0,0-.711.3L1.2,11.1a.5.5,0,0,0,.3.9H4v8a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V14h4v6a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V12h2.5a.5.5,0,0,0,.3-.9L12.717,2.3,12.711,2.3A1,1,0,0,0,12,2Z"
      transform="translate(-1 -2)"
      fill="currentColor"
    />
  </svg>
);
