import { ContestParlayItem, ContestParlayResponse } from "types/request";

export const mapParlayResponse = (
  contestParlayResponse: ContestParlayResponse
): ContestParlayItem => {
  const wagers = contestParlayResponse.Wagers.map((wager) => ({
    wagerId: wager.WagerId,
    userId: wager.WagerId,
    userName: wager.UserName,
    pictureUri: wager.PictureUri,
    gameId: wager.GameId,
    gameDate: wager.GameDate,
    sport: wager.Sport,
    status: wager.Status,
    amount: wager.Amount,
    roi: wager.Roi,
    amountWon: wager.AmountWon,
    wagerType: wager.WagerType,
    chosenTeam: wager.ChosenTeam,
    homeTeam: wager.HomeTeam,
    awayTeam: wager.AwayTeam,
    homeTeamUri: wager.HomeTeamUri,
    awayTeamUri: wager.AwayTeamUri,
    pointSpread: wager.PointSpread,
    moneyLine: wager.MoneyLine,
    overUnder: wager.OverUnder,
    overPayout: wager.OverPayout,
    underPayout: wager.UnderPayout,
    createdDt: wager.CreatedDt,
    updatedDt: wager.UpdatedDt,
    currentGameStatus: wager.CurrentGameStatus,
    currentPointSpread: wager.CurrentPointSpread,
    currentOverUnder: wager.CurrentOverUnder,
    currentOverPayout: wager.CurrentOverPayout,
    currentUnderPayout: wager.CurrentUnderPayout,
    currentHomeTeamMoneyLine: wager.CurrentHomeTeamMoneyLine,
    currentAwayTeamMoneyLine: wager.CurrentAwayTeamMoneyLine,
    currentNoSpreadHomeTeamMoneyLine: wager.CurrentNoSpreadAwayTeamMoneyLine,
    currentNoSpreadAwayTeamMoneyLine: wager.CurrentNoSpreadAwayTeamMoneyLine,
    homeTeamAbbreviation: wager.HomeTeamAbbreviation,
    awayTeamAbbreviation: wager.AwayTeamAbbreviation,
    homeTeamScore: wager.HomeTeamScore,
    awayTeamScore: wager.AwayTeamScore,
  }));
  return {
    parlay: {
      id: contestParlayResponse.Parlay.Id,
      userId: contestParlayResponse.Parlay.UserId,
      sport: contestParlayResponse.Parlay.Sport,
      name: contestParlayResponse.Parlay.Name,
      status: contestParlayResponse.Parlay.Status,
      wlt: contestParlayResponse.Parlay.Wlt,
      created: contestParlayResponse.Parlay.Created,
      updated: contestParlayResponse.Parlay.Updated,
    },
    wagers,
  };
};
