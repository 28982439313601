import { useLocation, useParams, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { LobbyContests } from "components/lobby-contests";
import { RouterNavbar } from "components/router-navbar";
import { selectUpcomingContests } from "store/app-slice";
import { useAppSelector } from "hooks/store";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { PathsLobby } from "types/router";
import "./lobby.scss";

enum SportVariants {
  POPULAR = "popular",
  MULTISPORT = "multisport",
  NFL = "nfl",
  NBA = "nba",
  MLB = "mlb",
}
export const UNSELECTED_PARAM = "unselected";
export const Lobby: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { contestId } = useParams<{ contestId?: string }>();
  const upcomingContests = useAppSelector(selectUpcomingContests);
  const isTournamentSelected = contestId !== UNSELECTED_PARAM;
  const currentParam = searchParams.toString();
  const currentParamPathValue = currentParam ? `?${currentParam}` : "";
  const categoryPathConverter = (category: string) =>
    `/lobby/${category}/unselected/details${currentParamPathValue}`;
  const location = useLocation();
  const isMobile = useMediaQuery(MediaQueryBreakpoints.MOBILE);
  const isNavBarHidden =
    isMobile &&
    (isTournamentSelected ||
      location.pathname.includes(PathsLobby.SEARCH) ||
      location.pathname.includes(PathsLobby.SORT));

  const allLobbyCategories = upcomingContests.map(({ contest }) => contest.contestType);
  const filteredLobbyCategories = [...new Set(allLobbyCategories)];

  const navTabs = [
    { label: Object.keys(SportVariants)[0], url: SportVariants.POPULAR },
    ...filteredLobbyCategories.map((tab) => {
      return {
        label: tab === "All" ? Object.keys(SportVariants)[1] : tab,
        url: tab === "All" ? SportVariants.MULTISPORT : tab.toLocaleLowerCase(),
      };
    }),
  ];

  return (
    <div className="lobby">
      <div className="lobby__navigation-wrapper">
        <RouterNavbar
          navigationClassName={clsx(
            "lobby__navigation",
            isNavBarHidden && "lobby__navigation--mobile-hidden"
          )}
          tabs={navTabs.map(({ label, url }) => {
            return {
              label: label,
              url: categoryPathConverter(url),
              uniqueStringPath: url,
            };
          })}
        />
      </div>

      <LobbyContests />
    </div>
  );
};
