import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useEscapePress } from "hooks/use-escape-press";
import { useOnClickAway } from "hooks/use-on-click-away";
import { Option, OptionValue } from "types/select";
import { ExpandMoreIcon } from "assets/icons";
import "./select.scss";

interface SelectProps<Value extends OptionValue> {
  options: Option<Value>[];
  value?: Value;
  noOptionsMessage?: string;
  disabled?: boolean;
  onChange?: (value: Option<Value>) => void;
  className?: string;
}

export const Select = <Value extends OptionValue>({
  options,
  value,
  onChange,
  noOptionsMessage,
  disabled,
  className,
}: SelectProps<Value>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option<Value> | undefined>(
    options[0]
  );
  const selectRef = useRef(null);

  useOnClickAway([selectRef], () => setIsOpen(false));

  useEffect(() => {
    setSelectedOption(options.find((item) => item.value === value));
  }, [options, value]);

  const handleChange = (option: Option<Value>) => {
    if (onChange) {
      onChange(option);
    }
    setIsOpen(false);
  };

  useEscapePress(() => setIsOpen(false));

  return (
    <div
      data-testid="select"
      className={clsx("select", { "select--active": isOpen }, className)}
      ref={selectRef}
    >
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="select__button"
        type="button"
        disabled={disabled}
      >
        {selectedOption ? selectedOption.label : noOptionsMessage ?? "unknown"}
        <ExpandMoreIcon
          data-testid="select-icon"
          className={clsx("select__icon", {
            "select__icon--active": isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div className="select__options-container">
          {options.map((option) => (
            <li key={option.label} className="select__option-wrapper">
              <button
                data-testid="select-option-button"
                className={clsx("select__option", {
                  "select__option--active": selectedOption?.value === option.value,
                })}
                onClick={() => handleChange(option)}
                type="button"
              >
                {option.label}
              </button>
            </li>
          ))}
        </div>
      )}
    </div>
  );
};
