import { ContestStatus } from "types/contest";
import { ContestsItemType, ContestsResponseItem } from "types/request";

export const mapContestsResponse = (
  contestResponse: ContestsResponseItem[]
): ContestsItemType[] => {
  return contestResponse.map((contest) => {
    return {
      contest: {
        id: contest.Contest.ID,
        name: contest.Contest.Name,
        status: contest.Contest.Status as ContestStatus,
        contestType: contest.Contest.ContestType,
        startDT: contest.Contest.StartDT,
        endDT: contest.Contest.EndDT,
        entryDeadline: contest.Contest.EntryDeadline,
        picksDue: contest.Contest.PicksDue,
        maxContestants: contest.Contest.MaxContestants,
        perContestantBudget: contest.Contest.PerContestantBudget,
        prizePool: contest.Contest.PrizePool,
        numPicksReq: contest.Contest.NumPicksReq,
        acceptingEntries: contest.Contest.AcceptingEntries,
        created: contest.Contest.Created,
        updated: contest.Contest.Updated,
        distributedPrizes: contest.Contest.DistributedPrizes,
      },
      contestantStats: {
        totalEntries: contest.ContestantStats.TotalEntries,
        totalEntriesWithPicksComplete:
          contest.ContestantStats.TotalEntriesWithPicksComplete,
        hasEntered: contest.ContestantStats.HasEntered,
        userPlaceByWlt: contest.ContestantStats.UserPlaceByWlt,
        userPlaceByUnits: contest.ContestantStats.UserPlaceByUnits,
        userPlaceByBudget: contest.ContestantStats.UserPlaceByBudget,
        remainingBudget: contest.ContestantStats.RemainingBudget,
      },
      contestPickDetails: contest.ContestPickDetails.map((pickDetails) => {
        return {
          contestId: pickDetails.ContestId,
          gameId: pickDetails.GameId,
          sport: pickDetails.Sport,
          gameDate: pickDetails.GameDate,
          awayTeamPointSpread: pickDetails.AwayTeamPointSpread,
          noSpreadAwayTeamMoneyline: pickDetails.NoSpreadAwayTeamMoneyline,
          awayTeamMoneyline: pickDetails.AwayTeamMoneyline,
          homeTeamPointSpread: pickDetails.HomeTeamPointSpread,
          noSpreadHomeTeamMoneyline: pickDetails.NoSpreadHomeTeamMoneyline,
          homeTeamMoneyline: pickDetails.HomeTeamMoneyline,
          overUnder: pickDetails.OverUnder,
          overPayout: pickDetails.OverPayout,
          underPayout: pickDetails.UnderPayout,
          awayLocation: pickDetails.AwayLocation,
          homeLocation: pickDetails.HomeLocation,
          awayName: pickDetails.AwayName,
          homeName: pickDetails.HomeName,
          awayNickname: pickDetails.AwayNickname,
          homeNickname: pickDetails.HomeNickname,
          awayAbbrev: pickDetails.AwayAbbrev,
          homeAbbrev: pickDetails.HomeAbbrev,
          homeLogoUrl: pickDetails.HomeLogoUrl,
          awayLogoUrl: pickDetails.AwayLogoUrl,
        };
      }),
    };
  });
};
