import { SVGProps } from "react";

export const OnboardingWelcomeLogoSVG = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={57.759} height={46.683} {...restProps}>
    <defs>
      <filter
        id="a"
        x={5.054}
        y={0}
        width={47.437}
        height={46.683}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 443" transform="translate(-43.788 -484)">
      <g data-name="Group 397" fill="#e1e9ff">
        <path data-name="Path 6406" d="m43.788 496.043 2.634 4.913h31.187v-4.913Z" />
        <path data-name="Path 6407" d="m47.008 502.048 2.61 4.863H77.61v-4.863Z" />
        <path data-name="Path 6408" d="m50.201 508.002 2.53 4.719H77.61v-4.719Z" />
        <path data-name="Path 6409" d="M72.222 496.043v4.913h26.693l2.632-4.913Z" />
        <path data-name="Path 6410" d="m95.722 506.911 2.608-4.863H72.222v4.863Z" />
        <path data-name="Path 6411" d="m95.377 508.002-2.138 4.719h-21.02v-4.719Z" />
      </g>
      <ellipse
        data-name="Ellipse 92"
        cx={15.529}
        cy={15.219}
        rx={15.529}
        ry={15.219}
        transform="translate(57.031 491.171)"
        fill="#e1e9ff"
      />
      <g transform="translate(43.79 484)" filter="url(#a)">
        <ellipse
          data-name="Ellipse 91"
          cx={14.719}
          cy={14.341}
          rx={14.719}
          ry={14.341}
          transform="translate(14.05 6)"
          fill="#6277d7"
        />
      </g>
      <text
        transform="translate(66.183 510.827)"
        fill="#e1e9ff"
        fontSize={20}
        fontFamily="CollegiateBlackFLF"
        letterSpacing=".078em"
      >
        <tspan x={0} y={0}>
          {"B"}
        </tspan>
      </text>
    </g>
  </svg>
);
