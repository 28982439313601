import { useEffect } from "react";
import clsx from "clsx";
import { setSelectedMyContest } from "store/my-contest-slice";
import { useAppDispatch } from "hooks/store";
import "./contest-section.scss";

interface ContestSectionProps {
  title: string;
  showHeaderOnSm?: boolean;
}

export const ContestSection: React.FC<ContestSectionProps> = ({
  title,
  showHeaderOnSm,
  children,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedMyContest(null));
  }, [dispatch]);
  return (
    <div className="contest-section">
      <div
        className={clsx("contest-section__header", {
          "contest-section__header--show-on-sm": showHeaderOnSm,
        })}
      >
        {title}
      </div>
      {children}
    </div>
  );
};
