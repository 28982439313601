export enum TopbarTitle {
  LOBBY = "Lobby",
  CONTESTS = "My Contests",
  PROFILE = "Profile",
  ACCOUNT = "My account",
  TERMS = "Terms and privacy",
  SORT = "Sort",
  SEARCH = "Search",
  ADMIN = "Admin panel",
}
