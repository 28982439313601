interface FormatToCurrencyType {
  value: number;
  withDollar: boolean;
}

export const formatToCurrency = ({ value, withDollar }: FormatToCurrencyType) => {
  if (withDollar) {
    return `$${value.toLocaleString("en-Us")}`;
  }
  return value.toLocaleString("en-Us");
};
