import { forwardRef } from "react";
import clsx from "clsx";
import "./screen-darken.scss";

interface ScreenDarkenProps {
  isActive: boolean;
}

export const ScreenDarken = forwardRef<HTMLDivElement, ScreenDarkenProps>(
  ({ isActive }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx("screen-darken", {
          "screen-darken--disabled": !isActive,
        })}
      />
    );
  }
);

ScreenDarken.displayName = "ScreenDarken";
