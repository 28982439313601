import { ContestStatus } from "./contest";

export enum RequestState {
  IDLE = "idle",
  PENDING = "pending",
  SUCCESS = "success",
  ERROR = "error",
}

export interface ContestsResponseContest {
  ID: number;
  Name: string;
  Status: string;
  ContestType: string;
  StartDT: string;
  EndDT: string;
  EntryDeadline: string;
  PicksDue: string;
  MaxContestants: number;
  PerContestantBudget: number;
  PrizePool: number;
  NumPicksReq: number;
  AcceptingEntries: boolean;
  Created: string;
  Updated: string;
  DistributedPrizes?: string;
}

export interface ContestType {
  id: number;
  name: string;
  status: ContestStatus;
  contestType: string;
  startDT: string;
  endDT: string;
  entryDeadline: string;
  picksDue: string;
  maxContestants: number;
  perContestantBudget: number;
  prizePool: number;
  numPicksReq: number;
  acceptingEntries: boolean;
  created: string;
  updated: string;
  distributedPrizes?: string;
}

export interface ContestsResponsePickDetails {
  ContestId: number;
  GameId: number;
  Sport: string;
  GameDate: string;
  AwayTeamPointSpread: number;
  NoSpreadAwayTeamMoneyline: number;
  AwayTeamMoneyline: number;
  HomeTeamPointSpread: number;
  NoSpreadHomeTeamMoneyline: number;
  HomeTeamMoneyline: number;
  OverUnder: number;
  OverPayout: number;
  UnderPayout: number;
  AwayLocation: string;
  HomeLocation: string;
  AwayName: string;
  HomeName: string;
  AwayNickname: string;
  HomeNickname: string;
  AwayAbbrev: string;
  HomeAbbrev: string;
  HomeLogoUrl: string;
  AwayLogoUrl: string;
}

export interface ContestsPickDetails {
  contestId: number;
  gameId: number;
  sport: string;
  gameDate: string;
  awayTeamPointSpread: number;
  noSpreadAwayTeamMoneyline: number;
  awayTeamMoneyline: number;
  homeTeamPointSpread: number;
  noSpreadHomeTeamMoneyline: number;
  homeTeamMoneyline: number;
  overUnder: number;
  overPayout: number;
  underPayout: number;
  awayLocation: string;
  homeLocation: string;
  awayName: string;
  homeName: string;
  awayNickname: string;
  homeNickname: string;
  awayAbbrev: string;
  homeAbbrev: string;
  homeLogoUrl: string;
  awayLogoUrl: string;
}

export interface ContestsResponseItem {
  Contest: ContestsResponseContest;
  ContestPickDetails: ContestsResponsePickDetails[];
  ContestantStats: {
    TotalEntries: number;
    TotalEntriesWithPicksComplete: number;
    HasEntered: boolean;
    UserPlaceByWlt: number;
    UserPlaceByUnits: number;
    UserPlaceByBudget: number;
    RemainingBudget: number;
  };
}

export interface ContestsItemType {
  contest: ContestType;
  contestantStats: {
    totalEntries: number;
    totalEntriesWithPicksComplete: number;
    hasEntered: boolean;
    userPlaceByWlt: number;
    userPlaceByUnits: number;
    userPlaceByBudget: number;
    remainingBudget: number;
  };
  contestPickDetails: ContestsPickDetails[];
}

export interface MyContestResponse {
  UserName: string;
  PictureUri: string;
  ContestId: number;
  WltPercent: number;
  Units: number;
  UserId: number;
  PercentileByWlt: number;
  PercentileByUnits: number;
  CurrentPlaceByWlt: number;
  CurrentPlaceByUnits: number;
  CurrentPlaceByBudget: number;
  RemainingBudget: number;
  PicksCompleted: boolean;
}

export interface MyContestItem {
  userName: string;
  pictureUri: string;
  contestId: number;
  wltPercent: number;
  units: number;
  userId: number;
  currentPlaceByBudget: number;
  percentileByWlt: number;
  percentileByUnits: number;
  currentPlaceByWlt: number;
  currentPlaceByUnits: number;
  remainingBudget: number;
  picksCompleted: boolean;
}

export interface UserDetailsResponse {
  userId: string;
  availablePoints: number;
}

export interface ChartPointType {
  name: string;
  value: number;
}

export interface CategoryChartType {
  chartCategory: string;
  chartPoints: ChartPointType[];
  income: number;
  percentageIncome: number;
}

export interface UserStatsResponse {
  sportsChart: { chartCategory: string; chartPoints: ChartPointType[] };
  categoryCharts: CategoryChartType[];
}

export interface ContestWagers {
  gameId: number;
  chosenTeam: string;
  wagerType: number;
  wagerAmount: number;
}

export interface ContestParlayResponse {
  Parlay: {
    Id: number;
    UserId: number;
    Sport: string;
    Name: string;
    Status: string;
    Wlt: number;
    Created: string;
    Updated: string;
  };
  Wagers: {
    WagerId: number;
    UserId: number;
    UserName: string;
    PictureUri: string;
    GameId: string;
    GameDate: string;
    Sport: string;
    Status: number;
    Amount: number;
    Roi: number;
    AmountWon: number;
    WagerType: string;
    ChosenTeam: string;
    HomeTeam: string;
    AwayTeam: string;
    HomeTeamUri: string;
    AwayTeamUri: string;
    PointSpread: number;
    MoneyLine: number;
    OverUnder: number;
    OverPayout: number;
    UnderPayout: number;
    CreatedDt: string;
    UpdatedDt: string;
    CurrentGameStatus: string;
    CurrentPointSpread: number;
    CurrentOverUnder: number;
    CurrentOverPayout: number;
    CurrentUnderPayout: number;
    CurrentHomeTeamMoneyLine: number;
    CurrentAwayTeamMoneyLine: number;
    CurrentNoSpreadHomeTeamMoneyLine: number;
    CurrentNoSpreadAwayTeamMoneyLine: number;
    HomeTeamAbbreviation: string;
    AwayTeamAbbreviation: string;
    HomeTeamScore: number;
    AwayTeamScore: number;
  }[];
}

export interface Wager {
  wagerId: number;
  userId: number;
  userName: string;
  pictureUri: string;
  gameId: string;
  gameDate: string;
  sport: string;
  status: number;
  amount: number;
  roi: number;
  amountWon: number;
  wagerType: string;
  chosenTeam: string;
  homeTeam: string;
  awayTeam: string;
  homeTeamUri: string;
  awayTeamUri: string;
  pointSpread: number;
  moneyLine: number;
  overUnder: number;
  overPayout: number;
  underPayout: number;
  createdDt: string;
  updatedDt: string;
  currentGameStatus: string;
  currentPointSpread: number;
  currentOverUnder: number;
  currentOverPayout: number;
  currentUnderPayout: number;
  currentHomeTeamMoneyLine: number;
  currentAwayTeamMoneyLine: number;
  currentNoSpreadHomeTeamMoneyLine: number;
  currentNoSpreadAwayTeamMoneyLine: number;
  homeTeamAbbreviation: string;
  awayTeamAbbreviation: string;
  homeTeamScore: number;
  awayTeamScore: number;
}

export interface ContestParlayItem {
  parlay: {
    id: number;
    userId: number;
    sport: string;
    name: string;
    status: string;
    wlt: number;
    created: string;
    updated: string;
  };
  wagers: {
    wagerId: number;
    userId: number;
    userName: string;
    pictureUri: string;
    gameId: string;
    gameDate: string;
    sport: string;
    status: number;
    amount: number;
    roi: number;
    amountWon: number;
    wagerType: string;
    chosenTeam: string;
    homeTeam: string;
    awayTeam: string;
    homeTeamUri: string;
    awayTeamUri: string;
    pointSpread: number;
    moneyLine: number;
    overUnder: number;
    overPayout: number;
    underPayout: number;
    createdDt: string;
    updatedDt: string;
    currentGameStatus: string;
    currentPointSpread: number;
    currentOverUnder: number;
    currentOverPayout: number;
    currentUnderPayout: number;
    currentHomeTeamMoneyLine: number;
    currentAwayTeamMoneyLine: number;
    currentNoSpreadHomeTeamMoneyLine: number;
    currentNoSpreadAwayTeamMoneyLine: number;
    homeTeamAbbreviation: string;
    awayTeamAbbreviation: string;
    homeTeamScore: number;
    awayTeamScore: number;
  }[];
}

export interface MakePicksResponse {
  parlayId: number;
}
