import { ContentWrapper } from "components/content-wrapper";
import { ImageLayout } from "components/image-layout";
import { SignInContent } from "./sign-in-content";
import "./sign-in.scss";

export const SignInPage: React.FC = () => {
  return (
    <div className="sign-in-page">
      <ImageLayout />
      <ContentWrapper>
        <SignInContent />
      </ContentWrapper>
    </div>
  );
};
