import { SVGProps } from "react";

export const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.164 2.227H12.38V1.67c0-.92-.749-1.67-1.67-1.67H6.258c-.92 0-1.67.75-1.67 1.67v.557H1.805c-.92 0-1.67.749-1.67 1.67 0 .739.484 1.367 1.151 1.586l.993 11.986A1.68 1.68 0 0 0 3.942 19h9.084a1.68 1.68 0 0 0 1.664-1.532l.993-11.985a1.672 1.672 0 0 0 1.15-1.587c0-.92-.748-1.67-1.67-1.67ZM5.7 1.67c0-.307.25-.557.556-.557h4.454c.306 0 .556.25.556.557v.557H5.701V1.67Zm7.88 15.706a.56.56 0 0 1-.555.51H3.942a.56.56 0 0 1-.555-.51L2.41 5.567h12.15l-.978 11.81Zm1.583-12.923H1.804a.557.557 0 0 1 0-1.113h13.36a.557.557 0 0 1 0 1.113Z"
      fill="currentColor"
    />
  </svg>
);
