import { ReactNode } from "react";
import clsx from "clsx";
import "./content-with-subheader.scss";

interface ContentWithSubheaderProps {
  content: ReactNode;
  subheader: string;
  boldContent?: boolean;
  wrapperClassName?: string;
  subheaderClassName?: string;
}

export const ContentWithSubheader: React.FC<ContentWithSubheaderProps> = ({
  content,
  subheader,
  boldContent,
  wrapperClassName,
  subheaderClassName,
}) => {
  return (
    <div className={clsx("content-with-subheader", wrapperClassName)}>
      <div
        className={clsx("content-with-subheader__content", {
          "content-with-subheader__content--bold": boldContent,
        })}
      >
        {content}
      </div>
      <div className={clsx("content-with-subheader__subheader", subheaderClassName)}>
        {subheader}
      </div>
    </div>
  );
};
