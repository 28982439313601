import { ReactNode, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { selectAllContests, setPreviousPage, setTopbarTitle } from "store/app-slice";
import { isNumber } from "helpers/is-number";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { AllPaths, Paths, PathsLobby, PathsMyContests } from "types/router";
import { TopbarTitle } from "types/topbar";

export interface AppRouteWrapperProps {
  title?: string;
  element: ReactNode;
}

type PreviousPagePathType = {
  url: AllPaths;
  previousPage: AllPaths;
  title: TopbarTitle | null;
};

const PREVIOUS_PAGE_PATHS: PreviousPagePathType[] = [
  { url: PathsLobby.SORT_ABSOLUTE, previousPage: Paths.LOBBY, title: TopbarTitle.SORT },
  {
    url: PathsLobby.SEARCH_ABSOLUTE,
    previousPage: Paths.LOBBY,
    title: TopbarTitle.SEARCH,
  },
  {
    url: PathsMyContests.SORT_ABSOLUTE,
    previousPage: Paths.CONTESTS,
    title: TopbarTitle.SORT,
  },
  {
    url: PathsMyContests.SEARCH_ABSOLUTE,
    previousPage: Paths.CONTESTS,
    title: TopbarTitle.SEARCH,
  },
  {
    url: PathsMyContests.SLATE,
    previousPage: Paths.CONTESTS,
    title: null,
  },
  {
    url: PathsMyContests.MY_BETS,
    previousPage: Paths.CONTESTS,
    title: null,
  },
  {
    url: PathsMyContests.LEADERBOARD,
    previousPage: Paths.CONTESTS,
    title: null,
  },
];

const SELECTED_CONTESTS_PATHS: PreviousPagePathType[] = [
  { url: PathsLobby.ROOT, previousPage: Paths.LOBBY, title: TopbarTitle.LOBBY },
  {
    url: PathsMyContests.ROOT,
    previousPage: Paths.CONTESTS,
    title: TopbarTitle.CONTESTS,
  },
];

type TopbarValuesType = {
  previousPage: AllPaths | null;
  title: string | null;
};

export const AppRouteWrapper = ({ title, element }: AppRouteWrapperProps) => {
  const dispatch = useAppDispatch();
  const { contestId } = useParams<{ contestId?: string }>();
  const { pathname } = useLocation();
  const allContests = useAppSelector(selectAllContests);

  useEffect(() => {
    const matchedPath = PREVIOUS_PAGE_PATHS.find((path) => pathname.includes(path.url));
    const topBarValues: TopbarValuesType = matchedPath
      ? {
          previousPage: matchedPath.previousPage,
          title: matchedPath.title,
        }
      : {
          previousPage: null,
          title: title || null,
        };

    if (contestId && isNumber(contestId)) {
      const activeContestTitle = allContests.find(
        ({ contest }) => `${contest.id}` === contestId
      )?.contest.name;

      if (activeContestTitle) {
        const pathObject = SELECTED_CONTESTS_PATHS.find((path) =>
          pathname.includes(path.url)
        );
        if (pathObject) {
          topBarValues.previousPage = pathObject.previousPage;
        }
        topBarValues.title = activeContestTitle;
      }
    }

    if (topBarValues.title) {
      dispatch(setTopbarTitle(topBarValues.title));
    }
    if (topBarValues.previousPage) {
      dispatch(setPreviousPage(topBarValues.previousPage));
    }
  }, [dispatch, title, pathname, contestId, allContests]);

  return <>{element}</>;
};
