import { SVGProps } from "react";

export const OnboardingPersonSVG = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={187.739} height={323.152} {...restProps}>
    <g data-name="Group 145" transform="translate(-698.056 -2637.954)">
      <g data-name="Group 143">
        <g data-name="Group 138">
          <path
            data-name="Path 388"
            d="M786.982 2664.041c1.246 15.131 12.239 24.617 18.69 24.617a11.911 11.911 0 0 0 3.122-.476v7.547h16.362v-19.188c.151-.4.3-.836.442-1.306 7.254-.693 6.952-12.681 2.642-12.681-.784 0-1.308.741-1.637 1.487l-1.071 1.07v-5.411s-2.407.226-7.147-7c-2.044 3.1-18.355 7.974-31.445 5.792 0-.001-.033 2.693.042 5.549Z"
            fill="#fcbeb7"
          />
        </g>
        <path
          data-name="Path 389"
          d="M806.8 2637.954c11.285 0 13.541 6.018 13.541 6.018 4.815 0 12.187 8.8 7.749 18.582l-.023.022-2.535 2.535v-5.411s-2.407.226-7.147-7c-2.044 3.1-18.355 7.974-31.445 5.792 0 0-.033 2.694.042 5.55v.008c-4.89-14.144 4.923-26.096 19.818-26.096Z"
          fill="#311643"
        />
        <g data-name="Group 139">
          <path
            data-name="Path 390"
            d="M811.653 2665.688a.9.9 0 0 0 .9-.9v-1.688a.9.9 0 1 0-1.8 0v1.686a.9.9 0 0 0 .9.902Z"
            fill="#311643"
          />
        </g>
        <g data-name="Group 140">
          <path
            data-name="Path 391"
            d="M794.914 2665.688a.9.9 0 0 0 .9-.9v-1.688a.9.9 0 1 0-1.8 0v1.686a.9.9 0 0 0 .9.902Z"
            fill="#311643"
          />
        </g>
        <g data-name="Group 141">
          <path
            data-name="Path 392"
            d="M805.015 2678.709a5.164 5.164 0 0 0 3.373-1.158 4.587 4.587 0 0 0 1.2-1.487.414.414 0 0 0-.754-.342 4.218 4.218 0 0 1-4.516 2.118.414.414 0 0 0-.095.822 6.888 6.888 0 0 0 .792.047Z"
            fill="#311643"
          />
        </g>
        <circle
          data-name="Ellipse 50"
          cx={4.464}
          cy={4.464}
          r={4.464}
          transform="translate(813.182 2666.729)"
          fill="#f49795"
        />
        <path
          data-name="Path 393"
          d="M787.446 2667.486a31.8 31.8 0 0 0 2.713 8.164 4.463 4.463 0 1 0-2.713-8.164Z"
          fill="#f49795"
        />
        <g data-name="Group 142">
          <circle
            data-name="Ellipse 51"
            cx={5.498}
            cy={5.498}
            r={5.498}
            transform="translate(806.155 2658.444)"
            fill="none"
          />
          <circle
            data-name="Ellipse 52"
            cx={5.498}
            cy={5.498}
            r={5.498}
            transform="rotate(-80.783 1962.507 870.8)"
            fill="none"
          />
          <path
            data-name="Path 394"
            d="M788.589 2663.942a6.325 6.325 0 0 0 12.645.239 4.13 4.13 0 0 1 4.1 0 6.316 6.316 0 1 0 .031-.914 4.862 4.862 0 0 0-4.161 0 6.325 6.325 0 0 0-12.614.677Zm17.566 0a5.5 5.5 0 1 1 5.5 5.5 5.5 5.5 0 0 1-5.5-5.5Zm-16.738 0a5.5 5.5 0 1 1 5.5 5.5 5.5 5.5 0 0 1-5.5-5.5Z"
            fill="#fff"
          />
        </g>
        <path
          data-name="Path 395"
          d="M808.794 2695.729v-7.547a36.434 36.434 0 0 0 11.078-5.882Z"
          fill="#f9afab"
        />
      </g>
      <path
        data-name="Path 396"
        d="M769.676 2939.306h17.329l16.808-89.389 12.33-38.743 29.85 128.132h17.329l-16.146-143.75h-64.02Z"
        fill="#6277d7"
      />
      <path
        data-name="Path 397"
        d="M785.629 2946.274v12.246a2.585 2.585 0 0 1-2.585 2.586H748.31a.6.6 0 0 1-.6-.6v-2.358a2.685 2.685 0 0 1 1.519-2.419l19.585-9.456Z"
        fill="#2f3d6c"
      />
      <path
        data-name="Path 398"
        d="M847.877 2946.274v12.246a2.586 2.586 0 0 0 2.586 2.586H885.2a.6.6 0 0 0 .6-.6v-2.358a2.686 2.686 0 0 0-1.518-2.419l-19.585-9.456Z"
        fill="#2f3d6c"
      />
      <path
        data-name="Path 399"
        d="m787.005 2939.307-1.3 6.967h-16.89l.862-6.967Z"
        fill="#fcbeb7"
      />
      <path
        data-name="Path 400"
        d="m863.321 2939.307 1.371 6.967h-16.815l-1.885-6.967Z"
        fill="#fcbeb7"
      />
      <path
        data-name="Path 401"
        d="m847.521 2798.639-65 3.672.633-6.755h64.02"
        fill="#4a5ea3"
      />
      <path data-name="Line 3" fill="#fcbeb7" d="m862.218 2719.805-14.773 11.357" />
      <path
        data-name="Path 403"
        d="m808.815 2689.29-24.768 10.75a10.169 10.169 0 0 0-4.453 3.719l-9.918 14.943 13.984 12.34 3.736-3.191-4.241 67.7h64.02l-3.923-44.147.87-23.558 3.323 3.311 14.773-11.357-7.938-12.935a19.67 19.67 0 0 0-8.178-7.41l-20.953-10.17s-9.503 6.24-16.334.005Z"
        fill="#89aeff"
      />
      <g data-name="Group 144">
        <path
          data-name="Path 404"
          d="M803.552 2691.574a19.343 19.343 0 0 0 25.333-.471l-.646-.313a18.681 18.681 0 0 1-24.005.488Z"
          fill="#fff"
        />
      </g>
      <path
        data-name="Path 405"
        d="M846.9 2701.57a5.506 5.506 0 0 0 7.319 5.2 19.662 19.662 0 0 0-7.09-6.771 5.5 5.5 0 0 0-.229 1.571Z"
        fill="#4a62d3"
      />
      <path
        data-name="Path 406"
        d="M778.124 2705.973a5.505 5.505 0 0 0 7.706-6.707l-1.783.774a10.176 10.176 0 0 0-4.453 3.719Z"
        fill="#4a62d3"
      />
      <circle
        data-name="Ellipse 53"
        cx={8.295}
        cy={8.295}
        r={8.295}
        transform="translate(835.276 2844.437)"
        fill="#4a5ea3"
      />
      <circle
        data-name="Ellipse 54"
        cx={8.295}
        cy={8.295}
        r={8.295}
        transform="translate(779.939 2844.437)"
        fill="#4a5ea3"
      />
      <path
        data-name="Path 407"
        d="m698.94 2702.509.694-.115.109-.425a1.913 1.913 0 0 1 1.724-1.436l9.745-.658 6.778-2.756-3.784-4.724a1.9 1.9 0 0 1 .2-2.594l7.984 6.152a7.167 7.167 0 0 1 1.909 2.229l2.5 4.553-.012.017 36.507 25.516 6.383-9.566 13.984 12.34-17.5 15.479a2.721 2.721 0 0 1-3.6-.038l-41.092-36.974-7.865.748a13.71 13.71 0 0 1-5.27-.525l-4.176-1.263a1.254 1.254 0 0 1-.8-1.678l-2.974-.571a1.285 1.285 0 0 1-1.035-1.128l-.051-.481h-.182a1.058 1.058 0 0 1-.174-2.1Z"
        fill="#fcbeb7"
      />
      <path
        data-name="Path 408"
        d="m832.945 2790.389 6.511-2.906a1.817 1.817 0 0 0 .969-1.041l.944-2.6-3.29.807-1.049 2.253a1.547 1.547 0 0 1-2.014.768l1.37-4.136a2.825 2.825 0 0 1 1.384-1.508l6.876-3.382 11.86-38.469-9.061-9.014 14.773-11.357 8.638 15.033a7.569 7.569 0 0 1 .391 6.761l-23.975 54.825-4.757.219a1.557 1.557 0 0 1-1.407-.754l-.518-.864-2.5.205a1.554 1.554 0 0 1-1.377-.625l-.589-.794-.351.092a2.144 2.144 0 0 1-2.436-1.058l-1.738.579a2.484 2.484 0 0 1 1.346-3.034Z"
        fill="#fcbeb7"
      />
      <path
        data-name="Path 409"
        d="M865.81 2738.315a5.567 5.567 0 0 0 4.5 5.464l.938-2.18a7.566 7.566 0 0 0-.391-6.761l-1.069-1.86a5.57 5.57 0 0 0-3.978 5.337Z"
        fill="#f9afab"
      />
      <path
        data-name="Path 410"
        d="m758.607 2742.926 3.948 3.557a2.72 2.72 0 0 0 3.6.038l3.868-3.34a6.583 6.583 0 0 0-11.416-.255Z"
        fill="#f9afab"
      />
    </g>
  </svg>
);
