import clsx from "clsx";
import { Button } from "components/button";
import { RadioButton } from "components/inputs";
import { selectSortOption, setSortOption } from "store/app-slice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { SortOptions } from "types/sort-contests";
import "./contests-mobile-sort-view.scss";

interface LobbyMobileSortOptions {
  show: boolean;
}

export const ContestsMobileSortView: React.FC<LobbyMobileSortOptions> = ({ show }) => {
  const dispatch = useAppDispatch();
  const sortOption = useAppSelector(selectSortOption);

  return (
    <div className={clsx("contests-sort", show && "contests-sort--show")}>
      <div className="contests-sort__title">Sort By</div>
      {SortOptions.map((option) => (
        <Button
          variant="clear"
          className="contests-sort__option-bar"
          key={option.label}
          childrenClassName="contests-sort__children-wrapper"
          onClick={() => dispatch(setSortOption(option))}
        >
          <RadioButton name="sort" checked={option.label === sortOption.label} readOnly />
          <div
            className={clsx(
              "contests-sort__option",
              sortOption === option && "contests-sort__option--active"
            )}
          >
            {option.label}
          </div>
        </Button>
      ))}
    </div>
  );
};
