import { REGEX_EVERY_THREE_DIGITS } from "helpers/constants/regex";

interface FormatToPoints {
  value: number;
  separator?: "comma" | "dot";
  withSuffix?: boolean;
}

export const formatToPoints = ({
  value,
  separator = "comma",
  withSuffix = true,
}: FormatToPoints) => {
  const suffix = withSuffix ? "TD" : "";
  if (separator === "comma") {
    return `${value.toLocaleString("en-US")} ${suffix}`;
  }
  return `${value.toString().replace(REGEX_EVERY_THREE_DIGITS, ".")} ${suffix}`;
};
