import { useEffect } from "react";
import { ContestsItemType } from "types/request";

interface MissingContestParameters {
  contestList: ContestsItemType[];
  contestId: string;
  callback: () => void;
}

export const useMissingContestFallback = ({
  contestList,
  contestId,
  callback,
}: MissingContestParameters) => {
  const isAvailableContest = contestList.some(
    (contest) => contest.contest.id === Number(contestId)
  );

  useEffect(() => {
    if (!isAvailableContest && Number(contestId)) {
      callback();
    }
  });
};
