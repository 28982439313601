// numbers like (123) 123-1234
export const REGEX_PHONE_NUMBER = /^(\([0-9_]{3}\) |[0-9_]{3}-)[0-9_]{3}-[0-9_]{4}$/;
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#$@!%&*?]{8,}$/;
export const REGEX_PASSWORD_CAPITAL_LETTER = /[A-Z]/;
export const REGEX_PASSWORD_LENGTH = /[a-zA-Z\d#$@!%&*?]{8,}/;
export const REGEX_PASSWORD_NUMBER = /\d/;
export const REGEX_EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
// month format like 1, 01, 12
export const REGEX_MONTH = /^(0?[1-9]|1[012])$/;
// day format like 1, 10, 11, 31
export const REGEX_DAY = /^(0?[1-9]|[12][0-9]|3[01])$/;
// four digit year format like 2000, 1995 etc.
export const REGEX_YEAR = /\d{4}/;
// place on every three digits to replace f.e. 125000 to 125.000
export const REGEX_EVERY_THREE_DIGITS = /\B(?=(\d{3})+(?!\d))/g;
export const REGEX_APPLE_MOBILE = /iphone|ipad|ipod/;
export const REGEX_ANDROID = /Android/i;
// matches every whitespace
export const REGEX_WHITESPACES = /\s/g;
export const REGEX_NON_DIGIT = /\D/g;
export const REGEX_NON_DIGIT_WITH_LEADING_ZERO = /^0+|\D+/;
// must start with a alphanumeric character and can have non continuous dots and dashes
export const REGEX_USERNAME = /^\w(?:\w|[.-](?=\w))*$/;
// Exclusion of characters such as "!" or "?" that never appear in the first and last name.
export const REGEX_FIRST_AND_LAST_NAME =
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
export const REGEX_VERIFY_CODE = /\d/;
export const REGEX_VERIFY_PASTED_CODE = /\d{6}/;
