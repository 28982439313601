import { PhoneNumberUtil } from "google-libphonenumber";
import { ERROR_PHONE_NUMBER_INVALID } from "helpers/constants";

const PHONE_PLACEHOLDER = "(___) ___-____";

export const validatePhoneNumber = (value = "") => {
  if (!value || value === PHONE_PLACEHOLDER) {
    return true;
  } else if (!value?.includes("_")) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value || PHONE_PLACEHOLDER, "US");
    return phoneUtil.isValidNumber(phoneNumber) || ERROR_PHONE_NUMBER_INVALID;
  }
  return ERROR_PHONE_NUMBER_INVALID;
};
