export enum SortOrder {
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

export enum SortField {
  PRIZE = "prizePool",
  MAX_CONTESTANTS = "maxContestants",
}

export enum SortLabel {
  HIGHEST_PRIZE = "Highest Prize",
  LOWEST_PRIZE = "Lowest Prize",
  HIGHEST_ENTRY = "Highest Entry",
  LOWEST_ENTRY = "Lowest Entry",
}

export interface SortOption {
  label: SortLabel;
  sortField: SortField;
  sortOrder: SortOrder;
}

export const SortOptions: SortOption[] = [
  {
    label: SortLabel.HIGHEST_PRIZE,
    sortField: SortField.PRIZE,
    sortOrder: SortOrder.ASCENDING,
  },
  {
    label: SortLabel.LOWEST_PRIZE,
    sortField: SortField.PRIZE,
    sortOrder: SortOrder.DESCENDING,
  },
  {
    label: SortLabel.HIGHEST_ENTRY,
    sortField: SortField.MAX_CONTESTANTS,
    sortOrder: SortOrder.ASCENDING,
  },
  {
    label: SortLabel.LOWEST_ENTRY,
    sortField: SortField.MAX_CONTESTANTS,
    sortOrder: SortOrder.DESCENDING,
  },
];
