import { BaseSyntheticEvent } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { BottomNavigation } from "components/bottom-navigation";
import { CommonForm } from "components/common-form";
import { AnimatedLabeledInput } from "components/inputs/animated-labeled-input";
import { selectAuthPayload } from "store/auth";
import {
  ERROR_EMAIL_EMPTY,
  ERROR_EMAIL_INVALID,
  ERROR_EMAIL_NOT_FOUND,
  REGEX_EMAIL,
} from "helpers/constants";
import { useAppSelector } from "hooks/store";
import { Paths } from "types/router";
import "./forgot-password-content.scss";

interface ForgotPasswordContentInput {
  email: string;
}

export const ForgotPasswordContent = () => {
  const { userId } = useAppSelector(selectAuthPayload);
  const { handleSubmit, register } = useForm<ForgotPasswordContentInput>();

  const navigate = useNavigate();

  const onSubmit = async (
    { email }: ForgotPasswordContentInput,
    event?: BaseSyntheticEvent<object, unknown, unknown>
  ) => {
    if (event) {
      event.preventDefault();
    }
    await Auth.forgotPassword(email)
      .then(() => {
        toast.success("Message has been send");
        navigate(Paths.RESET_PASSWORD, { state: email });
      })
      .catch(() => toast.error(ERROR_EMAIL_NOT_FOUND));
  };

  return (
    <div className="forgot-password-content">
      <CommonForm
        className="forgot-password-content__form"
        title="Forgot password"
        subtitle="Enter your email address, we will send a password reset code to reset your password"
        onSubmit={handleSubmit((data, event) => onSubmit(data, event))}
        footer={
          <div className="forgot-password-content__footer">
            <BottomNavigation
              submitText="SEND"
              cancelText="CANCEL"
              cancelPath={userId ? Paths.PROFILE : Paths.SIGN_IN}
              isSubmitType
            />
          </div>
        }
      >
        <AnimatedLabeledInput
          labelText="Email"
          placeholder="Email"
          {...register("email", {
            required: {
              value: true,
              message: ERROR_EMAIL_EMPTY,
            },
            pattern: {
              value: REGEX_EMAIL,
              message: ERROR_EMAIL_INVALID,
            },
          })}
        />
      </CommonForm>
    </div>
  );
};
