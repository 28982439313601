import { SVGProps } from "react";

export const TickIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.566.362a.777.777 0 0 1 1.335.796l-.022.038L4.53 9.638a.777.777 0 0 1-1.22.12l-.031-.035L.183 6.047A.78.78 0 0 1 .276 4.95a.777.777 0 0 1 1.065.058l.03.035 2.414 2.865L8.565.362Z"
      fill="currentColor"
    />
  </svg>
);
