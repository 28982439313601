import { ContestFooter } from "components/contest";
import { ScrollWrapper } from "components/scroll-wrapper";
import { selectSelectedContest } from "store/app-slice";
import { DateFormats } from "helpers/constants/formats";
import { dateToLocalTime } from "helpers/date-to-local-time";
import { useAppSelector } from "hooks/store";
import "./contest-tournaments.scss";

export const ContestTournaments: React.FC = () => {
  const selectedContest = useAppSelector(selectSelectedContest);
  if (!selectedContest) {
    return null;
  }
  const games = selectedContest.contestPickDetails;
  return (
    <div className="contest-tournaments">
      <ScrollWrapper variant="dark" className="contest-tournaments__content">
        {games.map(({ homeAbbrev, awayAbbrev, sport, gameDate }) => {
          return (
            <div
              key={`${homeAbbrev}-${awayAbbrev}-${sport}-${gameDate}}`}
              className="contest-tournaments__card"
            >
              <div className="contest-tournaments__wrapper">
                <div className="contest-tournaments__teams">
                  <span className="contest-tournaments__team">{homeAbbrev}</span> @{" "}
                  <span className="contest-tournaments__team">{awayAbbrev}</span>
                </div>
                <div className="contest-tournaments__league">{sport}</div>
              </div>
              <div className="contest-tournaments__date">
                {dateToLocalTime(gameDate).format(DateFormats.DATE_AND_TIME)}
              </div>
            </div>
          );
        })}
      </ScrollWrapper>
      <ContestFooter free />
    </div>
  );
};
