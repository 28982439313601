import clsx from "clsx";
import { AvatarProvider } from "components/avatar-provider";
import { selectProfileImageUrl } from "store/auth";
import { useAppSelector } from "hooks/store";
import { PersonFilledIcon } from "assets/icons";
import "./profile-image.scss";

interface ProfileImageProps {
  className?: string;
}

export const ProfileImage: React.FC<ProfileImageProps> = ({ className }) => {
  const profileImgUrl = useAppSelector(selectProfileImageUrl);

  return (
    <div className={clsx("profile-image", className)}>
      <AvatarProvider>
        {profileImgUrl ? (
          <img
            src={profileImgUrl}
            alt="profile avatar"
            className="profile-image__profile"
          />
        ) : (
          <PersonFilledIcon className="profile-image__default" />
        )}
      </AvatarProvider>
    </div>
  );
};
