import { calculatePrize } from "helpers/calculate-prize";
import { DateFormats } from "helpers/constants/formats";
import { dateToLocalTime } from "helpers/date-to-local-time";
import { formatToPoints } from "helpers/format-to-points";
import { formatValueToDisplay } from "helpers/format-value-to-display";
import { MyBetVariants } from "types/contest";
import { CheckMarkIcon, CrossMarkIcon } from "assets/icons";
import "./my-bets-card.scss";

interface MyBetsCardProps {
  wagerModifier: string;
  variant: MyBetVariants;
  moneyLine: number;
  homeTeam: string;
  awayTeam: string;
  wager: number;
  payout: number;
  date: string;
}

interface FooterDataItem {
  value: number;
  text: string;
  classNameModifier: string;
}
interface FooterData {
  pending: FooterDataItem;
  won: FooterDataItem;
  lost: FooterDataItem;
}

export const MyBetsCard: React.FC<MyBetsCardProps> = ({
  wagerModifier,
  variant,
  moneyLine,
  homeTeam,
  awayTeam,
  payout,
  wager,
  date,
}) => {
  const formattedDate = dateToLocalTime(date).format(DateFormats.DATE_AND_TIME);

  const calculatedPrize = calculatePrize(wager, moneyLine);

  const footerData: FooterData = {
    pending: {
      value: calculatedPrize,
      text: "TO WIN",
      classNameModifier: "payout",
    },
    won: { value: calculatedPrize, text: "WON", classNameModifier: "won" },
    lost: { value: payout || 0, text: "RETURN", classNameModifier: "payout" },
  };
  return (
    <div className="my-bets-card">
      <div className="my-bets-card__icon-wrapper">
        {variant === MyBetVariants.PENDING && (
          <div className="my-bets-card__pending-bet" />
        )}
        {variant === MyBetVariants.WON && (
          <CheckMarkIcon className="my-bets-card__icon my-bets-card__icon--won" />
        )}
        {variant === MyBetVariants.LOST && (
          <CrossMarkIcon className="my-bets-card__icon my-bets-card__icon--lost" />
        )}
      </div>
      <div className="my-bets-card__wrapper">
        <div className="my-bets-card__names-moneyline">
          <div className="my-bets-card__teams-names">
            <div className="my-bets-card__team-name">{awayTeam}</div>
            <div className="my-bets-card__team-name">{homeTeam}</div>
          </div>
          <div className="my-bets-card__moneyline">{formatValueToDisplay(moneyLine)}</div>
        </div>
        <div className="my-bets-card__container">
          <div className="my-bets-card__teams">{wagerModifier}</div>

          <div className="my-bets-card__date">{formattedDate}</div>
        </div>
        <div className="my-bets-card__container">
          <span className="my-bets-card__wager">{`WAGER ${formatToPoints({
            value: wager,
            separator: "comma",
            withSuffix: true,
          })}`}</span>
          <span
            className={`my-bets-card__footer-text--${footerData[variant].classNameModifier}`}
            key={footerData[variant].text}
          >{`${footerData[variant].text.toUpperCase()}: ${formatToPoints({
            value: footerData[variant].value || 0,
            separator: "comma",
            withSuffix: true,
          })}`}</span>
        </div>
      </div>
    </div>
  );
};
