import { ChangeEvent, useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import clsx from "clsx";
import { BottomNavigation } from "components/bottom-navigation";
import { Button } from "components/button";
import { selectAuthPayload } from "store/auth";
import { UNKNOWN_ERROR_FALLBACK } from "helpers/constants";
import { getCssVariable } from "helpers/get-css-variable";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { useOnClickAway } from "hooks/use-on-click-away";
import { useScrollLock } from "hooks/use-scroll-lock";
import { CurvedArrowIcon, PersonFilledIcon, TrashIcon } from "assets/icons";
import { CrossMarkIcon } from "assets/icons/cross-mark-icon";
import { MinusIcon } from "assets/icons/minus-icon";
import { PlusIcon } from "assets/icons/plus-icon";
import "./profile-image-selector.scss";

interface ProfileImageSelectorProps {
  onImageChange: (file: File | null) => void;
  onDeleteImage: (toDelete: boolean) => void;
  value: File | null;
}

const MIN_ZOOM = 100;
const MAX_ZOOM = 500;
const zoomClickDifference = 20;
const EDITOR_BORDER_RADIUS = 2000;
const EDITOR_BORDER_SIZE = 0;
const EDITOR_DIMENSIONS_MD = 400;
const EDITOR_ZOOM_SCALE = 100;
const ACCEPTED_EXTENSIONS = ["image/heic", "image/webp", "image/png", "image/jpeg"];

export const ProfileImageSelector: React.FC<ProfileImageSelectorProps> = ({
  onImageChange,
  onDeleteImage,
  value,
}) => {
  const [windowWidth, setWindowWidth] = useState(parseInt(getCssVariable("width")));
  const [image, setImage] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const [isSelectTriggered, setIsSelectTriggered] = useState(false);
  const [isToDelete, setIsToDelete] = useState(false);
  const [cropImageZoom, setCropImageZoom] = useState(MIN_ZOOM);
  const { profileImageUrl } = useSelector(selectAuthPayload);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const avatarEditorRef = useRef<AvatarEditor>(null);
  const actionSelectorRef = useRef<HTMLDivElement | null>(null);
  const imageCropperRef = useRef<HTMLDivElement | null>(null);

  useScrollLock(!!imageToCrop || isSelectTriggered);

  const onDialogOpen = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onCancelClick = () => {
    setImageToCrop(null);
  };

  const onSelectionClose = () => {
    setIsSelectTriggered(false);
  };

  useOnClickAway([actionSelectorRef], onSelectionClose);
  useOnClickAway([imageCropperRef], onCancelClick);

  useEffect(() => {
    const setWidth = () => {
      setWindowWidth(parseInt(getCssVariable("width")));
    };
    window.addEventListener("resize", setWidth);
    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, []);

  const isMobile = useMediaQuery(MediaQueryBreakpoints.MOBILE);
  const editorSize = isMobile ? windowWidth : EDITOR_DIMENSIONS_MD;

  const handleZoomIn = () => {
    if (cropImageZoom > MAX_ZOOM - zoomClickDifference) {
      setCropImageZoom(MAX_ZOOM);
    } else {
      setCropImageZoom(cropImageZoom + zoomClickDifference);
    }
  };

  const handleZoomOut = () => {
    if (cropImageZoom < MIN_ZOOM + zoomClickDifference) {
      setCropImageZoom(MIN_ZOOM);
    } else {
      setCropImageZoom(cropImageZoom - zoomClickDifference);
    }
  };

  const handleSaveButton = () => {
    const croppedImage = avatarEditorRef.current?.getImage();

    if (croppedImage) {
      setImage(croppedImage.toDataURL() ?? null);
      croppedImage.toBlob((blob) => {
        if (blob) {
          onImageChange(new File([blob], "profileImg"));
        }
      });
    }
    setImageToCrop(null);
  };

  const onProcessFile = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSelectTriggered(false);
    setCropImageZoom(MIN_ZOOM);
    event.preventDefault();
    const reader = new FileReader();
    let file = value;
    if (event.target.files) {
      file = event.target.files[0];
    }

    try {
      if (file) {
        reader.readAsDataURL(file);
      }
    } catch (error) {
      toast.error(UNKNOWN_ERROR_FALLBACK);
    }

    reader.addEventListener("loadend", () => {
      if (reader.result && file && ACCEPTED_EXTENSIONS.includes(file.type)) {
        setImageToCrop(reader.result.toString());
      }
    });
    setInputValue("");
    setIsToDelete(false);
  };

  const url = image ?? profileImageUrl;

  return (
    <div className="profile-image-selector">
      <button
        type="button"
        onClick={() => (url ? setIsSelectTriggered(true) : onDialogOpen())}
        className="profile-image-selector__add-image"
      >
        <input
          type="file"
          onChange={onProcessFile}
          hidden
          value={inputValue}
          ref={fileInputRef}
        />
        {url ? (
          <CurvedArrowIcon />
        ) : (
          <PlusIcon className="profile-image-selector__add-icon" />
        )}
      </button>
      {!isToDelete && url ? (
        <img
          src={url}
          alt="profileImage"
          className="profile-image-selector__profile-image"
        />
      ) : (
        <PersonFilledIcon
          className={clsx(
            "profile-image-selector__profile-image",
            "profile-image-selector__profile-image--default"
          )}
        />
      )}
      {isSelectTriggered && (
        <div className="profile-image-selector__selection-menu-wrapper">
          <div ref={actionSelectorRef} className="profile-image-selector__selection-menu">
            <div className="profile-image-selector__selection-menu-header">
              <span>Change profile photo</span>
              <Button variant="clear" type="button" onClick={onSelectionClose}>
                <CrossMarkIcon />
              </Button>
            </div>
            <div className="profile-image-selector__selection-menu-option">
              <div className="profile-image-selector__plus-icon">
                <PlusIcon className="profile-image-selector__add-icon" />
              </div>
              <Button variant="link" type="button" onClick={onDialogOpen}>
                NEW PHOTO
              </Button>
            </div>
            <div className="profile-image-selector__selection-menu-option">
              <div className="profile-image-selector__trash-icon">
                <TrashIcon />
              </div>
              <Button
                variant="link"
                type="button"
                onClick={() => {
                  onDeleteImage(true);
                  setImage(null);
                  setIsToDelete(true);
                  onImageChange(null);
                  setIsSelectTriggered(false);
                }}
              >
                DELETE PHOTO
              </Button>
            </div>
          </div>
        </div>
      )}
      {!!imageToCrop && (
        <div className="profile-image-selector__avatar-editor-wrapper">
          <div ref={imageCropperRef} className="profile-image-selector__editor-content">
            <div className="profile-image-selector__editor-header">
              <span>Edit your profile photo</span>
              <Button variant="clear" type="button" onClick={onCancelClick}>
                <CrossMarkIcon />
              </Button>
            </div>
            <div className="profile-image-selector__editor-main">
              <AvatarEditor
                ref={avatarEditorRef}
                image={imageToCrop}
                border={EDITOR_BORDER_SIZE}
                width={editorSize}
                height={editorSize}
                borderRadius={EDITOR_BORDER_RADIUS}
                scale={cropImageZoom / EDITOR_ZOOM_SCALE}
              />
              <div className="profile-image-selector__zoom-wrapper">
                <Button
                  className={clsx("profile-image-selector__zoom-button", {
                    "profile-image-selector__zoom-button--disabled":
                      cropImageZoom <= MIN_ZOOM,
                  })}
                  variant="clear"
                  type="button"
                  disabled={cropImageZoom === MIN_ZOOM}
                  onClick={handleZoomOut}
                >
                  <MinusIcon className="profile-image-selector__zoom-icon profile-image-selector__zoom-icon--minus" />
                </Button>
                <input
                  className="profile-image-selector__zoom-input"
                  type="range"
                  min={MIN_ZOOM}
                  max={MAX_ZOOM}
                  value={cropImageZoom}
                  onChange={(event) => setCropImageZoom(event.target.valueAsNumber)}
                  step={1}
                />
                <Button
                  className={clsx("profile-image-selector__zoom-button", {
                    "profile-image-selector__zoom-button--disabled":
                      cropImageZoom >= MAX_ZOOM,
                  })}
                  variant="clear"
                  type="button"
                  disabled={cropImageZoom === MAX_ZOOM}
                  onClick={handleZoomIn}
                >
                  <PlusIcon className="profile-image-selector__zoom-icon profile-image-selector__zoom-icon--plus" />
                </Button>
              </div>
              <span className="profile-image-selector__zoom-label">
                Use zoom in/out panel
              </span>
            </div>
            <div className="profile-image-selector__editor-navigation">
              <BottomNavigation
                onCancelAction={onCancelClick}
                onSubmit={handleSaveButton}
                submitText="CONFIRM"
                isSubmitType={false}
                onlySubmitOnMd
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
