import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useParams, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { ContestPoints, ContestSection } from "components/contest";
import { ContestSectionContent } from "components/contest/contest-section/contest-section-content";
import {
  CONTEST_PARAM,
  ContestsMobileSearchView,
} from "components/contests-mobile-search-view";
import { ContestsMobileSortView } from "components/contests-mobile-sort-view";
import { RouterNavbar } from "components/router-navbar";
import { SearchBar } from "components/search-bar";
import { SelectOdd } from "components/select-odd";
import {
  selectSelectedSlate,
  selectShowScreenDarkenLeaderboard,
  selectSortOption,
  setSelectedSlate,
  setShowScreenDarkenLeaderboard,
} from "store/app-slice";
import { selectAuthPayload } from "store/auth";
import {
  fetchUsersDetailsAndParlay,
  selectMyContestRequestState,
  selectMyContests,
  selectSelectedMyContest,
  selectUsersDetails,
  setSelectedMyContest,
} from "store/my-contest-slice";
import { getContestUrl } from "helpers/get-contest-url";
import { sortContests } from "helpers/sort-contests";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { MyContestSmTab } from "types/contest";
import { MyContestItem, RequestState } from "types/request";
import { PathsMyContests } from "types/router";
import "./my-contests-sm-content.scss";

const CONTEST_NAV_TABS: {
  label: string;
  url: MyContestSmTab;
  isActionPerforming?: boolean;
}[] = [
  {
    label: "Slate",
    url: MyContestSmTab.SLATE,
  },
  { label: "My Bets", url: MyContestSmTab.MY_BETS, isActionPerforming: true },
  { label: "Leaderboard", url: MyContestSmTab.LEADERBOARD },
];

export const MyContestsSmContent: React.FC = () => {
  const { inProgressContests, previousContests } = useAppSelector(selectMyContests);
  const selectedContest = useAppSelector(selectSelectedMyContest);
  const { pathname } = useLocation();
  const { sortOrder, sortField } = useAppSelector(selectSortOption);
  const [searchParams] = useSearchParams();
  const contestParamValue = searchParams.get(CONTEST_PARAM);
  const showScreenDarkenLeaderboard = useAppSelector(selectShowScreenDarkenLeaderboard);
  const sortedActiveContests = useMemo(
    () => sortContests(inProgressContests, sortOrder, sortField, contestParamValue),
    [contestParamValue, inProgressContests, sortField, sortOrder]
  );
  const sortedPreviousContests = useMemo(
    () => sortContests(previousContests, sortOrder, sortField, contestParamValue),
    [contestParamValue, previousContests, sortField, sortOrder]
  );
  const [userDetailsInContest, setUserDetailsInContest] = useState<MyContestItem | null>(
    null
  );
  const isChildrenPage = Object.values(MyContestSmTab).some((tab) =>
    pathname.includes(tab)
  );
  const usersDetails = useAppSelector(selectUsersDetails);
  const { username } = useAppSelector(selectAuthPayload);
  const requestState = useAppSelector(selectMyContestRequestState);
  const { contestId } = useParams<{ contestId?: string }>();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (showScreenDarkenLeaderboard) {
      dispatch(setShowScreenDarkenLeaderboard(false));
    }
  }, [dispatch, showScreenDarkenLeaderboard]);
  useEffect(() => {
    if (contestId) {
      const newSelectedContest = [...inProgressContests, ...previousContests].find(
        ({ contest }) => String(contest.id) === contestId
      );
      if (newSelectedContest) {
        dispatch(setSelectedMyContest(newSelectedContest));
        dispatch(fetchUsersDetailsAndParlay(newSelectedContest.contest.id));
      }
    }
  }, [contestId, dispatch, inProgressContests, previousContests]);

  useEffect(() => {
    dispatch(setSelectedSlate(null));
  }, [dispatch, contestId]);

  useEffect(() => {
    if (selectedContest && usersDetails) {
      const filteredUser = usersDetails.find((user) => user.userName === username);
      if (filteredUser) {
        setUserDetailsInContest(filteredUser);
      }
    }
  }, [selectedContest, username, usersDetails]);

  const isSortView = pathname.includes(PathsMyContests.SORT);
  const isSearchView = pathname.includes(PathsMyContests.SEARCH);
  const mainContentHidden = isSortView || isSearchView;
  const selectedSlate = useAppSelector(selectSelectedSlate);

  const contestSections = [
    { title: "In progress", contests: sortedActiveContests },
    { title: "Previous", contests: sortedPreviousContests },
  ];

  const temporaryMyContests = previousContests.concat(inProgressContests);
  return selectedSlate ? (
    <SelectOdd {...selectedSlate} />
  ) : (
    <>
      <ContestsMobileSearchView show={isSearchView} contests={temporaryMyContests} />
      <ContestsMobileSortView show={isSortView} />
      <div
        className={clsx(
          "my-contests-sm-content",
          mainContentHidden && "my-contests-sm-content--hidden"
        )}
      >
        <SearchBar
          mobileSortPath={PathsMyContests.SORT_ABSOLUTE}
          mobileSearchPath={PathsMyContests.SEARCH_ABSOLUTE}
        />
        {selectedContest && isChildrenPage ? (
          <>
            <div className="my-contests-sm-content__navigation-wrapper">
              <RouterNavbar
                navigationClassName="my-contests-sm-content__navigation"
                tabs={CONTEST_NAV_TABS.map(({ label, url, isActionPerforming }) => {
                  return {
                    label: label,
                    url: getContestUrl(
                      selectedContest.contest.status,
                      selectedContest.contest.id,
                      url
                    ),
                    uniqueStringPath: url,
                    isActionPerforming,
                  };
                })}
              />
            </div>

            <div className="my-contests-sm-content__children-page">
              <div className="my-contests-sm-content__outlet-wrapper">
                <Outlet />
              </div>
              <ContestPoints
                isLoading={[RequestState.PENDING, RequestState.IDLE].includes(
                  requestState
                )}
                budgetPoints={selectedContest.contest.perContestantBudget}
                availablePoints={userDetailsInContest?.remainingBudget ?? 0}
              />
            </div>
          </>
        ) : (
          <>
            {contestSections.map(({ title, contests }) => (
              <ContestSection key={title} title={title} showHeaderOnSm>
                <ContestSectionContent contests={contests} />
              </ContestSection>
            ))}
          </>
        )}
      </div>
    </>
  );
};
