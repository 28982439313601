import { useEffect, useState } from "react";
import clsx from "clsx";
import { getUserStats } from "services/user-service";
import { DonutChart } from "components/donut-chart";
import { LinearChart } from "components/linear-chart";
import { UserStatsResponse } from "types/request";
import "./profile-stats-view.scss";

interface StatsViewProps {
  className?: string;
}

export const StatsView: React.FC<StatsViewProps> = ({ className }) => {
  const [userStats, setUserStats] = useState<UserStatsResponse | null>(null);

  useEffect(() => {
    const userStatsResponse = getUserStats();
    setUserStats(userStatsResponse);
  }, []);

  if (!userStats) {
    return <div className={clsx("stats-view", className)}>No stats available</div>;
  }
  return (
    <div className={clsx("stats-view", className)}>
      <div className="stats-view__container-stats">
        {userStats.sportsChart.chartPoints && (
          <DonutChart chartPoints={userStats.sportsChart.chartPoints} />
        )}

        {userStats.categoryCharts.map(
          ({ chartCategory, chartPoints, income, percentageIncome }) => (
            <LinearChart
              key={chartCategory}
              chartCategory={chartCategory}
              chartPoints={chartPoints}
              income={income}
              percentageIncome={percentageIncome}
            />
          )
        )}
      </div>
      <div className="stats-view__coming-soon">COMING SOON</div>
    </div>
  );
};
