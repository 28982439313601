import { ReactNode } from "react";
import clsx from "clsx";
import "./stepper.scss";

interface StepperProps {
  hideSteps?: boolean;
  activeStep: number;
  numberOfSteps: number;
  element: ReactNode;
}

export const Stepper: React.FC<StepperProps> = ({
  element,
  activeStep,
  numberOfSteps,
  hideSteps = false,
}) => {
  return (
    <div data-testid="stepper" className="stepper">
      {!hideSteps && (
        <div data-testid="stepper-header" className="stepper__header">
          {[...Array(numberOfSteps)].map((_, index) => {
            return (
              <div
                data-testid="step"
                key={index}
                className={clsx("stepper__step", {
                  "stepper__step--active": index === activeStep,
                })}
              />
            );
          })}
        </div>
      )}
      <div className="stepper__content">{element}</div>
    </div>
  );
};
