import { Cell, Label, Pie, PieChart, PieLabel } from "recharts";
import { getCssVariable } from "helpers/get-css-variable";
import { ChartPointType } from "types/request";
import "./donut-chart.scss";

const RADIAN = Math.PI / 180;
const LABEL_RADIUS = 23;
const LABEL_TEXT_PADDING_LEFT = 7;
const LABEL_TEXT_PADDING_TOP = 28;
const PIE_CHART_SIZE = 266;
const PIE_CHART_INNER_RADIUS = 50;
const PIE_CHART_OUTER_RADIUS = 90;
const POINT_RADIUS = 5;

interface DonutChartProps {
  chartPoints: ChartPointType[];
}

export const DonutChart: React.FC<DonutChartProps> = ({ chartPoints }) => {
  const renderCustomizedLabel: PieLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN) - LABEL_RADIUS;
    const y = cy + radius * Math.sin(-midAngle * RADIAN) - LABEL_RADIUS;

    return (
      <g>
        <rect
          className="donut-chart__label"
          x={x}
          y={y}
          rx={50}
          width={LABEL_RADIUS * 2}
          height={LABEL_RADIUS * 2}
        />
        <text
          className="donut-chart__label-text"
          fill="black"
          x={x + LABEL_TEXT_PADDING_LEFT}
          y={y + LABEL_TEXT_PADDING_TOP}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };
  return (
    <div className="donut-chart">
      <div className="donut-chart__title donut-chart__title--chart">SPORTS</div>

      <PieChart width={PIE_CHART_SIZE} height={PIE_CHART_SIZE}>
        <Pie
          data={chartPoints}
          dataKey="value"
          innerRadius={PIE_CHART_INNER_RADIUS}
          outerRadius={PIE_CHART_OUTER_RADIUS}
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {chartPoints.map((entry, index) => (
            <Cell
              key={`cell-${entry.name}-${entry.name}`}
              fill={getCssVariable(`--chart-color-${index}`)}
            >
              <Label />
            </Cell>
          ))}
        </Pie>
      </PieChart>
      <div className="donut-chart__parts">
        <div className="donut-chart__title donut-chart__title--parts">SPORTS</div>
        {chartPoints.map((element, index) => (
          <div key={element.name} className="donut-chart__part">
            <svg width={POINT_RADIUS * 2} height={POINT_RADIUS * 2}>
              <circle
                r={POINT_RADIUS}
                cx={POINT_RADIUS}
                cy={POINT_RADIUS}
                fill={getCssVariable(`--chart-color-${index}`)}
              />
            </svg>
            {`${element.name} ($${element.value})`}
          </div>
        ))}
      </div>
    </div>
  );
};
