import { SVGProps } from "react";

export const CurvedArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.99 20.631c4.15-.465 7.476-3.782 7.95-7.932.62-5.427-3.598-10.047-8.885-10.106V.16c0-.135-.169-.207-.283-.123L3.76 3.718a.154.154 0 0 0 0 .249l5.012 3.681c.114.085.283.009.283-.123v-2.43c3.715.06 6.69 3.22 6.437 7-.216 3.25-2.865 5.886-6.115 6.094-3.445.22-6.374-2.24-6.898-5.495a1.249 1.249 0 0 0-1.234-1.044c-.757 0-1.348.672-1.23 1.42.736 4.637 5.017 8.115 9.974 7.561Z"
      fill="currentColor"
    />
  </svg>
);
