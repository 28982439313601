import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Topbar } from "components/topbar";
import { setTopbarTitle } from "store/app-slice";
import {
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
} from "helpers/constants/terms-and-service";
import { useAppDispatch } from "hooks/store";
import { Paths } from "types/router";
import { TopbarTitle } from "types/topbar";
import "./terms-and-privacy.scss";

export const TermsAndPrivacyPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTopbarTitle(TopbarTitle.TERMS));
  }, [dispatch]);
  const navigate = useNavigate();

  return (
    <>
      <Topbar handleMenuButtonClick={() => navigate(Paths.LOBBY)} arrow />
      <div className="terms">
        <div className="terms__main-header-wrapper">
          <span className="terms__main-header">Bet Max Action</span>
          <span className="terms__main-subheader">Terms and Conditions</span>
        </div>
        <div className="terms__date-wrapper">
          <span className="terms__date">Effective Date: January, 2022</span>
          <span className="terms__date">Last Updated: February 15, 2022</span>
        </div>
        {TERMS_AND_CONDITIONS.map(({ title, content }) => (
          <React.Fragment key={title}>
            <p className="terms__paragraph-header">{title}</p>
            <p>{content}</p>
          </React.Fragment>
        ))}
        <div className="terms__main-header-wrapper">
          <span className="terms__main-subheader">Privacy Policy</span>
          {PRIVACY_POLICY.map(({ title, content }) => (
            <React.Fragment key={title}>
              <p className="terms__paragraph-header">{title}</p>
              <p>{content}</p>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
