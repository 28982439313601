import clsx from "clsx";
import {
  ERROR_PASSWORD_CAPITAL_LETTER,
  ERROR_PASSWORD_INVALID,
  ERROR_PASSWORD_NUMBER,
  ERROR_PASSWORD_TOO_SHORT,
  REGEX_PASSWORD_CAPITAL_LETTER,
  REGEX_PASSWORD_LENGTH,
  REGEX_PASSWORD_NUMBER,
} from "helpers/constants";
import { TickIcon } from "assets/icons/tick-icon";
import "./password-error.scss";

interface PasswordErrorProps {
  altTextColor?: boolean;
  passwordValue?: string;
}

const errorArray = [
  ERROR_PASSWORD_TOO_SHORT,
  ERROR_PASSWORD_CAPITAL_LETTER,
  ERROR_PASSWORD_NUMBER,
];

const regexArray = [
  { regex: REGEX_PASSWORD_LENGTH, error: ERROR_PASSWORD_TOO_SHORT },
  { regex: REGEX_PASSWORD_NUMBER, error: ERROR_PASSWORD_NUMBER },
  { regex: REGEX_PASSWORD_CAPITAL_LETTER, error: ERROR_PASSWORD_CAPITAL_LETTER },
];

export const PasswordError: React.FC<PasswordErrorProps> = ({
  altTextColor,
  passwordValue,
}) => {
  const errors: string[] = [];

  if (passwordValue) {
    regexArray.forEach(({ regex, error }) => {
      if (!regex.test(passwordValue)) {
        errors.push(error);
      }
    });
  }

  return (
    <div
      data-testid="password-error"
      className={clsx("password-error", { "password-error--white-text": altTextColor })}
    >
      {passwordValue ? (
        <div
          data-testid="password-error__message"
          className={clsx("password-error__message", {
            "password-error__message--error": errors.length,
          })}
        >
          Must contain:
          {errorArray.map((error) =>
            !errors.includes(error) ? (
              <div
                key={error}
                className={clsx("password-error__message-correct", {
                  "password-error__message-correct--white-text": altTextColor,
                })}
              >
                <TickIcon />
                <span
                  className={clsx({
                    "password-error__message-correct--white-text": altTextColor,
                  })}
                >
                  {error}
                </span>
              </div>
            ) : (
              <span key={error} className="password-error__message-incorrect">
                {error}
              </span>
            )
          )}
        </div>
      ) : (
        <span>{ERROR_PASSWORD_INVALID}</span>
      )}
    </div>
  );
};
