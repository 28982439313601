import { Button } from "components/button";
import { Label } from "components/label";
import { StateSetter } from "types/state-setter";
import { CrossMarkCloseIcon } from "assets/icons/cross-mark-close-icon";
import { ExclamationMarkIcon } from "assets/icons/exclamation-mark-icon";
import "./alert.scss";

export interface AlertProps {
  message: string;
  setError: StateSetter<string | null>;
}

export const Alert: React.FC<AlertProps> = ({ message, setError }) => {
  return (
    <Label className="alert" variant="red" rounded>
      <ExclamationMarkIcon />
      {message}
      <Button className="alert__close-button" onClick={() => setError(null)}>
        <CrossMarkCloseIcon />
      </Button>
    </Label>
  );
};
