import { SVGProps } from "react";

export const AwardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.992"
    height="24.99"
    viewBox="0 0 19.992 24.99"
    {...props}
  >
    <path
      id="_7d65fc70-295e-40fa-abd8-a40c176df59e"
      data-name="7d65fc70-295e-40fa-abd8-a40c176df59e"
      d="M61.2,2.5a7.5,7.5,0,1,0,7.5,7.5,7.5,7.5,0,0,0-7.5-7.5Zm6.83,14.792a10,10,0,1,0-13.66,0l-1.879,5.161a.61.61,0,0,0,.689.807l2.16-.417a.61.61,0,0,1,.59.215l1.385,1.708a.61.61,0,0,0,1.047-.176l1.7-4.663a9.786,9.786,0,0,0,2.282,0l1.7,4.663a.61.61,0,0,0,1.047.176l1.385-1.708a.61.61,0,0,1,.59-.215l2.159.418a.61.61,0,0,0,.69-.809ZM52.449,10A8.746,8.746,0,1,1,61.2,18.742,8.757,8.757,0,0,1,52.449,10Z"
      transform="translate(-51.2)"
      fill="currentColor"
      opacity="0.853"
    />
  </svg>
);
