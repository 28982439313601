import { Auth } from "aws-amplify";
import { resetState, selectAuthPayload } from "store/auth";
import { useAppDispatch, useAppSelector } from "hooks/store";

export const useLogout = () => {
  const { userId } = useAppSelector(selectAuthPayload);
  const dispatch = useAppDispatch();

  const logout = async () => {
    dispatch(resetState());
    if (userId) {
      await Auth.signOut();
    }
  };

  return logout;
};
