import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ERROR_GAMES_DOWNLOAD } from "helpers/constants";
import { mapContestsResponse } from "helpers/map-contests-response";
import { mapMyContestsResponse } from "helpers/map-my-contests-response";
import { mapParlayResponse } from "helpers/map-parlay-response";
import axios from "utils/axios";
import {
  ContestStatus,
  CreateContest,
  CreateContestResponse,
  GetGames,
  GetGamesResponse,
} from "types/contest";
import {
  ContestParlayResponse,
  ContestsResponseItem,
  ContestWagers,
  MakePicksResponse,
  MyContestResponse,
} from "types/request";

export const getUserDetailsInContestById = async (contestId: number) => {
  const { data } = await axios.get<MyContestResponse[]>("Contestant/Get", {
    params: {
      contestId,
      skipCache: true,
    },
  });
  return mapMyContestsResponse(data);
};

export const getContestsByStatus = async (status: ContestStatus, daysInPast?: number) => {
  const { data } = await axios.get<ContestsResponseItem[]>("Contest/GetByStatus", {
    params: { statuses: status, skipCache: true, daysInPast: daysInPast || 100 },
  });
  return mapContestsResponse(data);
};

export const getContestsByUser = async (daysInPast = 90) => {
  const { data } = await axios.get<ContestsResponseItem[]>("Contest/GetByUser", {
    params: {
      daysInPast,
      skipCache: true,
    },
  });
  const convertedResponse = mapContestsResponse(data);
  const activeContests = convertedResponse.filter(
    (contest) => contest.contest.status === ContestStatus.ACTIVE
  );
  const upcomingContests = convertedResponse.filter(
    (contest) => contest.contest.status === ContestStatus.UPCOMING
  );
  const completedContests = convertedResponse.filter(
    (contest) => contest.contest.status === ContestStatus.COMPLETED
  );
  return {
    inProgressContests: [...activeContests, ...upcomingContests],
    previousContests: completedContests,
  };
};
export interface MakePicksFields {
  contestId: number;
  contestWagers: ContestWagers[];
}

export const makePicks = async ({ contestId, contestWagers }: MakePicksFields) => {
  const { data } = await axios.post<MakePicksResponse>("Contestant/MakePicks", {
    contestId: contestId,
    contestWagers: contestWagers,
  });
  return data;
};

export const getContestParlay = async (contestId: number, UserId?: number) => {
  try {
    const { data } = await axios.get<ContestParlayResponse>(
      "Contestant/GetContestParlay",
      {
        params: { contestId, UserId, skipCache: true },
      }
    );
    return mapParlayResponse(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 500) {
      return null;
    }
    return Promise.reject(error);
  }
};

export const getGames = async (
  { gameDate, sport, days }: GetGames,
  signal?: AbortSignal
) => {
  try {
    const { data } = await axios.get<GetGamesResponse[]>("/Games", {
      params: { gameDate: gameDate, sport: sport, days: days, odds: true },
      signal,
    });
    return data;
  } catch (error) {
    if (!(error instanceof Error) || error.message !== "canceled")
      toast.error(ERROR_GAMES_DOWNLOAD);
  }
};

export const createContest = async ({
  contestType,
  maxContestants,
  prizePool,
  perContestantBudget,
  distributedPrizes,
  name,
  contestPicks,
  acceptingEntries,
}: CreateContest) => {
  const { data } = await axios.post<CreateContestResponse>("/Contest/Create", {
    contest: {
      name,
      contestType,
      distributedPrizes,
      maxContestants,
      prizePool,
      perContestantBudget,
      acceptingEntries,
    },
    contestPicks,
  });
  return data;
};
export const joinContest = async (contestId: number) => {
  await axios.get("Contestant/Join", {
    params: { contestId },
  });
};
