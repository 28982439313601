import { Link } from "react-router-dom";
import { ContentWithSubheader } from "components/content-with-subheader";
import { LoaderSpinner } from "components/loader-spinner";
import { selectPreviousPage } from "store/app-slice";
import { formatToPoints } from "helpers/format-to-points";
import { useAppSelector } from "hooks/store";
import "./contest-points.scss";

interface ContestPointsProps {
  availablePoints: number;
  budgetPoints: number;
  isLoading: boolean;
}

export const ContestPoints: React.FC<ContestPointsProps> = ({
  availablePoints,
  budgetPoints,
  isLoading,
}) => {
  const previousPage = useAppSelector(selectPreviousPage);
  return (
    <div className="contest-points">
      {isLoading ? (
        <div className="contest-content__points-loader">
          <LoaderSpinner size="small" />
        </div>
      ) : (
        <div className="contest-points__content">
          <ContentWithSubheader
            content={formatToPoints({ value: Math.floor(availablePoints) })}
            subheader="AVAILABLE"
            boldContent
          />
          <ContentWithSubheader
            content={
              <div className="contest-points__budget-points">
                {formatToPoints({
                  value: budgetPoints,
                })}
              </div>
            }
            subheader="BUDGET"
            subheaderClassName="contest-points__budget-points"
            boldContent
          />
        </div>
      )}
      {previousPage && (
        <Link className="contest-points__done-link" to={previousPage}>
          DONE
        </Link>
      )}
    </div>
  );
};
