import { SVGProps } from "react";

export const OnboardingBudgetBoardSVG = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={172.226} height={164.737} {...restProps}>
    <g data-name="Group 329" transform="translate(-2241.763 -3860.808)">
      <rect
        data-name="Rectangle 306"
        width={172.226}
        height={164.737}
        rx={7.963}
        transform="translate(2241.763 3860.808)"
        fill="#89aeff"
      />
      <rect
        data-name="Rectangle 307"
        width={78.941}
        height={4.78}
        rx={2.39}
        transform="translate(2262.724 3879.874)"
        fill="#d2dcff"
      />
      <rect
        data-name="Rectangle 308"
        width={55.1}
        height={4.78}
        rx={2.39}
        transform="translate(2262.724 3891.797)"
        fill="#d2dcff"
      />
      <rect
        data-name="Rectangle 309"
        width={16.667}
        height={3.414}
        rx={1.707}
        transform="translate(2262.724 3912.7)"
        fill="#6277d7"
      />
      <rect
        data-name="Rectangle 311"
        width={60.874}
        height={10.311}
        rx={1.707}
        transform="translate(2333.261 3994.167)"
        fill="#d2dcff"
      />
      <g data-name="Group 328">
        <path
          data-name="Path 947"
          d="M2288.231 4004.6a3.184 3.184 0 0 1 3.184-3.183 22.47 22.47 0 1 1 22.47-22.47 3.183 3.183 0 1 1 6.367 0 28.838 28.838 0 1 0-28.837 28.837 3.184 3.184 0 0 1-3.184-3.184Z"
          fill="#d2dcff"
        />
        <path
          data-name="Path 948"
          d="M2317.069 3975.768a3.183 3.183 0 0 0-3.184 3.184 22.5 22.5 0 0 1-22.47 22.47 3.183 3.183 0 1 0 0 6.367 28.869 28.869 0 0 0 28.837-28.837 3.183 3.183 0 0 0-3.183-3.184Z"
          fill="#6277d7"
        />
      </g>
    </g>
  </svg>
);
