import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ProtectedLayout } from "components/protected-layout";
import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { Paths } from "types/router";
import { CodeVerificationPage } from "pages/code-verification";
import {
  ForgotPasswordPage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  SignUpSteps,
  TermsAndPrivacyPage,
} from "./pages";

export const AppRouter = () => {
  const { userId } = useAppSelector(selectAuthPayload);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={Paths.HOME}
          element={<Navigate to={userId ? Paths.LOBBY : Paths.SIGN_IN} />}
        />
        <Route path={Paths.SIGN_UP} element={<SignUpPage />} />
        <Route path={Paths.SIGN_IN} element={<SignInPage />} />
        <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={Paths.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={Paths.SIGN_UP_STEPS} element={<SignUpSteps />} />
        <Route path={Paths.CODE_VERIFICATION} element={<CodeVerificationPage />} />
        <Route path={Paths.TERMS} element={<TermsAndPrivacyPage />} />
        <Route path="/*" element={<ProtectedLayout />} />
      </Routes>
    </BrowserRouter>
  );
};
