import { Auth } from "aws-amplify";

const CODE_FALLBACK = "000000";

export const checkUserNotExists = async (email: string) => {
  const result = await Auth.confirmSignUp(email, CODE_FALLBACK, {
    forceAliasCreation: false,
  })
    .then(() => false)
    .catch((err) => {
      return err.code === "UserNotFoundException";
    });
  return result;
};
