import { useRef, useState } from "react";
import clsx from "clsx";
import { Button } from "components/button";
import { selectSortOption, setSortOption } from "store/app-slice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useOnClickAway } from "hooks/use-on-click-away";
import { SortOption, SortOptions } from "types/sort-contests";
import { SortIcon } from "assets/icons";
import "./sort-dropdown.scss";

interface SortDropdownProps {
  className?: string;
}

export const SortDropdown: React.FC<SortDropdownProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const sortOption = useAppSelector(selectSortOption);

  useOnClickAway([divRef], () => setIsOpen(false));

  const handleOptionClick = (sort: SortOption) => {
    setIsOpen((prevState) => !prevState);
    dispatch(setSortOption(sort));
  };
  return (
    <div data-testid="sort-dropdown" className="sort-dropdown" ref={divRef}>
      <button
        data-testid="sort-dropdown-button"
        className={clsx("sort-dropdown__button", className)}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <SortIcon />
      </button>

      {isOpen && (
        <div
          data-testid="sort-dropdown-content"
          className="sort-dropdown__hidden-content"
        >
          <div className="sort-dropdown__bar sort-dropdown__bar--bold">Sort by</div>
          {SortOptions.map((option) => (
            <Button
              data-testid="options-button"
              key={option.label}
              variant="clear"
              className={clsx(
                "sort-dropdown__bar",
                sortOption === option && `sort-dropdown__bar--bold`
              )}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
