import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { updateUser, updateUserProfileImage } from "services/user-service";
import { UNKNOWN_ERROR_FALLBACK } from "helpers/constants";
import { getSession } from "helpers/get-session";
import { Errors } from "types/errors";
import { EditProfileTextInputs } from "types/profile-details";
import { StateSetter } from "types/state-setter";
import { UserFields } from "types/user";

interface UserUpdateFields {
  userId: number | null;
  data: EditProfileTextInputs;
}

interface SignIn {
  email: string;
  password: string;
  onSignIn: () => void;
  onNotConfirmedUser: () => void;
  setError?: StateSetter<string | null>;
}

export const fetchSession = createAsyncThunk(
  "auth/fetchSession",
  async (_, { rejectWithValue }) => {
    try {
      const result = await getSession();
      return {
        ...result,
      };
    } catch (error) {
      if (error !== Errors.NO_CURRENT_USER) {
        return rejectWithValue(error);
      }
    }
  }
);

export const updateAPIUser = createAsyncThunk(
  "auth/updateAPIUser",
  async ({ userId, data }: UserUpdateFields, { rejectWithValue }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
        [UserFields.USERNAME]: data.username,
        [UserFields.FIRST_NAME]: data.firstName,
        [UserFields.LAST_NAME]: data.lastName,
        [UserFields.EMAIL]: data.email,
        [UserFields.PHONE_NUMBER]: data.phoneNumber,
        [UserFields.PREFERRED_CONTACT]: data.preferredContact,
      }).catch(() => {
        return null;
      });
      if (!!result) {
        await updateUser({ ...data, id: userId });
      }
      return { userId, ...data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProfileImage = createAsyncThunk(
  "avatar/fetchAvatar",
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const userResponse = await updateUserProfileImage(formData);
      return userResponse.PictureUri || null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSignIn = createAsyncThunk(
  "auth/fetchSignIn",
  async (
    { email, password, onSignIn, onNotConfirmedUser, setError }: SignIn,
    { rejectWithValue }
  ) => {
    try {
      return await Auth.signIn(email, password)
        .then(() => onSignIn())
        .catch((error) => {
          if (error.code === "UserNotConfirmedException" || null) {
            onNotConfirmedUser();
          } else {
            if (setError) {
              setError(error.message || UNKNOWN_ERROR_FALLBACK);
            }
          }
          return rejectWithValue("error");
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
