import { forwardRef, useRef, useState } from "react";
import clsx from "clsx";
import { Input, InputProps } from "components/inputs";
import { useOnClickAway } from "hooks/use-on-click-away";
import "./animated-labeled-input.scss";

interface AnimatedLabeledInputProps extends Omit<InputProps, "className"> {
  labelText: string;
}

export const AnimatedLabeledInput = forwardRef<
  HTMLInputElement,
  AnimatedLabeledInputProps
>(({ labelText, ...restProps }, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const childInputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickAway([wrapperRef], () => {
    setIsFocused(false);
  });

  const onClickWrapper = () => {
    if (!isFocused) {
      childInputRef?.current?.focus();
      setTimeout(() => childInputRef?.current?.select());
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Tab") {
      setIsFocused(false);
    }
  };

  const onFocusInput = () => {
    setIsFocused(true);
    setTimeout(() => childInputRef?.current?.select());
  };

  return (
    <div
      className="animated-labeled-input"
      ref={wrapperRef}
      onClick={onClickWrapper}
      onKeyDown={onKeyDown}
      role="presentation"
    >
      <label
        className={clsx("animated-labeled-input__label", {
          "animated-labeled-input__label--focused": isFocused,
        })}
      >
        {labelText}
      </label>
      <Input
        childInputRef={childInputRef}
        className="animated-labeled-input__input"
        ref={ref}
        onFocus={onFocusInput}
        {...restProps}
      />
    </div>
  );
});

AnimatedLabeledInput.displayName = "AnimatedLabeledInput";
