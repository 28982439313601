import { Route } from "react-router-dom";
import { AppRouteWrapper } from "components/app-routes";
import { AppRoute } from "types/router";

export const useAppRoutes = (appRoutes: AppRoute[]) => {
  const routes = appRoutes.map(({ title, element, path, children, ...routeProps }) => {
    return (
      <Route
        key={path}
        path={path}
        {...routeProps}
        element={<AppRouteWrapper title={title} element={element} />}
      >
        {(children || []).map((childProps) => (
          <Route key={`${path}/${childProps.path}`} {...childProps} />
        ))}
      </Route>
    );
  });

  return routes;
};
