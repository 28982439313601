import { SVGProps } from "react";

export const LockIcon = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      opacity={0.376}
      d="M12.381 14.421V8.303a1.824 1.824 0 0 0-1.468-1.787l-.082-.016H1.887l-.108.016A1.825 1.825 0 0 0 .311 8.303v6.118a1.823 1.823 0 0 0 1.821 1.821h8.429a1.823 1.823 0 0 0 1.82-1.821Zm-.547 0a1.275 1.275 0 0 1-1.274 1.274H2.135a1.276 1.276 0 0 1-1.274-1.274V8.303a1.277 1.277 0 0 1 1.027-1.251l.03-.006h8.864l.029.006a1.277 1.277 0 0 1 1.027 1.251l-.004 6.118ZM10.738 5.931V4.89a4.39 4.39 0 1 0-8.78 0v1.038h.547V4.89a3.844 3.844 0 0 1 7.687 0v1.038l.546.003Z"
      fill="currentColor"
      stroke="#888"
      strokeWidth={0.5}
    />
    <path
      opacity={0.376}
      d="m8.293 9.033-1.952 1.952-1.947-1.947-.386.386 1.947 1.947-1.947 1.947.386.386 1.947-1.951L8.288 13.7l.386-.386-1.947-1.947L8.674 9.42l-.381-.387Z"
      fill="currentColor"
      stroke="#888"
      strokeWidth={0.5}
    />
  </svg>
);
