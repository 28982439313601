import { BottomNavigation } from "components/bottom-navigation";
import { CommonForm } from "components/common-form";
import { useIntroduceUser } from "hooks/use-introduce-user";
import { Paths } from "types/router";
import { OnboardingBudgetBoardSVG } from "assets/onboarding-budget-board";
import { OnboardingBudgetCupboardSVG } from "assets/onboarding-budget-cupboard";
import { OnboardingBudgetDollarSVG } from "assets/onboarding-budget-dollar";
import { OnboardingPersonSVG } from "assets/onboarding-person";
import "./onboarding-budget.scss";

interface OnboardingBudgetProps {
  onNextClick: () => void;
}

export const OnboardingBudget: React.FC<OnboardingBudgetProps> = ({ onNextClick }) => {
  const introduceUser = useIntroduceUser();

  return (
    <div className="budget">
      <CommonForm
        title="Step 2: Get your Budget"
        subtitle="Everyone receives the same Budget, which you'll use to place wagers in your contests. You MUST wager your entire budget in every contest!"
        footer={
          <BottomNavigation
            cancelText="SKIP"
            cancelPath={Paths.LOBBY}
            onSubmit={onNextClick}
            onCancelAction={async () => {
              await introduceUser();
            }}
          />
        }
      >
        <div className="budget__content">
          <OnboardingBudgetBoardSVG className="budget__board" />
          <OnboardingBudgetCupboardSVG className="budget__cupboard" />
          <OnboardingBudgetDollarSVG className="budget__dollar" />
          <OnboardingPersonSVG className="budget__person" />
        </div>
      </CommonForm>
    </div>
  );
};
