import { getUserDetails, updateUser } from "services/user-service";
import { initialPayload } from "store/auth";
import { API_TOKEN_NAME } from "helpers/constants/api";
import { getUserInfo } from "helpers/get-user-info";
import { AuthPayload } from "types/auth";
import { UserResponse } from "types/user";

const updateUserOnFirstLogin = async (
  userInfo: Omit<AuthPayload, "profileImgUrl" | "availablePoints"> | null,
  userResponse: UserResponse | null,
  userId: number | null
) => {
  if (userResponse && userInfo && userId && userResponse.UserName !== userInfo.username) {
    await updateUser({
      ...userInfo,
      id: userId,
    });
  }
};

export const getSession = async (): Promise<AuthPayload> => {
  const userInfo = await getUserInfo();
  let userId = null;
  let userResponse = null;
  if (userInfo?.tokenId) {
    localStorage.setItem(API_TOKEN_NAME, String(userInfo.tokenId));
    userResponse = await getUserDetails();
    userId = userResponse ? userResponse.Id : null;
  }
  await updateUserOnFirstLogin(userInfo, userResponse, userId);

  if (!userId || !userInfo) {
    return { ...initialPayload };
  }

  return {
    ...initialPayload,
    ...userInfo,
    userId,
    profileImageUrl:
      userResponse && userResponse.PictureUri ? userResponse.PictureUri : null,
  };
};
