import { useEffect } from "react";

export const useEscapePress = (action: () => void) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.key === "Escape") {
        action();
      }
    };

    document.addEventListener("keydown", handler);

    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [action]);
};
