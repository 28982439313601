export const UNKNOWN_ERROR_FALLBACK = "Something went wrong";
export const ERROR_PASSWORD_EMPTY = "You must enter a password";
export const ERROR_PASSWORD_TOO_SHORT = "8 characters";
export const ERROR_PASSWORD_CAPITAL_LETTER = "uppercase letter";
export const ERROR_PASSWORD_NUMBER = "number";
export const ERROR_PASSWORD_INVALID =
  "Must contain: 8 characters, uppercase letter, number";
export const ERROR_PASSWORD_NOT_MATCH = "Passwords should match";
export const ERROR_EMAIL_EMPTY = "Email is required";
export const ERROR_EMAIL_INVALID = "Email pattern is invalid";
export const ERROR_EMAIL_NOT_FOUND = "Email not found";
export const ERROR_PHONE_NUMBER_INVALID = "Incorrect phone number format";
export const ERROR_ADMIN_PANEL = "This field is required to create contest";
export const ERROR_INVALID = "Field is invalid";
export const ERROR_REQUIRED = "Field is required";
export const ERROR_MAX_LENGTH = "The maximum field length is 255";
export const ERROR_MAX_LENGTH_USERNAME = "The maximum field length is 30";
export const ERROR_MIN_LENGTH = "The minimum field length is 5";
export const ERROR_USERNAME_PATTERN =
  "The user name must start with an alphanumeric character and may contain non-contiguous dots and dashes";
export const ERROR_REQUIRED_USA_RESIDENT = "Only US residents can register";
export const ERROR_VERIFICATION_CODE = "Insert valid code";
export const ERROR_GAMES_DOWNLOAD = "Games data download error";
