import { SVGProps } from "react";

export const OnboardingFinalImageSVG = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={325.36} height={234.067} {...restProps}>
    <g data-name="Group 265">
      <g data-name="Group 180">
        <path
          data-name="Path 509"
          d="M63.945 178.574c11.876-21.743-25.614-31.967-34.651-23.343s20.924 48.475 34.651 23.343Z"
          fill="#fe9ecc"
        />
        <path
          data-name="Path 510"
          d="M79.706 171.937c23.3 0 12.758-41.683 2.794-41.683-12.492 0-29.719 41.683-2.794 41.683Z"
          fill="#fe9ecc"
        />
        <path
          data-name="Path 511"
          d="M97.669 187.058c19.213 15.64 36.1-19.356 29.269-29.815s-51.477 11.736-29.269 29.815Z"
          fill="#fe9ecc"
        />
        <path
          data-name="Path 512"
          d="M73.167 197.407a69.151 69.151 0 0 0-14.108-20.78 79.646 79.646 0 0 0-21.467-15.753.647.647 0 0 0-.533 1.18 78.362 78.362 0 0 1 21.1 15.51 67.857 67.857 0 0 1 13.825 20.383c.361.835.7 1.678 1.017 2.526h1.385a58.676 58.676 0 0 0-1.219-3.066Z"
          fill="#ff78ba"
        />
        <path
          data-name="Path 513"
          d="M81.921 138.801a.648.648 0 1 0-1.286-.153 566.445 566.445 0 0 0-3.143 61.825h1.295a565.148 565.148 0 0 1 3.134-61.672Z"
          fill="#ff78ba"
        />
        <path
          data-name="Path 514"
          d="M120.466 161.924a.649.649 0 0 0-.9-.179 122.317 122.317 0 0 0-19.058 16.692 94.447 94.447 0 0 0-15.554 22.034h1.448a93.733 93.733 0 0 1 15.034-21.13 120.879 120.879 0 0 1 18.85-16.519.648.648 0 0 0 .18-.898Z"
          fill="#ff78ba"
        />
        <path
          data-name="Path 515"
          d="M78.787 200.473H54.928a1.022 1.022 0 0 0-.972 1.34l1.141 3.5.577 1.772 8.262 25.346a1.671 1.671 0 0 0 1.589 1.154h29.777a1.67 1.67 0 0 0 1.589-1.154l8.262-25.346.577-1.772 1.142-3.5a1.023 1.023 0 0 0-.973-1.34H78.787Z"
          fill="#d2dcff"
        />
        <path
          data-name="Path 516"
          d="m105.728 205.314-.577 1.771H55.675l-.577-1.771Z"
          fill="#aec3ff"
        />
      </g>
      <g data-name="Group 181" transform="translate(-610.372 -4086.976)">
        <rect
          data-name="Rectangle 188"
          width={172.226}
          height={100.457}
          rx={7.963}
          transform="translate(610.372 4086.976)"
          fill="#6277d7"
        />
        <rect
          data-name="Rectangle 189"
          width={60.744}
          height={3.414}
          rx={1.707}
          transform="translate(629.494 4102.8)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 191"
          width={22.921}
          height={3.414}
          rx={1.707}
          transform="translate(729.349 4172.502)"
          fill="#89aeff"
        />
        <rect
          data-name="Rectangle 192"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2409.374 1721.888)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2045"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2421.374 1709.888)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2046"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2433.374 1697.888)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2047"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2417.503 1730.018)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2048"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2429.503 1718.018)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2049"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2441.503 1706.018)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2050"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2425.633 1738.147)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2051"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2437.633 1726.147)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2052"
          width={10.317}
          height={16.785}
          rx={1.707}
          transform="rotate(-90 2449.633 1714.147)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2053"
          width={33.702}
          height={3.414}
          rx={1.707}
          transform="translate(629.494 4122.689)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2054"
          width={33.702}
          height={3.414}
          rx={1.707}
          transform="translate(629.494 4138.949)"
          fill="#d2dcff"
        />
        <rect
          data-name="Rectangle 2055"
          width={33.702}
          height={3.414}
          rx={1.707}
          transform="translate(629.494 4156.915)"
          fill="#d2dcff"
        />
      </g>
      <g data-name="Group 187">
        <path
          data-name="Path 517"
          d="M278.165 120.846a5.752 5.752 0 0 0 2.891 4.992l.509-1.047a10.735 10.735 0 0 0 .081-9.232 5.754 5.754 0 0 0-3.481 5.287Z"
          fill="#f9afab"
        />
        <g data-name="Group 184" transform="translate(-610.372 -4086.976)">
          <path
            data-name="Path 518"
            d="m826.786 4155.823 1.711-3.336a41.032 41.032 0 0 1-.51-8.93q.052-1.083.162-2.2c3.569.316 14.041.735 21.745-4.153-.568 4.538 1.128 9.565 3.939 13.8.342-.818 1.568-3.348 3.682-3.348 4.229 0 4.869 6.758 2.5 9.977a16.624 16.624 0 0 0 5.037 2.637l.018-.018v19.455h-16.4v-8.478a17.646 17.646 0 0 1-5.545 1.274c-4.127 0-10.385-5.537-13.42-15.025H827.8a1.14 1.14 0 0 1-1.014-1.659Z"
            fill="#fcbeb7"
          />
          <path
            data-name="Path 519"
            d="M860.019 4157.637c2.365-3.219 1.725-9.977-2.5-9.977-2.114 0-3.34 2.53-3.682 3.348-2.811-4.239-4.507-9.266-3.939-13.8-7.7 4.888-18.176 4.469-21.745 4.153q-.109 1.116-.162 2.2a3.487 3.487 0 0 1-1.086-2.336v-.156c.094-7 6.505-18.9 22.152-18.9 16.578 0 19.68 10.793 19.68 10.793s5.707 3.127 5.707 9.431c0 8.283-8.93 17.424-9.364 17.864l-.018.018a16.624 16.624 0 0 1-5.043-2.638Z"
            fill="#311643"
          />
          <path
            data-name="Path 520"
            d="M848.674 4179.711v-8.478a43.5 43.5 0 0 0 13.914-8.39Z"
            fill="#f9afab"
          />
          <circle
            data-name="Ellipse 65"
            cx={4.446}
            cy={4.446}
            r={4.446}
            transform="translate(841.517 4150.612)"
            fill="#f49795"
          />
          <g data-name="Group 182">
            <path
              data-name="Path 521"
              d="M836.457 4161.931a2.875 2.875 0 0 0 1.876-.646 2.556 2.556 0 0 0 .677-.843.409.409 0 0 0-.745-.339 2.012 2.012 0 0 1-2.149.989.41.41 0 0 0-.094.814 3.9 3.9 0 0 0 .435.025Z"
              fill="#311643"
            />
          </g>
          <g data-name="Group 183">
            <path
              data-name="Path 522"
              d="M833.95 4149.611a.893.893 0 0 0 .893-.893v-1.669a.893.893 0 0 0-1.787 0v1.669a.893.893 0 0 0 .894.893Z"
              fill="#311643"
            />
          </g>
          <path
            data-name="Path 523"
            d="M827.924 4147.474h2.758v-5.084a.409.409 0 0 1 .819 0v10.987a.409.409 0 0 1-.819 0v-5.084h-2.758a.409.409 0 0 1 0-.819Z"
            fill="#fff"
          />
        </g>
        <g data-name="Group 185">
          <path
            data-name="Path 525"
            d="m190.445 126.269 46.148 17.975 13.624 18.136a15.392 15.392 0 0 0 8.379 5.637l-20.19 13.9-38.635-20.328 1.591 49.359h-17.334l-14.486-66.846a15.6 15.6 0 0 1 20.903-17.833Z"
            fill="#6277d7"
          />
          <path
            data-name="Path 526"
            d="M201.362 217.918v12.246a2.585 2.585 0 0 1-2.585 2.585h-34.734a.6.6 0 0 1-.6-.6v-2.257a2.785 2.785 0 0 1 1.626-2.532l20.626-9.444Z"
            fill="#2f3d6c"
          />
          <path
            data-name="Path 527"
            d="M201.362 210.95v6.967h-15.666l-1.666-6.967Z"
            fill="#fcbeb7"
          />
          <path
            data-name="Path 528"
            d="m217.111 170.714 21.3 11.2 15.884-11.2Z"
            fill="#4a5ea3"
          />
          <path
            data-name="Path 529"
            d="M169.969 135.846a8.3 8.3 0 0 0 12.11-10.431 15.792 15.792 0 0 0-12.11 10.431Z"
            fill="#4a5ea3"
          />
        </g>
        <path
          data-name="Path 530"
          d="m275.884 104.724 5.5 10.309a10.737 10.737 0 0 1 .18 9.757l-.472.971-4.277-17.731q-.404-1.676-.931-3.306Z"
          fill="#fcbeb7"
        />
        <path
          data-name="Path 531"
          d="M258.596 168.017a11.85 11.85 0 0 1-.323-.089 15.392 15.392 0 0 1-8.056-5.548l-13.624-18.136 1.07-36.872-13.733 2.991-5.447-17.185 36.21-14.283a45.6 45.6 0 0 1 21.191 25.83q.527 1.629.932 3.306l4.277 17.731 10.489 43.489-29.438-.728a15.4 15.4 0 0 1-3.548-.506Z"
          fill="#89aeff"
        />
        <path
          data-name="Path 532"
          d="m237.477 113.795 17.977-11.315-17.791 4.9Z"
          fill="#4a62d3"
        />
        <g data-name="Group 186">
          <path
            data-name="Path 533"
            d="m286.214 216.667 6.462-6.305a3.635 3.635 0 0 1 4.656-.353l27.7 19.857a.794.794 0 0 1 .182 1.106l-1.216 1.7a3.353 3.353 0 0 1-2.79 1.4l-11.122-.215Z"
            fill="#2f3d6c"
          />
          <path
            data-name="Path 534"
            d="M286.214 233.852h23.869l-23.869-17.185Z"
            fill="#fcbeb7"
          />
          <path
            data-name="Path 535"
            d="m207.49 203.456 50.783-35.528c.107.031.215.061.323.089a15.4 15.4 0 0 0 3.548.506l29.438.728a31.386 31.386 0 0 1-19.276 28.956l-29.9 12.5 43.8 5.958v17.185h-69.139c-16.339.002-22.963-21.026-9.577-30.394Z"
            fill="#6277d7"
          />
          <path
            data-name="Path 536"
            d="M204.956 228.737a8.3 8.3 0 0 0-4.241-15.332 17.244 17.244 0 0 0 4.241 15.332Z"
            fill="#4a5ea3"
          />
        </g>
        <path
          data-name="Path 537"
          d="m144.697 55.75 10.433-3.467a2.385 2.385 0 0 1 1.871.159l6.452 3.438 1.46.318a1.679 1.679 0 0 1 1.2 1.017l2.539 6.354 43.262 31.965 6.567-2.356 5.447 17.185-12.7 2.622a8.916 8.916 0 0 1-8.128-2.448l-40.613-40.892-7.456-4.384a2.335 2.335 0 0 1-1.152-1.927l-.03-.791-.483-.466a2.337 2.337 0 0 1-.706-1.888l.058-.657a2.335 2.335 0 0 1-.675-2.04l.1-.66-7.034.823a.981.981 0 0 1-.423-1.9Z"
          fill="#fcbeb7"
        />
        <path
          data-name="Path 538"
          d="m202.378 109.804.728.733a8.914 8.914 0 0 0 8.128 2.448l.595-.123a5.491 5.491 0 0 0-9.451-3.057Z"
          fill="#f9afab"
        />
      </g>
    </g>
  </svg>
);
