import clsx from "clsx";
import "./coming-soon-label.scss";

interface ComingSoonLabelProps {
  className?: string;
}

export const ComingSoonLabel: React.FC<ComingSoonLabelProps> = ({ className }) => {
  return <div className={clsx("coming-soon-label", className)}>Coming soon</div>;
};
