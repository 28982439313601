import React, { ReactNode } from "react";
import clsx from "clsx";
import "./scroll-wrapper.scss";

export type ScrollWrapperProps = {
  children: ReactNode;
  className?: string;
  variant: "light" | "dark";
};

export const ScrollWrapper: React.FC<ScrollWrapperProps> = ({
  className,
  children,
  variant = "light",
}) => {
  return (
    <div className={clsx("scroll-wrapper", `scroll-wrapper--${variant}`, className)}>
      {children}
    </div>
  );
};
