import { toast } from "react-toastify";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getContestParlay,
  getContestsByUser,
  getUserDetailsInContestById,
  makePicks,
  MakePicksFields,
} from "services/my-contests-service";
import {
  ContestParlayItem,
  ContestsItemType,
  MyContestItem,
  RequestState,
} from "types/request";
import { RootState } from "./main-store";

export interface ParlayStoreState {
  payload: ParlayPayload;
  requestMyContestsState: RequestState;
  makePicksRequestState: RequestState;
}

interface ParlayPayload {
  parlay: ContestParlayItem | null;
  usersDetails: MyContestItem[] | null;
  myContests: {
    inProgressContests: ContestsItemType[];
    previousContests: ContestsItemType[];
  };
  selectedMyContest: ContestsItemType | null;
}

export const initialState: ParlayStoreState = {
  payload: {
    parlay: null,
    usersDetails: null,
    myContests: { inProgressContests: [], previousContests: [] },
    selectedMyContest: null,
  },
  requestMyContestsState: RequestState.IDLE,
  makePicksRequestState: RequestState.IDLE,
};

export const fetchUsersDetailsAndParlay = createAsyncThunk(
  "parlay/fetchUsersDetailsAndParlay",
  async (id: number, ThunkApi) => {
    try {
      const usersDetails = await getUserDetailsInContestById(id);
      const parlay = await getContestParlay(id);

      return { usersDetails, parlay };
    } catch (error) {
      return ThunkApi.rejectWithValue(error);
    }
  }
);

export const fetchMyContests = createAsyncThunk(
  "app/fetchMyContests",
  async (_, thunkAPI) => {
    try {
      const { inProgressContests, previousContests } = await getContestsByUser();
      const selectedContest = inProgressContests.length ? inProgressContests[0] : null;
      const myContests = { inProgressContests, previousContests };
      return {
        myContests,
        selectedContest,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchMakePicksUsersDetailsAndParlay = createAsyncThunk(
  "app/makePicks",
  async ({ contestId, contestWagers }: MakePicksFields, thunkAPI) => {
    try {
      await makePicks({ contestId, contestWagers });
    } catch (error) {
      thunkAPI.rejectWithValue(error);
      toast.error("Couldn't place bet");
      return;
    }

    try {
      const [usersDetails, parlay] = await Promise.all([
        getUserDetailsInContestById(contestId),
        getContestParlay(contestId),
      ]);
      return { usersDetails, parlay };
    } catch (error) {
      toast.error("Couldn't place bet");
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const parlaySlice = createSlice({
  name: "parlay",
  initialState: initialState,
  reducers: {
    setParlay: (state, action: PayloadAction<ContestParlayItem | null>) => {
      state.payload.parlay = action.payload;
    },
    setMyContests: (
      state,
      action: PayloadAction<{
        inProgressContests: ContestsItemType[];
        previousContests: ContestsItemType[];
      }>
    ) => {
      const { inProgressContests, previousContests } = action.payload;
      state.payload.myContests.inProgressContests = inProgressContests;
      state.payload.myContests.previousContests = previousContests;
    },
    setSelectedMyContest: (state, action: PayloadAction<ContestsItemType | null>) => {
      state.payload.selectedMyContest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyContests.pending, (state) => {
      state.requestMyContestsState = RequestState.PENDING;
    });
    builder.addCase(fetchMyContests.fulfilled, (state, action) => {
      state.requestMyContestsState = RequestState.SUCCESS;
      state.payload = { ...state.payload, ...action.payload };
    });
    builder.addCase(fetchMyContests.rejected, (state) => {
      state.requestMyContestsState = RequestState.ERROR;
    });
    builder.addCase(fetchUsersDetailsAndParlay.pending, (state) => {
      state.requestMyContestsState = RequestState.PENDING;
    });
    builder.addCase(fetchUsersDetailsAndParlay.fulfilled, (state, action) => {
      state.requestMyContestsState = RequestState.SUCCESS;
      state.payload = { ...state.payload, ...action.payload };
    });
    builder.addCase(fetchUsersDetailsAndParlay.rejected, (state, action) => {
      if (action.error.name !== "AbortError") {
        state.requestMyContestsState = RequestState.ERROR;
      }
    });
    builder.addCase(fetchMakePicksUsersDetailsAndParlay.pending, (state) => {
      state.makePicksRequestState = RequestState.PENDING;
    });
    builder.addCase(fetchMakePicksUsersDetailsAndParlay.fulfilled, (state, action) => {
      state.makePicksRequestState = RequestState.SUCCESS;
      state.payload = { ...state.payload, ...action.payload };
    });
    builder.addCase(fetchMakePicksUsersDetailsAndParlay.rejected, (state) => {
      state.makePicksRequestState = RequestState.ERROR;
    });
  },
});

export const { setParlay, setMyContests, setSelectedMyContest } = parlaySlice.actions;
export const selectParlay = (state: RootState) => state.myContestReducer.payload.parlay;
export const selectUsersDetails = (state: RootState) =>
  state.myContestReducer.payload.usersDetails;
export const selectMyContestRequestState = (state: RootState) =>
  state.myContestReducer.requestMyContestsState;
export const selectMakePicksRequestState = (state: RootState) =>
  state.myContestReducer.makePicksRequestState;
export const selectMyContests = (state: RootState) =>
  state.myContestReducer.payload.myContests;
export const selectSelectedMyContest = (state: RootState) =>
  state.myContestReducer.payload.selectedMyContest;
export const myContestReducer = parlaySlice.reducer;
