import { SVGProps } from "react";

export const OnboardingWelcomeBoardSVG = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={172.226} height={100.457} {...restProps}>
    <g data-name="Group 139" transform="translate(-630.697 -2569.136)">
      <rect
        data-name="Rectangle 147"
        width={172.226}
        height={100.457}
        rx={7.963}
        transform="translate(630.697 2569.136)"
        fill="#89aeff"
      />
      <g data-name="Group 127">
        <circle
          data-name="Ellipse 43"
          cx={22.47}
          cy={22.47}
          r={22.47}
          transform="translate(654.211 2596.894)"
          fill="none"
        />
        <path
          data-name="Path 361"
          d="M673.5 2645.018a3.184 3.184 0 0 1 3.184-3.184 22.47 22.47 0 1 1 22.47-22.47 3.184 3.184 0 0 1 6.367 0 28.837 28.837 0 1 0-28.837 28.838 3.184 3.184 0 0 1-3.184-3.184Z"
          fill="#e1e9ff"
        />
        <path
          data-name="Path 362"
          d="M702.335 2616.181a3.183 3.183 0 0 0-3.184 3.183 22.5 22.5 0 0 1-22.47 22.47 3.184 3.184 0 1 0 0 6.368 28.87 28.87 0 0 0 28.837-28.838 3.183 3.183 0 0 0-3.183-3.183Z"
          fill="#6277d7"
        />
      </g>
      <rect
        data-name="Rectangle 148"
        width={60.744}
        height={3.414}
        rx={1.707}
        transform="translate(724.673 2596.96)"
        fill="#e1e9ff"
      />
      <rect
        data-name="Rectangle 149"
        width={33.702}
        height={3.414}
        rx={1.707}
        transform="translate(724.673 2606.27)"
        fill="#e1e9ff"
      />
    </g>
  </svg>
);
