import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { ContestBar } from "components/contest";
import { SearchInput } from "components/inputs";
import { selectContests } from "store/app-slice";
import { useAppSelector } from "hooks/store";
import { ContestsItemType } from "types/request";
import "./contests-mobile-search-view.scss";

export const CONTEST_PARAM = "contest";

interface LobbyMobileSearchViewProps {
  show: boolean;
  contests: ContestsItemType[];
}

export const ContestsMobileSearchView: React.FC<LobbyMobileSearchViewProps> = ({
  show,
}) => {
  const { upcomingContests } = useAppSelector(selectContests);
  const [contests, setContests] = useState<ContestsItemType[]>([]);
  const [searchParams] = useSearchParams();

  const contestPathConverter = (id: number, category: string) =>
    `/lobby/${category.toLowerCase()}/${id}/details`;

  useEffect(() => {
    const contestParamValue = searchParams.get(CONTEST_PARAM);
    if (contestParamValue) {
      const filteredContests = upcomingContests.filter(({ contest }) =>
        contest.name.toLowerCase().includes(contestParamValue.toLowerCase())
      );
      setContests(filteredContests);
    } else {
      setContests([]);
    }
  }, [upcomingContests, searchParams]);

  return (
    <div className={clsx("contests-search", show && "contests-search--show")}>
      <SearchInput placeholder="Search contests" paramName={CONTEST_PARAM} />
      {contests.map((contestItem) => {
        const { contest } = contestItem;
        return (
          <Link
            key={contest.id}
            to={contestPathConverter(contest.id, contest.contestType)}
            className="contests-search__link"
          >
            <ContestBar contestItem={contestItem} />
          </Link>
        );
      })}
    </div>
  );
};
