import React, { forwardRef, useState } from "react";
import clsx from "clsx";
import { getContestParlay } from "services/my-contests-service";
import { LeaderboardUsersCard } from "components/leaderboard/leaderboard-users";
import { LoaderSpinner } from "components/loader-spinner";
import { MyBets, MyBetsCurrentUser } from "components/my-bets";
import { ScrollWrapper } from "components/scroll-wrapper";
import { selectAuthPayload } from "store/auth";
import { selectMyContestRequestState, selectUsersDetails } from "store/my-contest-slice";
import { sumPayoutAndWager } from "helpers/sum-payout-and-wager";
import { useAppSelector } from "hooks/store";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { ContestParlayItem, RequestState } from "types/request";
import "./leaderboard-users.scss";

interface LeaderboardUsersProps {
  isModal?: boolean;
  open?: boolean;
}

export const LeaderboardUsers = forwardRef<HTMLDivElement, LeaderboardUsersProps>(
  ({ isModal, open }, ref) => {
    const { username } = useAppSelector(selectAuthPayload);
    const usersDetails = useAppSelector(selectUsersDetails);
    const [activeAccordion, setActiveAccordion] = useState<null | string>(null);
    const [parlays, setParlays] = useState<ContestParlayItem | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const selectedUsersDetails = usersDetails ?? [];
    const currentUser = selectedUsersDetails.find(
      (userDetails) => userDetails.userName === username
    );
    const isDesktop = useMediaQuery(MediaQueryBreakpoints.DESKTOP);
    const isMobile = useMediaQuery(MediaQueryBreakpoints.MOBILE);
    const requestState = useAppSelector(selectMyContestRequestState);

    const usersWithPlace = selectedUsersDetails.filter(
      (userDetails) =>
        userDetails.userName !== username && userDetails.currentPlaceByUnits !== 0
    );

    const usersWithoutPlace = selectedUsersDetails.filter(
      (userDetails) =>
        userDetails.userName !== username && userDetails.currentPlaceByUnits === 0
    );

    const sortedUsers = [
      ...usersWithPlace?.sort((a, b) => a.currentPlaceByBudget - b.currentPlaceByBudget),
      ...usersWithoutPlace,
    ];

    const isLoadingData = [RequestState.PENDING, RequestState.IDLE].includes(
      requestState
    );
    const totalWagerAndPayouts = sumPayoutAndWager(parlays?.wagers || []);

    const showUserBets = async (contest: number, user: number) => {
      try {
        const usersParlays = await getContestParlay(contest, user);
        setParlays(usersParlays);
        setIsLoading(false);
      } catch (error) {
        return error;
      }
    };

    return isLoadingData ? (
      isDesktop || isMobile ? (
        <LoaderSpinner size="small" />
      ) : (
        <></>
      )
    ) : (
      <div
        ref={ref}
        className={clsx("leaderboard-users", {
          "leaderboard-users--modal": isModal,
          "leaderboard-users--open": isModal && open,
          "leaderboard-users--close": isModal && !open,
        })}
      >
        <ScrollWrapper variant="light" className="leaderboard-users__wrapper">
          {currentUser && (
            <>
              <LeaderboardUsersCard
                activeAccordion={activeAccordion === currentUser?.userName}
                isLoggedUser
                onClick={() =>
                  activeAccordion === currentUser.userName
                    ? setActiveAccordion(null)
                    : setActiveAccordion(currentUser.userName)
                }
                {...currentUser}
              />
              {activeAccordion === currentUser.userName && <MyBetsCurrentUser />}
            </>
          )}
          <div>
            {sortedUsers?.map((userData) => {
              const { userName, contestId, userId } = userData;
              const isAccordionActive = activeAccordion === userName;

              return (
                <React.Fragment key={userName}>
                  <LeaderboardUsersCard
                    activeAccordion={isAccordionActive}
                    isDisabled={isLoading}
                    onClick={() => {
                      if (isAccordionActive) {
                        setActiveAccordion(null);
                      } else {
                        setIsLoading(true);
                        showUserBets(contestId, userId);
                        setActiveAccordion(userName);
                      }
                    }}
                    {...userData}
                  />
                  {isAccordionActive && (
                    <>
                      {isLoading ? (
                        <LoaderSpinner size="small" />
                      ) : (
                        <MyBets
                          totalPayout={totalWagerAndPayouts.totalPayout}
                          totalWager={totalWagerAndPayouts.totalWager}
                          itemToMap={parlays}
                        />
                      )}
                    </>
                  )}

                  <div
                    className={clsx("leaderboard-users__accordion", {
                      "leaderboard-users__accordion--active": isAccordionActive,
                    })}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </ScrollWrapper>
      </div>
    );
  }
);

LeaderboardUsers.displayName = "LeaderboardUsers";
