import { ReactNode, useMemo } from "react";
import clsx from "clsx";
import { Button } from "components/button";
import { selectParlay, selectSelectedMyContest } from "store/my-contest-slice";
import { formatValueToDisplay } from "helpers/format-value-to-display";
import { useAppSelector } from "hooks/store";
import { TeamType, WagerTypes } from "types/contest";
import "./slate-card.scss";

export interface SlateCardProps {
  content: ReactNode;
  topValue?: string | number;
  selectedGameId: number;
  selectedTeamType: TeamType;
  wagerType: WagerTypes;
  gameClosed: boolean;
}
export interface SlateCardPropsWithCallback extends SlateCardProps {
  onSlateClick: () => void;
}

export const SlateCard: React.FC<SlateCardPropsWithCallback> = ({
  content,
  topValue,
  onSlateClick,
  selectedGameId,
  selectedTeamType,
  wagerType,
  gameClosed,
}) => {
  const parlay = useAppSelector(selectParlay);
  const selectedContest = useAppSelector(selectSelectedMyContest);
  const isIncomplete = !content || (wagerType !== WagerTypes.MONEYLINE && !topValue);
  const isEnded = gameClosed;
  const isDisabled = isIncomplete || isEnded;

  const isWagerInParlay = useMemo(() => {
    if (parlay && selectedContest) {
      return (
        parlay.wagers.some(
          (wager) =>
            wager.chosenTeam === selectedTeamType &&
            parseInt(wager.gameId) === selectedGameId &&
            parseInt(wager.wagerType) === Object.values(WagerTypes).indexOf(wagerType)
        ) && parlay.parlay.name === selectedContest.contest.name
      );
    }
    return false;
  }, [parlay, selectedContest, selectedGameId, selectedTeamType, wagerType]);

  return (
    <Button
      onClick={onSlateClick}
      variant="clear"
      disabled={isDisabled}
      className={clsx(
        "slate-card",
        { "slate-card--betted": isWagerInParlay },
        { "slate-card--closed": isDisabled }
      )}
    >
      {isIncomplete ? (
        <>-</>
      ) : (
        <>
          {topValue && (
            <div className="slate-card__top-value">{formatValueToDisplay(topValue)}</div>
          )}
          <div className="slate-card__content">
            {formatValueToDisplay(content.toString())}
          </div>
        </>
      )}
    </Button>
  );
};
