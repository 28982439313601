import clsx from "clsx";
import { ContestBarStatusPart } from "components/contest";
import { LoaderSpinner } from "components/loader-spinner";
import { DateFormats } from "helpers/constants/formats";
import { dateToLocalTime } from "helpers/date-to-local-time";
import { getCssVariable } from "helpers/get-css-variable";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { ContestStatus } from "types/contest";
import { ContestsItemType } from "types/request";
import "./contest-bar.scss";

interface ContestBarProps {
  contestItem: ContestsItemType;
  wide?: boolean;
  active?: boolean;
  loading?: boolean;
}

const CHARS_FOR_SMALLER_FONT = 39;

export const ContestBar: React.FC<ContestBarProps> = ({
  contestItem,
  wide,
  active,
  loading,
}) => {
  const { startDT, status, name, maxContestants } = contestItem.contest;
  const { totalEntries, hasEntered, userPlaceByBudget } = contestItem.contestantStats;
  const entriesFull = totalEntries >= maxContestants;
  const isWideScreen = useMediaQuery(MediaQueryBreakpoints.WIDE);

  const isProgressBar =
    !entriesFull &&
    (status === ContestStatus.UPCOMING || status === ContestStatus.ACTIVE);
  const isOpenEnteredContest =
    hasEntered && (status === ContestStatus.ACTIVE || status === ContestStatus.UPCOMING);

  const smallFontTitle = name.length > CHARS_FOR_SMALLER_FONT;

  let progressBarColor = getCssVariable("--progress-bar");

  let contestBackgroundColor = getCssVariable("--light-text-color");
  let progressBackgroundColor = isProgressBar
    ? getCssVariable("--progress-background-gray")
    : contestBackgroundColor;
  let progressBackgroundColorFull = getCssVariable("--contest-background-full");

  if (active && isWideScreen) {
    contestBackgroundColor = "transparent";
    progressBackgroundColor = getCssVariable("--yellow-25");
    progressBarColor = progressBackgroundColor;
    progressBackgroundColorFull = "transparent";
  }
  const entriesCompareToMax = `${Math.floor((totalEntries / maxContestants) * 100)}%`;
  return (
    <div
      className={clsx(
        "contest-bar",
        entriesFull && "contest-bar--full",
        isWideScreen && active && "contest-bar--active"
      )}
    >
      <div
        className="contest-bar__wrapper"
        style={
          entriesFull
            ? { background: progressBackgroundColorFull }
            : {
                background: `linear-gradient(to right, ${progressBackgroundColor} 0%, ${progressBackgroundColor} ${entriesCompareToMax}, ${contestBackgroundColor} ${entriesCompareToMax}, ${contestBackgroundColor} 100%`,
              }
        }
      >
        <div className="contest-bar__content">
          <div
            className={clsx(
              "contest-bar__stripe",
              isOpenEnteredContest && `contest-bar__stripe--active`,
              status === ContestStatus.COMPLETED &&
                hasEntered &&
                "contest-bar__stripe--completed"
            )}
          />
          <div
            className={clsx(
              "contest-bar__left-side",
              wide && "contest-bar__left-side--wide"
            )}
          >
            <div>
              <div className="contest-bar__date-and-label">
                <div
                  className={clsx("contest-bar__date", wide && "contest-bar__date--wide")}
                >
                  {dateToLocalTime(startDT)
                    .format(DateFormats.DATE_AND_TIME)
                    .toUpperCase()}
                </div>
              </div>
              <div
                className={clsx(
                  "contest-bar__title",
                  wide && "contest-bar__title--wide",
                  {
                    "contest-bar__title--small-font": smallFontTitle,
                  }
                )}
              >
                {name}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              "contest-bar__right-side",
              wide && "contest-bar__right-side--wide",
              loading && "contest-bar__right-side--loader"
            )}
          >
            {loading ? (
              <LoaderSpinner size="tiny" />
            ) : (
              <ContestBarStatusPart
                status={status}
                userPlace={userPlaceByBudget}
                wide={wide}
                entriesFull={entriesFull}
                hasEntered={hasEntered}
              />
            )}
          </div>
          {isProgressBar && (
            <div className="contest-bar__progress-bar-wrapper">
              <div
                className="contest-bar__progress-bar"
                style={
                  isOpenEnteredContest
                    ? {
                        width: entriesCompareToMax,
                        background: `linear-gradient(to right, transparent 0%, transparent 7px, ${progressBarColor} 7px, ${progressBarColor} 100%`,
                      }
                    : {
                        width: entriesCompareToMax,
                        background: `${progressBarColor}`,
                      }
                }
              />
              <span
                className="contest-bar__progress-bar-span"
                style={{
                  margin: `0 0 0 ${entriesCompareToMax}`,
                }}
              >
                {entriesCompareToMax}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
