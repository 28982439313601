import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { fetchSession, fetchSignIn, updateAPIUser, updateProfileImage } from "store/auth";
import { RootState } from "store/main-store";
import { AuthPayload } from "types/auth";
import { EditProfileTextInputs } from "types/profile-details";
import { RequestState } from "types/request";
import { PreferredContact } from "types/user";

export interface AuthStoreState {
  payload: AuthPayload;
  requestState: RequestState;
  profileImageUrlRequestState: RequestState;
  fetchSignIn: RequestState;
}

export const initialPayload: AuthPayload = {
  userId: null,
  username: null,
  tokenId: null,
  email: null,
  firstName: null,
  lastName: null,
  livesInUs: true,
  isVerified: false,
  isIntroduced: false,
  availablePoints: 0,
  preferredContact: PreferredContact.EMAIL,
  profileImageUrl: null,
  roles: [],
};

const initialState: AuthStoreState = {
  payload: initialPayload,
  requestState: RequestState.IDLE,
  profileImageUrlRequestState: RequestState.IDLE,
  fetchSignIn: RequestState.IDLE,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUpdatedUserDetails: (state, action: PayloadAction<EditProfileTextInputs>) => {
      const { username, firstName, lastName, phoneNumber, email, preferredContact } =
        action.payload;
      state.payload.username = username;
      state.payload.firstName = firstName;
      state.payload.lastName = lastName;
      state.payload.phoneNumber = phoneNumber;
      state.payload.email = email;
      state.payload.preferredContact = preferredContact;
    },
    setIsVerified: (state, action: PayloadAction<boolean>) => {
      state.payload.isVerified = action.payload;
    },
    setIsIntroduced: (state, action: PayloadAction<boolean>) => {
      state.payload.isIntroduced = action.payload;
    },
    setRequestState: (state, action: PayloadAction<RequestState>) => {
      state.requestState = action.payload;
    },
    setAvailablePoints: (state, action: PayloadAction<number>) => {
      state.payload.availablePoints = action.payload;
    },
    resetState: (state) => {
      state.payload = initialPayload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSession.fulfilled, (state, action) => {
      state.requestState = RequestState.SUCCESS;
      state.profileImageUrlRequestState = RequestState.SUCCESS;
      state.payload = { ...state.payload, ...action.payload };
    });
    builder.addCase(fetchSession.rejected, (state) => {
      state.requestState = RequestState.ERROR;
      state.payload = initialPayload;
    });
    builder.addCase(updateAPIUser.fulfilled, (state, action) => {
      state.requestState = RequestState.SUCCESS;
      state.payload = { ...state.payload, ...action.payload };
    });
    builder.addCase(updateAPIUser.rejected, (state) => {
      state.requestState = RequestState.ERROR;
    });
    builder.addCase(updateProfileImage.pending, (state) => {
      state.profileImageUrlRequestState = RequestState.PENDING;
    });
    builder.addCase(updateProfileImage.fulfilled, (state, action) => {
      state.profileImageUrlRequestState = RequestState.SUCCESS;
      state.payload.profileImageUrl = action.payload;
    });
    builder.addCase(updateProfileImage.rejected, (state) => {
      state.profileImageUrlRequestState = RequestState.ERROR;
    });
    builder.addCase(fetchSignIn.pending, (state) => {
      state.fetchSignIn = RequestState.PENDING;
    });
    builder.addCase(fetchSignIn.fulfilled, (state) => {
      state.fetchSignIn = RequestState.SUCCESS;
    });
    builder.addCase(fetchSignIn.rejected, (state) => {
      state.fetchSignIn = RequestState.ERROR;
    });
    builder.addMatcher(isAnyOf(fetchSession.pending, updateAPIUser.pending), (state) => {
      state.requestState = RequestState.PENDING;
    });
  },
});

export const {
  setUpdatedUserDetails,
  setIsVerified,
  setIsIntroduced,
  setRequestState,
  resetState,
} = authSlice.actions;
export const selectAuthPayload = (state: RootState) => state.authReducer.payload;
export const selectRequestState = (state: RootState) => state.authReducer.requestState;
export const selectAvailablePoints = (state: RootState) =>
  state.authReducer.payload.availablePoints;
export const selectProfileImageUrl = (state: RootState) =>
  state.authReducer.payload.profileImageUrl;
export const selectProfileImageRequestState = (state: RootState) =>
  state.authReducer.profileImageUrlRequestState;
export const selectFetchSignIn = (state: RootState) => state.authReducer.fetchSignIn;
export const authReducer = authSlice.reducer;
