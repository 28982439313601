import { SVGProps } from "react";

export const OnboardingBudgetDollarSVG = ({ ...restProps }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={66} height={127} {...restProps}>
    <text
      data-name="$"
      fill="#d2dcff"
      fontSize={90}
      fontFamily="Poppins-Bold, Poppins"
      fontWeight={700}
    >
      <tspan x={0} y={95}>
        {"$"}
      </tspan>
    </text>
  </svg>
);
