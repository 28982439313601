import { ReactNode } from "react";
import "./menu-wrapper.scss";

interface MenuWrapperProps {
  children: ReactNode;
}

export const MenuWrapper: React.FC<MenuWrapperProps> = ({ children }) => {
  return <div className="menu-wrapper">{children}</div>;
};
