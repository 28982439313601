import { ContentWrapper } from "components/content-wrapper";
import { ImageLayout } from "components/image-layout";
import { SignUpContent } from "./sign-up-content";
import "./sign-up.scss";

export const SignUpPage = () => {
  return (
    <div className="sign-up-page">
      <ImageLayout />
      <ContentWrapper>
        <SignUpContent />
      </ContentWrapper>
    </div>
  );
};
