import { useEffect } from "react";
import { RouteProps, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "components/app-routes";
import { MenuLayout } from "components/menu";
import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { Paths } from "types/router";
import "./protected-layout.scss";

export const EXCEPTION_PATH = "lobby";

export const ProtectedLayout: React.FC<RouteProps> = () => {
  const { userId, isVerified, isIntroduced } = useAppSelector(selectAuthPayload);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!userId) {
      navigate(Paths.SIGN_IN);
    } else {
      if (!isVerified) {
        navigate(Paths.CODE_VERIFICATION);
      } else if (!isIntroduced) {
        navigate(Paths.ONBOARDING);
      }
    }
  }, [userId, isVerified, isIntroduced, navigate, pathname]);

  return (
    <div className="protected-layout">
      <MenuLayout>
        <AppRoutes />
      </MenuLayout>
    </div>
  );
};
