import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { selectAuthPayload } from "store/auth";
import { Paths } from "types/router";
import { useAppSelector } from "./store";

export const useLobbyOrOnboardingRedirect = () => {
  const { username, isIntroduced } = useAppSelector(selectAuthPayload);
  const navigate = useNavigate();

  useEffect(() => {
    if (username && isIntroduced) {
      navigate(Paths.LOBBY, { replace: true });
    } else if (username && !isIntroduced) {
      navigate(Paths.ONBOARDING, { replace: true });
    }
  }, [username, isIntroduced, navigate]);
};
