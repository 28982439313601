import { BottomNavigation } from "components/bottom-navigation";
import { CommonForm } from "components/common-form";
import { Paths } from "types/router";
import { OnboardingFinalImageSVG } from "assets/onboarding-final";
import "./onboarding-final.scss";

interface OnboardingFinalProps {
  onNextClick: () => void;
}

export const OnboardingFinal: React.FC<OnboardingFinalProps> = ({ onNextClick }) => {
  return (
    <div className="final">
      <CommonForm
        title="Step 3: Ready, Bet, Go!"
        subtitle="Go aggressive and bet it all on your favorite team, or spread your Budget out over the entire betting slate… it’s entirely up to your own individual strategy! If you win a bet, use those winnings for more action! You can view your opponents’ active and settled bets from the leaderboard, so make sure to use that info to your advantage!"
        footer={
          <BottomNavigation
            cancelText="SKIP"
            cancelPath={Paths.LOBBY}
            onSubmit={onNextClick}
          />
        }
      >
        <div className="final__content">
          <OnboardingFinalImageSVG />
        </div>
      </CommonForm>
    </div>
  );
};
