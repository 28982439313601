import clsx from "clsx";
import "./label.scss";

interface LabelProps {
  variant?: "orange" | "green" | "red";
  rounded?: boolean;
  className?: string;
  disabled?: boolean;
}
export const Label: React.FC<LabelProps> = ({
  variant = "orange",
  children,
  className,
  rounded,
  disabled = false,
}) => {
  return (
    <div
      data-testid="label"
      className={clsx(
        "label",
        `label--${variant}`,
        rounded && "label__rounded",
        disabled && "label--disabled",
        className
      )}
    >
      {children}
    </div>
  );
};
