import { ContestFooter } from "components/contest";
import { ScrollWrapper } from "components/scroll-wrapper";
import "./contest-rules.scss";

interface ContestRulesProps {
  rules: {
    label: string;
    text: string[];
  }[];
  entry: number;
}

export const ContestRules: React.FC<ContestRulesProps> = ({ rules }) => {
  return (
    <div className="contest-rules">
      <ScrollWrapper variant="dark" className="contest-rules__content">
        {rules.map(({ label, text }) => (
          <div key={label}>
            <span className="contest-rules__label">{label}</span>
            <ul className="contest-rules__list">
              {text.map((rule) => (
                <li key={rule}>{rule}</li>
              ))}
            </ul>
          </div>
        ))}
      </ScrollWrapper>
      <ContestFooter free />
    </div>
  );
};
