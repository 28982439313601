import { ContestsItemType } from "types/request";
import { SortField, SortOrder } from "types/sort-contests";

export const sortContests = (
  contests: ContestsItemType[],
  sortOrder: SortOrder,
  sortField: SortField,
  contestParamValue: string | null
) => {
  const sortedContests = [...contests].sort((a, b) =>
    sortOrder === SortOrder.ASCENDING
      ? b.contest[sortField] - a.contest[sortField]
      : a.contest[sortField] - b.contest[sortField]
  );

  if (contestParamValue) {
    return sortedContests.filter((contestObject) =>
      contestObject.contest.name.toLowerCase().includes(contestParamValue.toLowerCase())
    );
  }
  return sortedContests;
};
