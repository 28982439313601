import { useCallback, useLayoutEffect, useState } from "react";
import { getCssVariable } from "helpers/get-css-variable";

export enum MediaQueryBreakpoints {
  MOBILE = "--breakpoint-sm",
  WIDE = "--breakpoint-md",
  DESKTOP = "--breakpoint-lg",
}

export const useMediaQuery = (query: MediaQueryBreakpoints) => {
  const [matches, setMatches] = useState(false);

  const getComputedQuery = useCallback(() => {
    const tabletBreakpoint = getCssVariable(MediaQueryBreakpoints.WIDE);
    switch (query) {
      case MediaQueryBreakpoints.MOBILE:
        return `(max-width:calc(${tabletBreakpoint} - 1px))`;
      case MediaQueryBreakpoints.WIDE:
        return `(min-width:${tabletBreakpoint})`;
      case MediaQueryBreakpoints.DESKTOP:
        return `(min-width: ${getCssVariable(MediaQueryBreakpoints.DESKTOP)})`;
    }
  }, [query]);

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(getComputedQuery());
    const handler = () => setMatches(mediaQuery.matches);
    mediaQuery.addEventListener("change", handler);
    handler();
    return () => mediaQuery.removeEventListener("change", handler);
  }, [getComputedQuery]);

  return matches;
};
