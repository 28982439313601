import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Alert } from "components/alert";
import { Button } from "components/button";
import { AnimatedLabeledInput } from "components/inputs/animated-labeled-input";
import { fetchContests } from "store/app-slice";
import { fetchSession, fetchSignIn, selectFetchSignIn } from "store/auth";
import { setSelectedMyContest } from "store/my-contest-slice";
import { resetState, setEmailAndPassword } from "store/register-slice";
import { ERROR_EMAIL_EMPTY, ERROR_PASSWORD_EMPTY } from "helpers/constants";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useLobbyOrOnboardingRedirect } from "hooks/use-lobby-or-onboarding-redirect";
import { RequestState } from "types/request";
import { Paths } from "types/router";
import { LockIcon, PersonIcon } from "assets/icons";
import "./sign-in-content.scss";

interface LoginInput {
  email: string;
  password: string;
}

export const SignInContent: React.FC = () => {
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useLobbyOrOnboardingRedirect();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginInput>();

  const onSignIn = async () => {
    dispatch(resetState());
    await dispatch(fetchSession()).unwrap();
    dispatch(fetchContests());
    dispatch(setSelectedMyContest(null));
    navigate(Paths.CODE_VERIFICATION);
  };

  const onNotConfirmedUser = () => {
    dispatch(
      setEmailAndPassword({ email: getValues("email"), password: getValues("password") })
    );
    navigate(Paths.CODE_VERIFICATION);
  };

  const requestFetchSignIn = useAppSelector(selectFetchSignIn);

  const onSubmit = ({ email, password }: LoginInput) => {
    dispatch(
      fetchSignIn({
        email: email,
        password: password,
        onSignIn: onSignIn,
        onNotConfirmedUser: onNotConfirmedUser,
        setError: setError,
      })
    );
  };

  return (
    <div className="sign-in-content">
      <div className="sign-in-content__logo-wrapper">
        <img
          srcSet="images/logo-base.png, images/logo-base@2x.png 2x,"
          src="images/logo-base.png"
          alt="logo"
          className="sign-in-content__logo"
        />
      </div>
      <div className="sign-in-content__alert">
        {error && <Alert message={error} setError={setError} />}
      </div>
      <form className="sign-in-content__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="sign-in-content__title">Sign In</div>
        <AnimatedLabeledInput
          labelText="Username"
          {...register("email", {
            required: ERROR_EMAIL_EMPTY,
          })}
          placeholder="Username"
          icon={PersonIcon}
          error={!!errors.email}
          errorText={errors.email?.message}
          rounded
          autoComplete="username"
        />
        <AnimatedLabeledInput
          labelText="Password"
          {...register("password", {
            required: ERROR_PASSWORD_EMPTY,
          })}
          placeholder="Password"
          type="password"
          icon={LockIcon}
          error={!!errors.password}
          errorText={errors.password?.message}
          rounded
          autoComplete="current-password"
        />
        <Button
          loader={requestFetchSignIn === RequestState.PENDING}
          disabled={requestFetchSignIn === RequestState.PENDING}
          type="submit"
          variant="secondary"
          className={clsx("sign-in-content__button", {
            "sign-in-content__button__disabled":
              requestFetchSignIn === RequestState.PENDING,
          })}
        >
          LOGIN
        </Button>
        <Link
          to={Paths.FORGOT_PASSWORD}
          className="sign-in-content__link-forgot-password "
        >
          Forgot password?
        </Link>
        <Link to={Paths.SIGN_UP} className="sign-in-content__sign-up">
          Don't have an account? Sign up
        </Link>
      </form>
      <div className="sign-in-content__other-options">
        <span className="sign-in-content__other-options-text">Or sign in with</span>
        <button
          className={clsx(
            "sign-in-content__button-icon",
            "sign-in-content__button-icon--google"
          )}
        />
        <button
          className={clsx(
            "sign-in-content__button-icon",
            "sign-in-content__button-icon--facebook"
          )}
        />
      </div>
      <div className="sign-in-content__footer">
        If you have a gambling problem, help is available. Call (877-8-HOPENY) or text
        HOPENY (467369).
      </div>
    </div>
  );
};
