import { useCallback, useEffect, useState } from "react";
import { getGames } from "services/my-contests-service";
import { addDayToDateBoundaries } from "helpers/add-day-to-date-boundaries";
import { getDaysBetweenDates } from "helpers/getDaysBetweenDates";
import { validateGame } from "helpers/validate-game";
import { GetGamesResponse } from "types/contest";
import { Option } from "types/select";

const getOptions = (
  gamesList: GetGamesResponse[],
  startDate: string,
  endDate: string
) => {
  const validatedGames = gamesList.filter((game) =>
    validateGame(game, startDate, endDate)
  );
  if (!validatedGames.length) {
    return [];
  }
  const receivedCategories = [...new Set(validatedGames.map((game) => game.Sport))];
  if (receivedCategories.length === 1) {
    return receivedCategories.map((category) => ({
      label: category,
      value: category,
    }));
  }
  const options = [
    ...receivedCategories.map((category) => ({ label: category, value: category })),
    {
      label: "MULTISPORT",
      value: "All",
    },
  ];

  return options;
};

export const useGetCategories = (dateOfStart: string, dateOfEnd: string) => {
  const [categories, setCategories] = useState<Option<string>[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchGames = useCallback(
    async (signal: AbortSignal) => {
      setIsLoading(true);
      const { usersStartDate, usersEndDate } = addDayToDateBoundaries(
        dateOfStart,
        dateOfEnd
      );

      const receivedGames = await getGames(
        {
          gameDate: usersStartDate,
          sport: "All",
          days: getDaysBetweenDates(usersStartDate, usersEndDate),
        },
        signal
      );
      setCategories(getOptions(receivedGames ?? [], dateOfStart, dateOfEnd));
      setIsLoading(false);
    },
    [dateOfEnd, dateOfStart]
  );

  useEffect(() => {
    const abortController = new AbortController();
    fetchGames(abortController.signal);

    return () => {
      abortController.abort();
      setCategories([]);
    };
  }, [fetchGames]);

  return { isLoading, categories };
};
