import dayjs from "dayjs";
import { DateFormats } from "./constants";

export const addDayToDateBoundaries = (startDate: string, endDate: string) => {
  const usersStartDate = dayjs(startDate)
    .tz("UTC")
    .subtract(1, "d")
    .format(DateFormats.DATE);
  const usersEndDate = dayjs(endDate).tz("UTC").add(1, "d").format(DateFormats.DATE);

  return { usersStartDate, usersEndDate };
};
