export const TERMS_AND_CONDITIONS: { title: string; content: string }[] = [
  {
    title: "Welcome!",
    content: `These Terms and Conditions ("Terms") govern your use of the Bet Max Action
website and mobile application (collectively, the "Services") provided by TSG
Interactive US Services Limited, d/b/a Bet Max Action ("Bet Max Action," "we,"
"us" or "our"). By using the Services, you agree to be bound by the terms and
conditions of these Terms. If you do not agree to the terms and conditions of
these Terms, please do not use the Services. These Terms constitute a legal
agreement between you and Bet Max Action, and shall apply to your use of the
Services even after termination. PLEASE NOTE THE ARBITRATION PROVISION SET FORTH
BELOW, WHICH REQUIRES, EXCEPT WHERE AND TO THE EXTENT PROHIBITED BY LAW, YOU TO
ARBITRATE ANY CLAIMS YOU MAY HAVE AGAINST Bet Max Action ON AN INDIVIDUAL BASIS.
ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU WAIVE,
THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT
PROCEED IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY.`,
  },
  {
    title: "ELIGIBILITY",
    content: `You must be at least 18 years of age or older to use the services. In
    jurisdictions, territories, and locations where the minimum age for permissible
    use of the website is greater than 18 years old, you must meet the age
    requirement in your local jurisdiction or territory. THE SERVICES ARE AVAILABLE
    TO LEGAL US RESIDENTS ABOVE THE AGE OF MAJORITY IN THEIR STATE OF RESIDENCE,
    EXCLUDING LEGAL RESIDENTS OF THE STATE OF WASHINGTON. You must be a citizen or
    legal resident of the US and have an address in the US. If you are a legal
    resident of the State of Washington, you may not use the Services. From time to
    time, we run sweepstakes, or games of chance. Your entrance into such
    sweepstakes will be governed by separate rules for such sweepstakes. In general,
    however, by entering a sweepstakes, you represent and warrant that: (i) at the
    time of game entry you are physically located in the US, with the exception of
    the State of Washington or any other jurisdiction in which participation in the
    sweepstakes is not prohibited by applicable law and/or by these Terms; and (ii)
    you are not listed on any US Government list of prohibited or restricted
    parties. Employees of Bet Max Action or its affiliates, their respective
    relatives, agents or agents' relatives are not eligible to win prizes. The
    Services and sweepstakes may not be used for any form of illicit gambling. If
    Bet Max Action determines that you do not meet the eligibility requirements
    above, then you are not authorized to use the Services.`,
  },
  {
    title: "ACCOUNT CREATION",
    content: `To access certain portions of the Services, you must create an account. You
    agree to provide truthful and accurate information during the account creation
    process. You also agree to maintain the accuracy of any submitted data. If you
    provided any information that is untrue, inaccurate, or incomplete, we reserve
    the right to terminate your account and suspend your use of any and all
    Services. You are responsible for preserving the confidentiality of your account
    password and will notify us of any known or suspected unauthorized use of your
    account. You agree that you are responsible for all acts or omissions that occur
    on your account while your password is being used.`,
  },
  {
    title: "USER CONDUCT",
    content: `By using the Services, you agree not to use the Services in any manner that: Is
    designed to interrupt, destroy or limit the functionality of, any computer
    software or hardware or telecommunications equipment (including by means of
    software viruses or any other computer code, files or programs); Interferes with
    or disrupts the Services, services connected to the Services, or otherwise
    interferes with operations or services of the Services in any way; Infringes any
    copyright, trademark, trade secret, patent or other right of any party, or
    defames or invades the publicity rights or the privacy of any person, living or
    deceased (or impersonates any such person); Utilizes bots, artificial
    intelligence, or other automated means to manipulate or submit an entry or any
    aspect of the Services; Consists of any unsolicited or unauthorized advertising,
    promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes,"
    or any other form of solicitation; Causes us to lose (in whole or in part) the
    services of our Internet service providers or other suppliers; Links to
    materials or other content, directly or indirectly, to which you do not have a
    right to link; Is false, misleading, harmful, threatening, abusive, harassing,
    tortious, defamatory, vulgar, obscene, libelous, invasive or another's privacy,
    hateful, or racially, ethnically or otherwise objectionable, as determined by us
    in our sole discretion; Copies, modifies, creates a derivative work of, reverse
    engineers, decompiles or otherwise attempts to extract the source code of the
    software underlying the Services or any portion thereof; Violates, or encourages
    anyone to violate these Terms, any ancillary terms and conditions listed on the
    Services, or the Privacy Policy; or Violates, or encourages to violate, any
    applicable local, state, national, or international law, regulation, or order.`,
  },
  {
    title: "NO FRAMING",
    content: `Without the prior written permission of Bet Max Action, you may not frame, or
    make it appear that a third-party site is presenting or endorsing, any of the
    content of the Services, or incorporate any intellectual property of the
    Services, Bet Max Action or any of its licensors into another website or other
    service.`,
  },
  {
    title: "INTELLECTUAL PROPERTY OWNERSHIP",
    content: `The content on the Services, including without limitation, the text, software,
    scripts, graphics, photos, sounds, music, videos, interactive features and the
    like and the trademarks, service marks and logos contained therein (the
    "Intellectual Property"), are owned by or licensed to Bet Max Action, subject to
    copyright and other intellectual property rights under US and foreign laws and
    international conventions. You may not use or exploit the Intellectual Property
    in any way without our prior written consent. You may download information from
    the Services and print out a hard copy for your personal, noncommercial use
    provided that you keep intact and do not remove or alter any copyright or other
    notice (e.g., trademark, patent, etc.) contained in the information. Except as
    otherwise expressly stated herein, you may not modify, copy, distribute,
    display, reproduce, sell, license, or create derivative works from any
    information or content, in whole or in part, including any text, images, audio,
    and video in any manner, without the prior written authorization of Bet Max
    Action or any applicable third party suppliers. Any unauthorized use of text or
    images may violate copyright laws, trademark laws, the laws of privacy and
    publicity, and applicable regulations and statutes. You agree to comply with
    reasonable written requests from us to help us protect our proprietary and
    intellectual property rights.`,
  },
  {
    title: "YOUR LICENSE GRANT",
    content: `We are providing you with access to the Services pursuant to a limited,
    non-exclusive, non-sub-licensable, non-transferable, revocable license. You can
    use the Services for personal, noncommercial use, and subject to these Terms.
    This license is available to you as long as you are not barred from the Services
    by applicable law and your account is not terminated by us or by you. If these
    Terms are not enforceable where you are located, you may not use the Services.
    We reserve all right, title, and interest not expressly granted under this
    license to the fullest extent possible under applicable laws.`,
  },
  {
    title: "THIRD-PARTY LINKS",
    content: `The Services may contain links to other websites. We are not responsible for the
    availability of these external websites nor do we endorse the activities or
    services provided by these websites. Under no circumstances shall we be held
    responsible or liable, directly or indirectly, for any loss or damage caused or
    alleged to have been caused to a user in connection with the use of or reliance
    on any content, goods or services available on such external websites.`,
  },
  {
    title: "PRIVACY",
    content: `Your privacy is important to us, and we created a Privacy Policy to describe our
    collection of personal information through the Services, how we use that
    information and with whom we share it. By using our Services, you acknowledge
    that you have read and understood the terms and conditions of our Privacy
    Policy, as amended from time to time. If you do not agree to the terms and
    conditions of our Privacy Policy, you may not use the Services.`,
  },
  {
    title: "DISCLAIMER OF WARRANTIES",
    content: `TO THE FULLEST EXTENT PERMITTED UNDER LAW AND SAVE FOR THE RIGHTS GRANTED TO
    CONSUMERS UNDER APPLICABLE LAWS, THE SERVICES, INCLUDING ALL CONTENT, SOFTWARE
    AND FUNCTIONS, ARE PROVIDED “AS IS,” “AS AVAILABLE” AND “WITH ALL FAULTS.” Bet
    Max Action MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND WHATSOEVER TO YOU OR
    ANY OTHER PERSON RELATING IN ANY WAY TO THE SERVICES OR THE CONTENT OR
    COMMUNICATIONS ON THE SERVICES, OR ANY WEBSITE OR OTHER CONTENT OR SERVICE THAT
    MAY BE ACCESSIBLE DIRECTLY OR INDIRECTLY THROUGH THE SERVICES. Bet Max Action
    DISCLAIMS IMPLIED WARRANTIES THAT THE SERVICES AND ALL SOFTWARE, CONTENT AND
    FUNCTIONS AND INFORMATION DISTRIBUTED THROUGH THE SERVICES ARE MERCHANTABLE, OF
    SATISFACTORY QUALITY, ACCURATE, TIMELY, FIT FOR A PARTICULAR PURPOSE OR NEED, OR
    NON-INFRINGING. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY Bet Max Action
    OR A REPRESENTATIVE SHALL CREATE A WARRANTY. Bet Max Action DOES NOT GUARANTEE
    THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT IT IS ERROR-FREE,
    RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, WITHOUT INTERRUPTION OR
    AVAILABLE AT ALL TIMES. WE DO NOT GUARANTEE THAT THE SERVICES, INCLUDING ANY
    SUPPORT SERVICES, WILL BE EFFECTIVE, RELIABLE, AND ACCURATE OR MEET YOUR
    REQUIREMENTS. Bet Max Action DOES NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS
    OR USE THE SERVICES (EITHER DIRECTLY OR THROUGH THIRD-PARTY NETWORKS) AT ALL
    TIMES OR LOCATIONS OF YOUR CHOOSING. FURTHER, WE DO NOT ENDORSE AND MAKE NO
    WARRANTY REGARDING THE ACCURACY OR RELIABILITY OF ANY OPINION, INFORMATION,
    ADVICE OR STATEMENT ON THE SERVICES. UNDER NO CIRCUMSTANCES WILL WE BE LIABLE
    FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED THROUGH
    THE CONTENT ON THE SERVICES. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY,
    COMPLETENESS OR USEFULNESS OF ANY INFORMATION, OPINION, ADVICE OR OTHER CONTENT
    AVAILABLE THROUGH THE SERVICES.`,
  },
  {
    title: "LIMITATION OF LIABILITY",
    content: `TO THE FULLEST EXTENT PERMITTED UNDER LAW AND SAVE FOR THE RIGHTS GRANTED TO
    CONSUMERS UNDER APPLICABLE LAWS, Bet Max Action WILL HAVE NO OBLIGATION OR
    LIABILITY (WHETHER ARISING IN CONTRACT, WARRANTY, TORT, INCLUDING NEGLIGENCE,
    PRODUCT LIABILITY, OR OTHERWISE) FOR ANY DAMAGES OR LIABILITIES, INCLUDING
    DIRECT, INCIDENTAL, INDIRECT, SPECIAL, OR CONSEQUENTIAL (INCLUDING ANY LOSS OF
    DATA, REVENUE OR PROFIT OR DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH)
    ARISING WITH RESPECT TO YOUR USE OF THE SERVICES, EVEN IF Bet Max Action HAS
    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION APPLIES TO
    DAMAGES ARISING FROM (I) USE OF OR INABILITY TO USE THE SERVICES, (II) COST OF
    PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES, (III) UNAUTHORIZED ACCESS TO OR
    ALTERATION OF YOUR TRANSMISSIONS BY THIRD PARTIES, (IV) THIRD PARTY CONTENT MADE
    AVAILABLE TO YOU THROUGH THE SERVICES, OR (V) ANY OTHER MATTER RELATING TO THE
    SERVICES. IN ADDITION, WHEN USING THE SERVICES, INFORMATION WILL BE TRANSMITTED
    OVER A MEDIUM WHICH IS BEYOND THE CONTROL AND JURISDICTION OF Bet Max Action,
    ITS PARTNERS, ADVERTISERS, AND SPONSORS OR ANY OTHER THIRD PARTY MENTIONED ON
    THE SERVICES. ACCORDINGLY, Bet Max Action ASSUMES NO LIABILITY FOR OR RELATING
    TO THE DELAY, FAILURE, INTERRUPTION, OR CORRUPTION OF ANY DATA OR OTHER
    INFORMATION TRANSMITTED IN CONNECTION WITH USE OF THE SERVICES. SOME
    JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
    CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO
    YOU.`,
  },
  {
    title: "DISPUTE RESOLUTION AND AGREEMENT TO ARBITRATE",
    content: `Except where and to the extent prohibited by law, by using the Services, you and
    Bet Max Action agree that, if there is any controversy, claim, action, or
    dispute arising out of or related to your use of the Services or the breach,
    enforcement, interpretation, or validity of these Terms or any part of it
    (“Dispute”), it shall be resolved in accordance with this Section 11. In the
    event of a Dispute, both parties shall first try in good faith to settle such
    Dispute by providing written notice to the other party describing the facts and
    circumstances of the Dispute and allowing the receiving party thirty (30) days
    in which to respond to or settle the Dispute. Notice of a Dispute shall be sent
    to: Us, at TSG Interactive US Services Limited d/b/a Bet Max Action, (Address of
    agent for service: 1209 Orange Street, Wilmington, DE 19801), or You, at the
    address we have on file for you. Both you and Bet Max Action agree that this
    dispute resolution procedure is a condition precedent that must be satisfied
    before initiating any litigation or filing any claim against the other party. IF
    ANY DISPUTE CANNOT BE RESOLVED BY THE ABOVE DISPUTE RESOLUTION PROCEDURE, YOU
    AGREE THAT THE SOLE AND EXCLUSIVE JURISDICTION FOR SUCH DISPUTE WILL BE DECIDED
    BY FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS AS DESCRIBED BELOW.
    ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU WAIVE,
    THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT
    PROCEED IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY. Other rights that
    you and we would otherwise have in court will not be available or will be more
    limited in arbitration, including discovery and appeal rights. All such Disputes
    shall be exclusively submitted for binding arbitration under the rules of the
    American Arbitration Association (“AAA”) then in effect, before one arbitrator
    to be mutually agreed upon by both parties (or, if not agreed within 30 days of
    the first party proposing an arbitrator, determined under AAA rules). The
    Federal Arbitration Act shall govern the interpretation, enforcement and all
    proceedings at such arbitration. The award determined by the arbitration will be
    final and binding, except for any right of appeal provided by the Federal
    Arbitration Act, and may be entered in any court having jurisdiction over the
    parties for purposes of enforcement. The place of such arbitration shall be
    either Broward County, Florida or the U.S. county of your residence, and the
    proceedings shall be held there, except to the extent otherwise agreed to by the
    parties. In the event that you initiate arbitration in the county of your
    residence, the entity you have a Dispute with may transfer the arbitration to
    Broward County, Florida in the event that it agrees to pay the additional fees
    or costs you incur as a result of the change in location as determined by the
    arbitrator.`,
  },
  {
    title: "CHOICE OF LAW",
    content: `These Terms shall be governed by and construed in accordance with the laws of
    the State of Florida, without giving effect to any conflict of law principles.
    Any Disputes not subject to the arbitration provision discussed above shall be
    resolved exclusively in the federal or state courts sitting in Broward County,
    Florida, and you expressly consent to the jurisdiction of and venue in such
    courts and waive all defenses of lack of jurisdiction and inconvenient forum
    with respect to such courts for the purpose of litigating any such claim or
    action. Each participant agrees to service of process by mail or other method
    acceptable under the laws of the State of Florida.`,
  },
  {
    title: "SEVERABILITY",
    content: `If any provision of these Terms is deemed invalid by a court of competent
    jurisdiction, the invalidity of such provision shall not affect the validity of
    the remaining provisions of these Terms, which shall remain in full force and
    effect.`,
  },
  {
    title: "MODIFICATION AND TERMINATION",
    content: `We reserve the right to modify these Terms at any time. When we do so, we will
    update the “Effective Date” above. You will be notified of any material changes
    to these Terms via a posting on the Services. We may also send you an email at
    the email address we have on file for you. We shall have the right to
    immediately terminate these Terms with respect to any user which we, in our sole
    discretion, consider to be unacceptable, or in the event of any breach by you of
    these Terms.`,
  },
  {
    title: "APPLE DISCLAIMERS",
    content: `You acknowledge and agree that these Promotion Official Rules are between you
    and Sponsor, and do not impose any obligations upon Apple Inc. (“Apple”). You
    further acknowledge and agree that: Apple and its subsidiaries are third-party
    beneficiaries under these Promotion Official Rules and that, under these
    Promotion Official Rules, Apple will have the right (and will be deemed to have
    accepted the right) to enforce the terms and conditions of these Promotion
    Official Rules against you as a third-party beneficiary thereof. Apple has no
    responsibility for the iOS Bet Max Action App or any included content. To the
    maximum extent permitted by applicable law, Apple will have no warranty
    obligation whatsoever with respect to the iOS Bet Max Action App, and any other
    claims, losses, liabilities, damages, costs or expenses attributable to any
    failure to conform to any warranty. Apple is not responsible for addressing any
    of your claims or those of any third-party relating to the iOS Bet Max Action
    App or your possession and/or use of the iOS [INSERT NAME] App, including,
    without limitation: (i) maintenance and support; (ii) product liability claims;
    (iii) any claim that the iOS Bet Max Action App failed to conform to any
    applicable legal or regulatory requirement; and (iv) claims arising under
    consumer protection or similar legislation. In the event of any third-party
    claim that the iOS Bet Max Action App or your possession and use of the iOS Bet
    Max Action App infringes that third-party's intellectual property rights, Apple
    will not have any responsibility for the investigation, defense, settlement and
    discharge of such claim.`,
  },
];

export const PRIVACY_POLICY: { title: string; content: string }[] = [
  {
    title: "WHAT INFORMATION WE COLLECT",
    content: `We collect various categories of both personal and non-personal information from
    different sources, including information you provide to us, as well as other
    information about you and your online activities related to the Services.
    “Personal information” is any information that can be used to identify you as an
    individual or to allow someone to contact you, as well as information attributed
    with such information. For example, we collect the following information from
    and about you:
    
    Registration & Account Details: As part of your account opening process, and
    to allow us to provide the Services, you provide us with your telephone number
    as well as certification, through agreeing to the Terms and Conditions, that
    you are at least 18 years old in jurisdictions in which the minimum age for
    use of the Services is 18. In the event that you register for certain features
    on the Services, sign up for our communications, request redemption of a prize
    or engage in other transactions on the Services, we may collect certain
    additional information, including but not limited to your name, date of birth,
    postal address, email address, password, credit card and other financial
    account information, photograph, gender, favorite sports, sports teams and
    players and rival sports teams, account activity, browsing and transaction
    history, interests, hobbies and demographic information.

    Messages, Feedback and Requests: If you provide us with feedback or contact us
    via email, or if you send other messages to us or other users in connection
    with the Services, we will collect your name and email address, as well as any
    other content included in your communication.

    Location Information: We collect real-time information about the geographic
    location of your device (including precise geolocation information on a
    real-time basis). This information is required for your use of the Services to
    enable us to verify eligibility for certain features of our Services and for
    fraud prevention purposes.

    Device and Usage Information: When you use the Services, we capture and store
    certain information about your activity on the Services and information held
    on the devices you use. The “clickstream data” we collect may include device
    ID and telephone number and other information about your device, web browser
    and operating system and their settings, mobile network information, mobile
    apps installed or running on your device, the referring source that linked you
    to the Services, the pages, content or ads you see or click on during your
    visit and when and for how long you do so, items you download, the next site
    you visit when you leave the Services, and any search terms you have entered
    on the Services or a referral site. As is the case with many sites and mobile
    applications, our servers automatically collect your IP address when you visit
    our Services, and we may associate that with your domain name or that of your
    Internet access provider. Please see our Cookie Policy for more information.

    Stored Information and Files: The FOX Bet Super 6 mobile application may also access
    metadata and other information associated with files stored on your device.`,
  },
  {
    title: "OUR PURPOSE FOR COLLECTING THIS INFORMATION",
    content: `We or our service providers use your information for the purpose of conducting
    our business and providing the Services to you. For example, this includes the
    following purposes (but we also reserve the right to use your information for
    any other purposes that are reasonably ancillary to the business of providing
    you with the Services): To process and fulfill requested transactions, respond
    to your requests, questions and concerns and administer the Services and your
    account with us; To provide the Services and its features, including to
    facilitate contests and sweepstakes; Identification, verification and vetting
    purposes, including confirming your geographic location; To send you marketing
    and other communications, including information about products, services, and
    events, of ours and of others that we think might interest you. (You may opt out
    of receiving our marketing communications by following the instructions in the
    “Your Rights in Relation to Your Information” section below.) Tailoring ads
    displayed to you on our Services and elsewhere to your interests and history
    with us; To customize our Services to your interests and history with us;
    Statistical analysis and research; Research and development, including to
    develop and enhance existing and new features and offerings; Business
    intelligence, analytics, data and statistical analysis, market research,
    customer surveys and customer profiling; To comply with any legal and regulatory
    requirements or requests; To test and debug software, systems, tools and
    practices, such as to carry out batch testing of documents prior to the
    deployment of tools sourced from or provided by third parties; To audit
    compliance and to detect and combat against security risks and fraudulent
    activity; To protect our rights and property and recover debt; and Other
    purposes disclosed when we collect information. To perform the above functions,
    we may match information collected from you through different means or at
    different times, including your personal information, and use such information
    along with information obtained from other sources (including third parties like
    data aggregators, sales lead generators, and mobile and social media services)
    and publicly accessible information (such as information available publicly on
    the Internet). This information includes, for example, demographic information
    and updated contact information. We or our service providers may also use this
    combined information to assess the level of interest in, and use of, the
    Services, our emails and our other messaging campaigns both on an individual
    basis and in the aggregate. Among other things, Services usage information
    enables us to generate analytics reports on the usage of our Services. We
    utilize third party service providers for web analytics on the Services,
    including Google Analytics. To opt out of your usage of our Services being
    reported to us by Google Analytics, you may follow the instructions at
    https://tools.google.com/dlpage/gaoptout.`,
  },
  {
    title: "INFORMATION SHARING, DISCLOSURES AND RECIPIENTS",
    content: `We may disclose the information collected on the Services with others for a
    variety of reasons. In addition to the kinds of information sharing you might
    expect, such as sharing what you voluntarily post to public areas on the
    Services with other users, we may share your information, including the
    information described in Section 1 above, in the following circumstances: To our
    corporate affiliates; To any governmental or regulatory body or authority; To
    third parties for the purposes of processing any transactions relating to the
    Services; To third parties who provide services to us or on our behalf,
    including promotion partners; To third parties with which we co-brand offerings
    or from which we license trademarks and content for the Services or other
    offerings and other third parties for their marketing and other purposes (in
    accordance with their respective privacy policies), unless you tell us not to by
    following the instructions in the “Your Rights in Relation to Your Information”
    section below; for example, these third parties include FSG Services, LLC, a
    subsidiary of Fox Sports 1, LLC, and its corporate affiliates, as well as
    national and collegiate sports leagues, such as the NFL; With our co-sponsor(s)
    if we obtain your information in connection with a contest, sweepstakes,
    offering, or other promotional activity that is jointly offered by us and any
    third parties, unless you instruct us not to by following the instructions in
    the “Your Rights in Relation to Your Information” section below; In connection
    with a corporate change or dissolution, including for example a merger,
    acquisition, reorganization, consolidation, bankruptcy, liquidation, sale of
    assets or wind down of business; If we believe in good faith that such action is
    necessary or appropriate to comply with any law, regulation or legal process
    served on us, or to protect, enforce and defend our rights or property, protect
    your safety or the safety of others, or investigate or seek to address fraud or
    violation of law; For the purposes of disaster recovery; or With your consent.
    Your username on the Services and the city in which you registered for the
    Services may appear in our leaderboards that can be viewed by the public on the
    Services. In addition, certain of your profile information (such as your
    username, name and/or other information) may be viewed by other users
    participating in the Service in which you participate. We may include your
    username, name and/or other information in our newsletters, blogs or other
    publicity materials, to the extent permitted under applicable law. In addition,
    we may share non-personally identifiable Services usage information (including
    aggregate data) with others, for their own use, in a form that does not include
    your name or contact information.`,
  },
  {
    title: "THIRD-PARTY ADVERTISERS",
    content: `We may use advertisers, third-party ad networks, and other advertising companies
    to serve advertisements on the Services and other online properties. Please be
    advised that such advertising companies may gather information about your visits
    to the Services and other sites (through cookies, web beacons, and other
    technologies), the region of the country or world where your IP address
    indicates you are located, as well as other information about you. They may use
    such information to provide advertisements about goods and services of interest
    to you, to monitor which ads have been served to your browser and which webpages
    you were viewing when such ads were delivered. These advertisements may appear
    on the Services and on other online properties. These companies may employ
    cookies, clear GIFs and other tracking technologies to cause relevant ads to be
    displayed to you. Please see our Cookie Policy for more information. If you
    would like to learn more about this practice generally and to know your choices,
    please visit https://www.networkadvertising.org or
    http://www.aboutads.info/choices/, Your Online Choices at
    http://www.youronlinechoices.eu/ or Digital Alliance of Canada at
    https://youradchoices.ca/. Please note that this Privacy Policy does not cover
    the collection and use of information by such third-party advertisers.`,
  },
  {
    title: "INTERNATIONAL DATA TRANSFERS",
    content: `THE FOX BET SUPER 6 SERVICES ARE ONLY INTENDED FOR RESIDENTS OF THE UNITED
    STATES, NOT INCLUDING RESIDENTS OF WASHINGTON STATE. FOX Bet Super 6 is located
    in the US. If you choose to provide us with information, please understand that
    your personal information may be transferred to the US and that we may transfer
    that information to our affiliates and subsidiaries or to other third parties,
    across borders, and from your country or jurisdiction to other countries or
    jurisdictions around the world. If you are visiting from the EU or other regions
    with laws governing data collection and use that may differ from US law, please
    note that you are transferring your personal information to the US and other
    jurisdictions which may not have the same data protection laws as the EU. We
    have a policy of putting in place operational, procedural and technical measures
    designed to protect sensitive personal information. You acknowledge and
    understand that by providing your personal information: (i) your personal
    information will be used for the uses identified above in accordance with this
    Privacy Policy; and (ii) your personal information may be transferred to the US
    and other jurisdictions as indicated above.`,
  },
  {
    title: "YOUR RIGHTS IN RELATION TO YOUR INFORMATION",
    content: `We provide you the opportunity to opt-out of marketing communications by
    clicking the “unsubscribe” or similar link in email communications or by
    contacting us using the contact information below. You may tell us that you do
    not want us to share your personal information with third parties for their
    marketing and other purposes by contacting us using the contact information
    below, providing sufficient information for us to verify your identify and
    account with us, and indicating that you are asking us to stop sharing your
    information with third parties for their marketing and other purposes. Please be
    aware that in some circumstances it may take us a few days and you may receive a
    few more messages until your opt-out request is processed. To stop receiving
    promotional messages from third parties who already have your contact
    information, please contact them directly. Additionally, even if you opt out of
    promotional correspondence from us, we may send you information regarding our
    Services and your relationship with us, such as information about changes to our
    policies and other notices and disclosures required by law. You may review,
    update or modify certain of the personal information that is stored in your user
    account on the Services (if you have one) by logging in to the “Profile” or
    similar area of the Services. You may also request that we update or delete
    certain of your personal information, subject to some conditions, by contacting
    us using the contact information below. We may ask you to verify your identity
    and to provide other details before we are able to provide you with any
    information, correct any inaccuracies, or delete any information. Your right to
    delete your information is subject to our records retention policies and
    applicable law.`,
  },
  {
    title: "COLLECTION AND USE OF INFORMATION FROM CHILDREN",
    content: `The Services are not intended for anyone under the age of 18. In addition, this
    Privacy Policy does not apply to any site or mobile application that is directed
    to children under 13.`,
  },
  {
    title: "THIRD-PARTY SERVICES AND LINKS AND SOCIAL MEDIA",
    content: `Certain third-party service providers we use in connection with the Services,
    such as payment transaction processors, have their own privacy policies with
    respect to the information we are required to provide to them for your
    purchase-related transactions. We recommend you read their privacy policies so
    you can understand the manner in which your personal information will be handled
    by these providers. In particular, remember that certain providers may be
    located in or have facilities that are located in a different jurisdiction. The
    Services contain links, banners, widgets or advertisements that lead to other
    sites and online properties. We are not responsible for the privacy practices or
    content of such other sites. Once you leave the Services or are redirected to a
    third-party website or application, you are no longer governed by this Privacy
    Policy and the third-party's posted privacy policies will govern instead. We use
    several social media platforms to communicate with our users, some of which are
    operated by third parties, such as Facebook, Twitter and Snapchat. The Services
    may include features and functionalities that allow you to share information
    with us that you have already provided to a third party social media platform.
    The information we receive from that third party may depend on the privacy
    settings you have on the third party platform. We may also include tools on the
    Services that allow you to share and/or publicly post content or information
    from the Services to your profile on a third party social media. For example, we
    use social media plug-ins that can be identified by the social media buttons
    marked with the logo of the provider of the respective social media networks. In
    general, we have implemented these plug-ins using a 2-click solution, which
    means that, if you click on one of the plug-ins will your personal information
    be transmitted. By activating the plug-in, information is automatically
    transmitted to the respective plug-in provider and stored by them. We neither
    have influence over the information collected and processing operations
    conducted by the social media platform providers, nor are we aware of the full
    extent of information collection, purposes, or the retention periods.
    Information on the purpose and scope of information collection and its
    processing by the plug-in provider can be found in the respective privacy
    policies of these providers, where you will also find further information on
    your rights and options for privacy protection with respect to those third
    parties.`,
  },

  {
    title: "SECURITY",
    content: `We endeavor to protect the security and integrity of sensitive personal
    information collected via the Services; please understand, however, that no data
    transmissions over the Internet, stored on our system or otherwise in our care
    can be guaranteed to be 100% secure or safe from intrusion by others, such as
    hackers. Consequently, we cannot ensure or warrant the security of any of your
    information or communications in relation to the Services and you understand
    that your sharing of information and any communications in connection with the
    Services is done at your own risk. A third party could view the information you
    send in transit by electronic means. We will have no liability for disclosure of
    your information due to errors or unauthorized acts of third parties during or
    after transmission. If we learn of a security systems breach we may attempt to
    notify you electronically so that you can take appropriate protective steps. By
    using the Services or providing personal information to us, you agree that we
    can communicate with you electronically regarding security, privacy and
    administrative issues relating to your use of the Services. We may post a notice
    via the Services if a security breach occurs. We may also send an email to you
    at the email address you have provided to us in these circumstances. Depending
    on where you live, you may have a legal right to receive notice of a security
    breach in writing. If you create an account on our Services, you are responsible
    for maintaining the strict confidentiality of your account password, and you
    shall be responsible for any activity that occurs using your account
    credentials, whether or not you authorized such activity. Please notify us of
    any unauthorized use of your password or account or any other breach of
    security.`,
  },
  {
    title: "WIRELESS ADDRESSES",
    content: `If the email address you provide to us is a wireless email address, you agree to
          receive messages from us at such address unless and until you tell us not to
          send messages to that address, by following the instructions in the “Your Rights
          in Relation to Your Information” section above. You may change your email
          preferences at any time, but your wireless carrier's standard rates will apply
          as long as you are receiving messages at a wireless email address. If you give
          us a wireless email address, you represent that you are the owner or authorized
          user of the device on which messages will be received and that you are
          authorized to approve the applicable charges.`,
  },
  {
    title: "YOUR CALIFORNIA PRIVACY RIGHTS",
    content: `In accordance with California Civil Code Section 1798.83, we have in place a
    policy not to disclose personal information of users to third parties for the
    third parties' direct marketing purposes if the user has exercised an option to
    opt out of such information-sharing, available for review here. If you wish to
    opt out of our sharing of your information with third parties for the third
    parties' direct marketing purposes, please follow the instructions in the “Your
    Rights in Relation to Your Information” section above.`,
  },
  {
    title: "HOW WE RESPOND TO “DO NOT TRACK” SIGNALS",
    content: `At this time our Services do not recognize and change our tracking practices in
    response to automated browser signals regarding tracking mechanisms, which may
    include “Do Not Track” instructions. Our third party partners, such as web
    analytics companies and third party ad networks, collect information about your
    online activities over time and across our Services and other online properties.
    These third parties may not change their tracking practices in response to “Do
    Not Track” settings in your web browser and we do not obligate these parties to
    do so. We utilize third party service providers for web analytics on the
    Services, including Google Analytics. To opt out of your usage of our Services
    being recorded by Google Analytics, you may follow the instructions at
    https://tools.google.com/dlpage/gaoptout.`,
  },
  {
    title: "CHANGES TO THIS PRIVACY POLICY",
    content: `We reserve the right to modify this Privacy Policy at any time, so please review
    it frequently. Changes and clarifications will take effect immediately upon
    their posting on the Services. If we make material changes to this policy, we
    will update the “Effective Date” above and notify you via the Services that it
    has been updated. We may also send you an email via the email address we have on
    file for you.`,
  },
  {
    title: "CONTACT US",
    content: `If you have any questions, comments or concerns about these Terms, please
    contact us at:`,
  },
];
