import clsx from "clsx";
import { DateFormats } from "helpers/constants/formats";
import { dateToLocalTime } from "helpers/date-to-local-time";
import { ContestsItemType } from "types/request";
import "./contest-rich-card.scss";

interface ContestRichCardProps {
  contestItem: ContestsItemType;
  selected?: boolean;
  onClick?: (contest: ContestsItemType) => void;
}

const MOCK_LOGO = "images/nfl-logo.png";
export const ContestRichCard: React.FC<ContestRichCardProps> = ({
  contestItem,
  selected,
  onClick,
}) => {
  const { startDT, name } = contestItem.contest;

  const handleClick = () => {
    if (onClick) {
      onClick(contestItem);
    }
  };

  return (
    <button
      className={clsx("contest-rich-card", {
        "contest-rich-card--selected": selected,
      })}
      onClick={handleClick}
    >
      <img className="contest-rich-card__image" src={MOCK_LOGO} alt="logo" />
      <div className="contest-rich-card__content">
        <div className="contest-rich-card__title">{name}</div>
        <div className="contest-rich-card__details">
          {dateToLocalTime(startDT).format(DateFormats.SHORT_DATE)}
        </div>
      </div>
    </button>
  );
};
