import { SVGProps } from "react";

export const CrossMarkCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.9456 4.86052L5.17151 14.6346" stroke="currentColor" />
    <path d="M14.9457 14.6346L5.17163 4.86052" stroke="currentColor" />
  </svg>
);
