import clsx from "clsx";
import { Button } from "components/button";
import { ContentWithSubheader } from "components/content-with-subheader";
import { ProfileImage } from "components/profile-image";
import { selectAuthPayload } from "store/auth";
import { formatToPoints } from "helpers/format-to-points";
import { formatToPosition } from "helpers/format-to-position";
import { useAppSelector } from "hooks/store";
import "./leaderboard-current-user.scss";

interface LeaderboardCurrentUserProps {
  place: number;
  points: number;
  onLeaderboardClick: () => void;
  className?: string;
}

export const LeaderboardCurrentUser: React.FC<LeaderboardCurrentUserProps> = ({
  place,
  points,
  onLeaderboardClick,
  className,
}) => {
  const { username, firstName, lastName } = useAppSelector(selectAuthPayload);

  return (
    <div className={clsx("leaderboard-current-user", className)}>
      <ContentWithSubheader
        content={formatToPosition(place)}
        subheader="PLACE"
        boldContent
      />

      <div className="leaderboard-current-user__user-profile">
        <ProfileImage className="leaderboard-current-user__image" />
        <ContentWithSubheader
          wrapperClassName="leaderboard-current-user__details-wrapper"
          subheaderClassName="leaderboard-current-user__username"
          content={`${firstName} ${lastName}`}
          subheader={`@${username}`}
        />
      </div>
      <ContentWithSubheader
        content={formatToPoints({
          value: Math.round(points),
          separator: "comma",
          withSuffix: false,
        })}
        subheader="TD"
        boldContent
      />
      <Button
        fontSize="sm-regular"
        className="leaderboard-current-user__leader-board-button"
        onClick={onLeaderboardClick}
      >
        Leaderboard
      </Button>
    </div>
  );
};
