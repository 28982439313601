import { useState } from "react";
import clsx from "clsx";
import { useCountdown } from "hooks/use-countdown";
import "./send-again-button.scss";

interface SendAgainButtonProps {
  onClick: () => void;
}

export const SendAgainButton: React.FC<SendAgainButtonProps> = ({ onClick }) => {
  const [isRequestSent, setIsRequestSent] = useState(false);

  const [counter, startCountdown] = useCountdown({
    initialValue: 20,
    tickMs: 1_000,
    onFinish: () => setIsRequestSent(false),
  });

  const handleClick = () => {
    startCountdown();
    setIsRequestSent(true);
    onClick();
  };

  return (
    <button
      disabled={isRequestSent}
      type="button"
      onClick={handleClick}
      className={clsx("send-again-button", {
        "send-again-button--request-sent": isRequestSent,
      })}
    >
      Send again {counter < 20 ? `(${counter})` : null}
    </button>
  );
};
