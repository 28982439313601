import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Button } from "components/button";
import { Paths } from "types/router";
import "./bottom-navigation.scss";

interface BottomNavigationProps {
  cancelText?: string;
  cancelPath?: Paths;
  onCancelAction?: () => void | Promise<void>;
  submitText?: string;
  onSubmit?: () => void;
  nextButtonActive?: boolean;
  isSubmitType?: boolean;
  onlySubmitOnMd?: boolean;
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({
  cancelText,
  cancelPath,
  onCancelAction,
  submitText,
  onSubmit,
  nextButtonActive = true,
  isSubmitType,
  onlySubmitOnMd,
}) => {
  const navigate = useNavigate();

  const handleCancel = async (path?: Paths) => {
    if (onCancelAction) {
      await onCancelAction();
    }
    if (path) {
      navigate(path);
    }
  };

  return (
    <div data-testid="bottom-navigation" className="bottom-navigation">
      {(cancelPath || onCancelAction) && (
        <Button
          className={clsx("bottom-navigation__button", {
            "bottom-navigation__cancel-button--only-submit": onlySubmitOnMd,
          })}
          onClick={() => {
            handleCancel(cancelPath);
          }}
          type="button"
          variant="link"
        >
          {cancelText ?? "CANCEL"}
        </Button>
      )}
      <Button
        data-testid="button-submit"
        disabled={!nextButtonActive}
        type={isSubmitType ? "submit" : "button"}
        className={clsx("bottom-navigation__button", {
          "bottom-navigation__submit-button--only-submit": onlySubmitOnMd,
        })}
        onClick={onSubmit}
        fontSize="sm-regular"
        variant="primary-rounded"
      >
        {submitText ?? "NEXT"}
      </Button>
    </div>
  );
};
