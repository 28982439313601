import { FocusEvent, MouseEvent } from "react";

export const moveCursorToEnd = (inputRef: React.RefObject<HTMLInputElement>) => {
  if (inputRef.current) {
    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
  }
};

export const moveCursorByEvent = (
  event: FocusEvent<HTMLInputElement, Element> | MouseEvent<HTMLInputElement>,
  place: "start" | "end"
) => {
  const { value } = event.currentTarget;
  const position = place === "start" ? 0 : value.length;
  event.currentTarget.setSelectionRange(position, position);
};
