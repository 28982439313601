import clsx from "clsx";
import { formatToPosition } from "helpers/format-to-position";
import { ContestStatus } from "types/contest";
import "./contest-bar-status-part.scss";

interface ContestBarStatusPartProps {
  status: ContestStatus;
  userPlace?: number;
  wide?: boolean;
  hasEntered: boolean;
  entriesFull: boolean;
}

export const ContestBarStatusPart: React.FC<ContestBarStatusPartProps> = ({
  status,
  userPlace,
  wide,
  hasEntered,
  entriesFull,
}) => {
  if (entriesFull && !hasEntered) {
    return <span className="contest-bar-status__big-span">FULL</span>;
  }
  switch (status) {
    case ContestStatus.COMPLETED:
      return (
        <div
          className={clsx(
            "contest-bar-status__position",
            wide && "contest-bar-status__position--wide"
          )}
        >
          {userPlace !== 0 && userPlace && formatToPosition(userPlace)}
        </div>
      );
    case ContestStatus.UPCOMING:
    case ContestStatus.ACTIVE:
      return hasEntered ? (
        <div className="contest-bar-status__label">You're in the game</div>
      ) : (
        <div
          className={clsx(
            "contest-bar-status__entry-wrapper",
            wide && "contest-bar-status__entry-wrapper--wide"
          )}
        >
          <div
            className={clsx(
              "contest-bar-status__right-side-text",
              wide && "contest-bar-status__right-side-text--wide"
            )}
          >
            ENTRY
          </div>
          <span className="contest-bar-status__big-span">FREE</span>
        </div>
      );

    default:
      return null;
  }
};
