import { ReactText, useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { getCssVariable } from "helpers/get-css-variable";
import { useAppDispatch } from "hooks/store";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { OfflineIcon, OnlineIcon } from "assets/icons";

export const useNetworkStatus = () => {
  const dispatch = useAppDispatch();
  const toastRef = useRef<ReactText | null>(null);
  const successColor = getCssVariable("--success-color");
  const isWide = useMediaQuery(MediaQueryBreakpoints.WIDE);

  const notify = useCallback(
    () =>
      (toastRef.current = toast.error(
        "The application is in offline mode. Connect to the network.",
        {
          autoClose: false,
          closeButton: !isWide,
          closeOnClick: false,
          draggable: !isWide,
          icon: OfflineIcon,
          theme: "colored",
          position: toast.POSITION.BOTTOM_LEFT,
        }
      )),
    [isWide]
  );

  const dismiss = useCallback(() => {
    if (toastRef.current) {
      toast.dismiss(toastRef.current);
    }
    toast.success("Internet connection has been restored", {
      icon: () => <OnlineIcon style={{ color: successColor }} />,
      autoClose: 2000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }, [successColor]);

  useEffect(() => {
    const onFirstLoad = () => {
      if (!navigator.onLine) {
        notify();
      }
    };

    window.addEventListener("load", onFirstLoad);
    window.addEventListener("online", dismiss);
    window.addEventListener("offline", notify);

    return () => {
      window.removeEventListener("load", onFirstLoad);
      window.removeEventListener("online", dismiss);
      window.removeEventListener("offline", notify);
    };
  }, [dispatch, dismiss, notify]);
};
