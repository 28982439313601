import { WagerModifierFields } from "helpers/get-wager-modifier";
import { TeamType } from "types/contest";

type WagerSelectedTeam = Omit<
  WagerModifierFields,
  "wagerType" | "pointSpread" | "overUnder"
>;

export const getSelectedTeamName = (wager: WagerSelectedTeam) => {
  switch (wager.chosenTeam) {
    case TeamType.HOME:
      return wager.homeTeam;
    case TeamType.AWAY:
      return wager.awayTeam;
    default:
      return "unknown";
  }
};
