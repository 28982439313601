import { useState } from "react";
import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { useDetectDevice } from "hooks/use-detect-device";
import { useInstallationPrompt } from "hooks/use-installation-prompt";
import { AndroidPrompt } from "./android-prompt";
import { IosPrompt } from "./ios-prompt";

export const InstallationPrompt: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const { isIos, isAndroid, isStandalone } = useDetectDevice();
  const { isPromptEventCalled, isAppInstalled, handleInstallPrompt } =
    useInstallationPrompt();

  const handleClick = () => {
    handleInstallPrompt();
    setIsVisible(false);
  };

  const isMobile = isIos || isAndroid;
  const { userId } = useAppSelector(selectAuthPayload);

  if (!isMobile || isStandalone || !isVisible) {
    return null;
  }

  if (isIos) {
    return <IosPrompt onClick={() => setIsVisible(false)} />;
  }

  if (isAndroid || isPromptEventCalled) {
    return (
      <AndroidPrompt
        isLoggedUser={Boolean(userId)}
        onClick={handleClick}
        onDismiss={() => setIsVisible(false)}
        variant={isAppInstalled ? "open" : "install"}
      />
    );
  }

  return null;
};
