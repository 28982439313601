import { ChangeEvent, ClipboardEvent, useRef } from "react";
import clsx from "clsx";
import { CodeInput } from "components/inputs";
import { REGEX_VERIFY_CODE, REGEX_VERIFY_PASTED_CODE } from "helpers/constants";
import { replaceAtIndex } from "helpers/replace-at-index";
import "./code-selector.scss";

interface CodeSelectorProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCodePaste: (verificationCode: string) => void;
  value: string;
  numberOfDigits: number;
  error: boolean;
  errorText?: string;
  errorTextClassName: string;
}

export const CodeSelector: React.FC<CodeSelectorProps> = ({
  onChange,
  onCodePaste,
  value,
  numberOfDigits,
  error,
  errorText,
  errorTextClassName,
}) => {
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleFocus = (inputIndex: number) => {
    const current = inputsRef.current[inputIndex];
    if (current) {
      current.focus();
    }
  };

  const callIfValueHasContent = (
    event: React.ChangeEvent<HTMLInputElement>,
    callback: () => void
  ) => {
    if (event.target.value.length) {
      callback();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, inputIndex: number) => {
    const { value: targetValue } = event.target;
    if (REGEX_VERIFY_CODE.test(targetValue) || !targetValue) {
      const newValue = replaceAtIndex(value, inputIndex, targetValue ? targetValue : " ");
      onChange({ ...event, target: { ...event.target, value: newValue } });
      const isLastInput = inputIndex !== value.length - 1;
      if (isLastInput) {
        callIfValueHasContent(event, () => handleFocus(inputIndex + 1));
        return true;
      }
    }
    return false;
  };

  const handleOnPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedCode = event.clipboardData.getData("text").trim();
    if (REGEX_VERIFY_PASTED_CODE.test(pastedCode)) {
      handleFocus(5);
      onCodePaste(pastedCode);
    }
  };
  return (
    <>
      <div className="code-selector">
        {[...Array(numberOfDigits).keys()].map((index) => {
          const isFirstElement = index === 0;
          const isLastElement = index === numberOfDigits - 1;
          return (
            <CodeInput
              key={`code-selector-${index}`}
              ref={(element) => (inputsRef.current[index] = element)}
              className={clsx("code-selector__input", {
                "code-selector__input--error":
                  error && (!value[index] || value[index] === " "),
              })}
              onLeftArrowClick={() =>
                handleFocus(isFirstElement ? inputsRef.current.length - 1 : index - 1)
              }
              onRightArrowClick={() => handleFocus(isLastElement ? 0 : index + 1)}
              onChange={(event) => handleChange(event, index)}
              value={value[index] || ""}
              placeholder="_"
              replaceMode
              autoComplete="one-time-code"
              onPaste={handleOnPaste}
            />
          );
        })}
      </div>
      <div className={errorTextClassName}>{errorText}</div>
    </>
  );
};
