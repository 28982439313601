import { SVGProps } from "react";

export const ExclamationMarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={21} width={20} {...props}>
    <path
      d="M11.311 11.5C11.311 11.7761 11.0872 12 10.811 12H9.81104C9.53489 12 9.31104 11.7761 9.31104 11.5V4.5C9.31104 4.22386 9.53489 4 9.81104 4H10.811C11.0872 4 11.311 4.22386 11.311 4.5V11.5ZM11.311 15.5C11.311 15.7761 11.0872 16 10.811 16H9.81104C9.53489 16 9.31104 15.7761 9.31104 15.5V14.5C9.31104 14.2239 9.53489 14 9.81104 14H10.811C11.0872 14 11.311 14.2239 11.311 14.5V15.5ZM10.311 0C8.99782 0 7.69746 0.258658 6.4842 0.761205C5.27094 1.26375 4.16855 2.00035 3.23997 2.92893C1.3646 4.8043 0.311035 7.34784 0.311035 10C0.311035 12.6522 1.3646 15.1957 3.23997 17.0711C4.16855 17.9997 5.27094 18.7362 6.4842 19.2388C7.69746 19.7413 8.99782 20 10.311 20C12.9632 20 15.5067 18.9464 17.3821 17.0711C19.2575 15.1957 20.311 12.6522 20.311 10C20.311 8.68678 20.0524 7.38642 19.5498 6.17317C19.0473 4.95991 18.3107 3.85752 17.3821 2.92893C16.4535 2.00035 15.3511 1.26375 14.1379 0.761205C12.9246 0.258658 11.6243 0 10.311 0Z"
      fill="currentColor"
    />
  </svg>
);
