import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ContestBar, ContestRichCard } from "components/contest";
import { CONTEST_PARAM } from "components/contests-mobile-search-view";
import { NoContestsMessage } from "components/no-contests-message";
import { ScrollWrapper } from "components/scroll-wrapper";
import { SearchBar } from "components/search-bar";
import { selectSortOption } from "store/app-slice";
import {
  fetchUsersDetailsAndParlay,
  selectMyContestRequestState,
  selectMyContests,
  selectSelectedMyContest,
  setSelectedMyContest,
} from "store/my-contest-slice";
import { getContestUrl } from "helpers/get-contest-url";
import { sortContests } from "helpers/sort-contests";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useMissingContestFallback } from "hooks/use-missing-contest-fallback";
import { MyContestMainTab } from "types/contest";
import { ContestsItemType, RequestState } from "types/request";
import { Paths, PathsMyContests } from "types/router";
import "./contests-list.scss";

export const ContestsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sortOrder, sortField } = useAppSelector(selectSortOption);
  const [searchParams] = useSearchParams();
  const { contestId, contestType } = useParams<{
    contestId?: string;
    contestType?: string;
  }>();
  const contestParamValue = searchParams.get(CONTEST_PARAM);

  const { inProgressContests, previousContests } = useAppSelector(selectMyContests);
  const contests =
    contestType === MyContestMainTab.IN_PROGRESS ? inProgressContests : previousContests;
  const sortedContests = useMemo(
    () => sortContests(contests, sortOrder, sortField, contestParamValue),
    [contestParamValue, contests, sortField, sortOrder]
  );
  const selectedMyContest = useAppSelector(selectSelectedMyContest);
  const requestState = useAppSelector(selectMyContestRequestState);

  useMissingContestFallback({
    contestList: sortedContests,
    contestId: contestId || "",
    callback: () => {
      if (requestState === RequestState.SUCCESS) {
        navigate(Paths.CONTESTS);
      }
    },
  });

  useEffect(() => {
    if (sortedContests.length) {
      dispatch(setSelectedMyContest(sortedContests[0]));
    } else {
      dispatch(setSelectedMyContest(null));
    }
  }, [contestType, dispatch, sortedContests]);

  useEffect(() => {
    const newSelectedContest = contests.find(
      ({ contest }) => contest.id === Number(contestId)
    );
    if (newSelectedContest) {
      dispatch(setSelectedMyContest(newSelectedContest));
    }
  }, [dispatch, contests, contestId]);

  useEffect(() => {
    if (selectedMyContest) {
      const fetch = dispatch(fetchUsersDetailsAndParlay(selectedMyContest.contest.id));
      return () => {
        fetch.abort();
      };
    }
  }, [dispatch, selectedMyContest]);

  const handleTournamentCardClick = (contestItem: ContestsItemType) => {
    const { id, status } = contestItem.contest;
    navigate(getContestUrl(status, id));
    dispatch(setSelectedMyContest(contestItem));
  };

  return (
    <div className="contests-list">
      <div className="contests-list__sm">
        {sortedContests.map((contestItem) => (
          <ContestBar contestItem={contestItem} key={contestItem.contest.id} />
        ))}
      </div>
      <div className="contests-list__md">
        <SearchBar
          mobileSortPath={PathsMyContests.SEARCH_ABSOLUTE}
          mobileSearchPath={PathsMyContests.SORT_ABSOLUTE}
          className="contests-list__search-bar"
        />
        <ScrollWrapper variant="dark" className="contests-list__contests-scroll">
          {!sortedContests.length && requestState === RequestState.SUCCESS ? (
            <NoContestsMessage message="No contests to display" />
          ) : (
            sortedContests.map((contestItem) => (
              <ContestRichCard
                selected={selectedMyContest?.contest.id === contestItem.contest.id}
                contestItem={contestItem}
                key={contestItem.contest.id}
                onClick={handleTournamentCardClick}
              />
            ))
          )}
        </ScrollWrapper>
      </div>
    </div>
  );
};
