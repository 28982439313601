import React, { useEffect, useRef } from "react";
import { setSortOption } from "store/app-slice";
import { useAppDispatch } from "hooks/store";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { SortOptions } from "types/sort-contests";
import { MyContestsMdContent } from "pages/my-contests/my-contests-md-content";
import { MyContestsSmContent } from "pages/my-contests/my-contests-sm-content";
import "./my-contests.scss";

export const MyContestsPage: React.FC = () => {
  const myContestsRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(MediaQueryBreakpoints.MOBILE);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSortOption(SortOptions[0]));
    };
  }, [dispatch]);

  return (
    <div className="my-contests" ref={myContestsRef}>
      {isMobile ? <MyContestsSmContent /> : <MyContestsMdContent />}
    </div>
  );
};
