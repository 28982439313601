import { Button } from "components/button";
import { AddToHomeScreenIcon, IosShareIcon } from "assets/icons";
import "./ios-prompt.scss";

interface IosPromptProps {
  onClick: () => void;
}

export const IosPrompt: React.FC<IosPromptProps> = ({ onClick }) => {
  return (
    <div className="ios-prompt">
      <div className="ios-prompt__text">
        Install this webapp on your device. Tap{" "}
        <IosShareIcon className="ios-prompt__icon" /> add then add to home screen
        <AddToHomeScreenIcon className="ios-prompt__icon" />
      </div>
      <Button onClick={onClick} variant="clear" className="ios-prompt__close-button">
        X
      </Button>
    </div>
  );
};
