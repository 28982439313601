import { ReactNode } from "react";
import { ContestCard, ContestFooter } from "components/contest";
import { ProgressBar } from "components/progress-bar";
import { ScrollWrapper } from "components/scroll-wrapper";
import { selectSelectedContest } from "store/app-slice";
import { accumulateObjectFields } from "helpers/accumulate-object-fields";
import { DateFormats } from "helpers/constants/formats";
import { dateToLocalTime } from "helpers/date-to-local-time";
import { formatToCurrency } from "helpers/format-to-currency";
import { parsePrizesString } from "helpers/parse-prizes";
import { useAppSelector } from "hooks/store";
import "./contest-details.scss";

const MOCK_CONTEST_IMAGE = "/images/tournament-background.webp";

export const ContestDetails: React.FC = () => {
  const selectedContest = useAppSelector(selectSelectedContest);
  if (!selectedContest) {
    return null;
  }
  const { perContestantBudget, maxContestants, startDT, distributedPrizes } =
    selectedContest.contest;
  const { totalEntries } = selectedContest.contestantStats;

  const parsedPrizes = parsePrizesString(distributedPrizes || "");

  const firstPlacePrize = parsedPrizes[0]?.prize || 0;
  const totalPrize = accumulateObjectFields(parsedPrizes, "prize", 0);

  const progress = Math.round((totalEntries / maxContestants) * 100);
  const cardsValues: { property: string; value: ReactNode; progressBar?: ReactNode }[] = [
    {
      property: "Total prize",
      value: formatToCurrency({ value: totalPrize, withDollar: true }),
    },
    {
      property: "Total entries",
      value: `${totalEntries}/${maxContestants}`,
      progressBar: (
        <div className="contest-details__progress-wrapper">
          <ProgressBar completed={progress} />
        </div>
      ),
    },
    {
      property: "1st place",
      value: formatToCurrency({ value: firstPlacePrize, withDollar: true }),
    },
    {
      property: "Budget",
      value: (
        <>
          {perContestantBudget &&
            formatToCurrency({ value: perContestantBudget, withDollar: false })}
          <span className="contest-details__currency">TD</span>
        </>
      ),
    },
  ];

  return (
    <div className="contest-details">
      <ScrollWrapper variant="dark" className="contest-details__body">
        <div className="contest-details__background-wrapper">
          <img
            className="contest-details__background"
            src={MOCK_CONTEST_IMAGE}
            alt="contest background"
          />
          <div className="contest-details__gradient" />
        </div>
        <div className="contest-details__date-bar">
          <div className="contest-details__calendar">
            <div className="contest-details__calendar-yellow-bar" />
            <div className="contest-details__day">
              {dateToLocalTime(startDT).format(DateFormats.DAY)}
            </div>
          </div>
          <div className="contest-details__date">
            <div>{dateToLocalTime(startDT).format(DateFormats.LONG_DATE)}</div>
            <div className="contest-details__event-time">
              {dateToLocalTime(startDT).format(DateFormats.TIME).toUpperCase()}
            </div>
          </div>
        </div>
        <div className="contest-details__content-wrapper">
          {cardsValues.map(({ property, value, progressBar }) => (
            <ContestCard className="contest-details__card" key={property}>
              <div className="contest-details__content-property">{property}</div>
              <div className="contest-details__content-value-wrapper">
                <div className="contest-details__content-value">{value}</div>
                {progressBar}
              </div>
            </ContestCard>
          ))}
        </div>
      </ScrollWrapper>
      <ContestFooter free />
    </div>
  );
};
