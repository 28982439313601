import { useEffect } from "react";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import axios from "axios";
import { resetState } from "store/auth";
import { API_URL } from "helpers/constants/api";
import { API_TOKEN_NAME } from "helpers/constants/api";
import { useAppDispatch } from "hooks/store";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const token = `Bearer ${localStorage.getItem(API_TOKEN_NAME)}`;
  if (config.headers) {
    config.headers.authorization = token;
  }
  return config;
});

export const AxiosInterceptor = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          toast.warning("Session expired");
          Auth.signOut();
          dispatch(resetState());
        } else {
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);

  return null;
};

export default axiosInstance;
