import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./app-slice";
import { authReducer } from "./auth/auth-slice";
import { myContestReducer } from "./my-contest-slice";
import { registerStateReducer } from "./register-slice";

export const rootReducer = combineReducers({
  appReducer,
  authReducer,
  registerStateReducer,
  myContestReducer,
});
