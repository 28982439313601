import React from "react";
import clsx from "clsx";
import { Input, InputProps } from "components/inputs";
import "./labeled-input.scss";

interface LabeledInputProps extends Omit<InputProps, "className"> {
  labelText?: string;
  wrapperClassName?: string;
}

export const LabeledInput = React.forwardRef<HTMLInputElement, LabeledInputProps>(
  ({ labelText, wrapperClassName, ...restProps }, ref) => {
    return (
      <div className={clsx("labeled-input", wrapperClassName)}>
        <label className="labeled-input__label">{labelText}</label>
        <Input ref={ref} className="labeled-input__input" {...restProps} />
      </div>
    );
  }
);

LabeledInput.displayName = "LabeledInput";
