import { Wager } from "types/request";

export const sumPayoutAndWager = (wagers: Wager[]) => {
  return wagers.reduce(
    (sum, wager) => {
      sum.totalWager += wager.amount;
      sum.totalPayout += wager.amountWon;

      return sum;
    },
    { totalWager: 0, totalPayout: 0 }
  );
};
