import { ContestsItemType } from "types/request";

export const setSpecificContestEntered = (contests: ContestsItemType[], id: number) => {
  return contests.map((contestItem) => {
    if (contestItem.contest.id === id) {
      return {
        ...contestItem,
        contestantStats: {
          ...contestItem.contestantStats,
          hasEntered: true,
          totalEntries: contestItem.contestantStats.totalEntries + 1,
        },
      };
    }
    return contestItem;
  });
};
