import { ReactNode, useEffect } from "react";
import { InstallationPrompt } from "components/installation-prompt";
import { LoaderSpinner } from "components/loader-spinner";
import { fetchContests } from "store/app-slice";
import {
  fetchSession,
  resetState,
  selectAuthPayload,
  selectRequestState,
} from "store/auth";
import { fetchMyContests } from "store/my-contest-slice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useNetworkStatus } from "hooks/use-network-status";
import { RequestState } from "types/request";
import "./config-provider.scss";

interface ConfigProviderProps {
  children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  useNetworkStatus();
  const dispatch = useAppDispatch();
  const requestState = useAppSelector(selectRequestState);
  const { userId } = useAppSelector(selectAuthPayload);
  useEffect(() => {
    dispatch(resetState());
    dispatch(fetchSession());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchContests());
      dispatch(fetchMyContests());
    }
  }, [dispatch, userId]);

  return (
    <div className="config-provider">
      {[RequestState.PENDING, RequestState.IDLE].includes(requestState) ? (
        <LoaderSpinner />
      ) : (
        <div className="config-provider__children">
          {children}
          <InstallationPrompt />
        </div>
      )}
    </div>
  );
};
