import { useState } from "react";
import ReactPaginate from "react-paginate";
import { SectionItems } from "components/contest/contest-section/section-items";
import { ContestsItemType } from "types/request";
import "./contests-pagination.scss";

interface PaginatedContestsProps {
  itemsPerPage: number;
  contests: ContestsItemType[];
  wide?: boolean;
}

export const PaginatedContests: React.FC<PaginatedContestsProps> = ({
  itemsPerPage,
  contests,
  wide,
}) => {
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(contests.length / itemsPerPage);

  const startOffset = (page * itemsPerPage) % contests.length;
  const endOffset = startOffset + itemsPerPage;

  const currentItems = contests.slice(startOffset, endOffset);

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected);
  };

  return (
    <>
      <SectionItems wide={wide} contests={currentItems} />
      {contests.length > itemsPerPage && (
        <ReactPaginate
          nextLabel="NEXT"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="PREVIOUS"
          pageClassName="pagination__page-item"
          pageLinkClassName="pagination__page-link"
          previousClassName="pagination__page-item"
          previousLinkClassName="pagination__page-link"
          nextClassName="pagination__page-item"
          nextLinkClassName="pagination__page-link"
          breakLabel="..."
          breakClassName="pagination__page-item"
          breakLinkClassName="pagination__page-link"
          containerClassName="pagination"
          activeLinkClassName="pagination__page-link--active"
          disabledLinkClassName="pagination__page-link--disabled"
        />
      )}
    </>
  );
};
