import { BottomNavigation } from "components/bottom-navigation";
import { CommonForm } from "components/common-form";
import { useIntroduceUser } from "hooks/use-introduce-user";
import { Paths } from "types/router";
import { OnboardingTournamentImageSVG } from "assets/onboarding-tournament";
import "./onboarding-tournament.scss";
interface OnboardingTournamentProps {
  onNextClick: () => void;
}

export const OnboardingTournament: React.FC<OnboardingTournamentProps> = ({
  onNextClick,
}) => {
  const introduceUser = useIntroduceUser();

  return (
    <div className="tournament">
      <CommonForm
        title="Step 1: Join a Tournament"
        subtitle="Search or browse all the tournaments from the “Lobby” and register for your favorite one."
        footer={
          <BottomNavigation
            cancelText="SKIP"
            cancelPath={Paths.LOBBY}
            onCancelAction={async () => {
              await introduceUser();
            }}
            onSubmit={onNextClick}
          />
        }
      >
        <div className="tournament__content">
          <OnboardingTournamentImageSVG />
        </div>
      </CommonForm>
    </div>
  );
};
