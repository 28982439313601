import { useEffect } from "react";

export const useScrollLock = (shouldScrollLock: boolean) => {
  useEffect(() => {
    if (shouldScrollLock) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [shouldScrollLock]);
};
