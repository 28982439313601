import { MyBetVariants, WagerGameStatuses } from "types/contest";
import { Wager } from "types/request";

type WagerVariantFields = Pick<Wager, "currentGameStatus" | "amountWon">;

export const getWagerVariant = (wager: WagerVariantFields) => {
  if (wager.currentGameStatus !== WagerGameStatuses.CLOSED) {
    return MyBetVariants.PENDING;
  }
  if (wager.amountWon === 0) {
    return MyBetVariants.LOST;
  }
  return MyBetVariants.WON;
};
