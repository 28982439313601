import { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import "./common-form.scss";

interface CommonFormProps extends Omit<HTMLAttributes<HTMLFormElement>, "title"> {
  title?: ReactNode;
  subtitle?: ReactNode;
  footer?: JSX.Element;
}

export const CommonForm: React.FC<CommonFormProps> = ({
  title,
  subtitle,
  children,
  footer,
  className,
  ...formProps
}) => {
  return (
    <form {...formProps} className="common-form" data-testid="form">
      <div className={clsx("common-form__wrapper", className)}>
        <div className="common-form__header">
          {title && <div className="common-form__title">{title}</div>}
          {subtitle && <div className="common-form__subtitle">{subtitle}</div>}
        </div>
        {children}
      </div>
      {footer && (
        <div className="common-form__footer" data-testid="bottom-nav">
          {footer}
        </div>
      )}
    </form>
  );
};
