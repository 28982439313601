import { useEffect, useMemo, useState } from "react";
import { REGEX_ANDROID, REGEX_APPLE_MOBILE } from "helpers/constants/regex";
import { StandaloneNavigator } from "types/navigator";

interface DeviceDetails {
  isIos: boolean;
  isAndroid: boolean;
  isStandalone: boolean;
}

export const useDetectDevice = () => {
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails>({
    isIos: false,
    isAndroid: false,
    isStandalone: false,
  });
  const userAgent = window.navigator.userAgent.toLocaleLowerCase();

  const isStandalone = useMemo(() => {
    const iosStandalone = (window.navigator as StandaloneNavigator).standalone;
    const androidStandalone = window.matchMedia("(display-mode: standalone)").matches;
    return iosStandalone || androidStandalone;
  }, []);

  useEffect(() => {
    setDeviceDetails({
      isIos: REGEX_APPLE_MOBILE.test(userAgent),
      isAndroid: REGEX_ANDROID.test(userAgent),
      isStandalone: !!isStandalone,
    });
  }, [isStandalone, userAgent]);

  return deviceDetails;
};
