import React, { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import clsx from "clsx";
import { Button } from "components/button";
import { ContestBar } from "components/contest";
import {
  CONTEST_PARAM,
  ContestsMobileSearchView,
} from "components/contests-mobile-search-view";
import { ContestsMobileSortView } from "components/contests-mobile-sort-view";
import { RouterNavbar } from "components/router-navbar";
import { ScrollWrapper } from "components/scroll-wrapper";
import { SearchBar } from "components/search-bar";
import {
  selectContests,
  selectContestsRequestState,
  selectSelectedContest,
  selectSortOption,
  setSelectedContest,
  setSortOption,
} from "store/app-slice";
import { selectMyContestRequestState } from "store/my-contest-slice";
import { sortContests } from "helpers/sort-contests";
import { uppercaseFirstLetter } from "helpers/uppercase-first-letter";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { useMissingContestFallback } from "hooks/use-missing-contest-fallback";
import { RequestState } from "types/request";
import { Paths, PathsLobby } from "types/router";
import { SortOptions } from "types/sort-contests";
import { UNSELECTED_PARAM } from "pages/lobby";
import "./lobby-contests.scss";

const LOBBY_DETAILS_NAV_TABS: string[] = [
  PathsLobby.DETAILS,
  PathsLobby.PRIZES,
  PathsLobby.GAMES,
  PathsLobby.RULES,
];

export const LobbyContests: React.FC = () => {
  const dispatch = useAppDispatch();
  const { sortOrder, sortField } = useAppSelector(selectSortOption);
  const { upcomingContests } = useAppSelector(selectContests);
  const requestLobbyContestsState = useAppSelector(selectContestsRequestState);
  const [contests, setContests] = useState(upcomingContests);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { category, contestId } = useParams<{
    category?: string;
    contestId?: string;
  }>();
  const isUnselected = contestId === UNSELECTED_PARAM;
  const [isInitContest, setIsInitContest] = useState(isUnselected);

  const selectedContest = useAppSelector(selectSelectedContest);
  const selectedContestId = selectedContest ? selectedContest.contest.id : null;
  const location = useLocation();
  const isMobile = useMediaQuery(MediaQueryBreakpoints.MOBILE);
  const isWideScreenAndContestNotChosen = !(isMobile && !isUnselected);
  const isWideScreenAndContestChosen = !(isMobile && isUnselected);
  const isSortView = location.pathname.includes(PathsLobby.SORT);
  const isSearchView = location.pathname.includes(PathsLobby.SEARCH);
  const lobbyContestsHidden = isSortView || isSearchView;

  const currentParam = searchParams.toString();
  const currentParamPathValue = currentParam ? `?${currentParam}` : "";
  const contestPathConverter = (id: number) =>
    `/lobby/${category}/${id}/details${currentParamPathValue}`;

  const detailsPathConverter = (details: string) =>
    `/lobby/${category}/${contestId}/${details}${currentParamPathValue}`;
  const requestState = useAppSelector(selectMyContestRequestState);
  useMissingContestFallback({
    contestList: upcomingContests,
    contestId: contestId || "",
    callback: () => {
      if (requestState === RequestState.SUCCESS) {
        navigate(Paths.LOBBY);
      }
    },
  });

  useEffect(() => {
    const contestParamValue = searchParams.get(CONTEST_PARAM);
    let contestToSort = upcomingContests;
    if (category !== "popular") {
      contestToSort = upcomingContests.filter(
        ({ contest }) =>
          contest.contestType.toLowerCase() ===
          (category === "multisport" ? "all" : category)
      );
    }
    const sortedContests = sortContests(
      contestToSort,
      sortOrder,
      sortField,
      contestParamValue
    );
    setContests(sortedContests);
    if (
      isInitContest &&
      sortedContests.length &&
      requestLobbyContestsState === RequestState.SUCCESS
    ) {
      dispatch(setSelectedContest(sortedContests[0]));
      setIsInitContest(false);
    }
  }, [
    category,
    upcomingContests,
    sortField,
    sortOrder,
    isInitContest,
    searchParams,
    dispatch,
    requestLobbyContestsState,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setSortOption(SortOptions[0]));
    };
  }, [dispatch]);

  useEffect(() => {
    const newSelectedContest = contests.find(
      ({ contest }) => contest.id === Number(contestId)
    );
    if (newSelectedContest) {
      dispatch(setSelectedContest(newSelectedContest));
    }
  }, [contestId, contests, dispatch]);

  const onContestClickHandler = (id: number) => {
    const newSelectedContest = contests.find(({ contest }) => contest.id === Number(id));
    if (newSelectedContest) {
      dispatch(setSelectedContest(newSelectedContest));
    }
    navigate(contestPathConverter(id));
  };

  return (
    <>
      <ContestsMobileSearchView show={isSearchView} contests={contests} />
      <ContestsMobileSortView show={isSortView} />
      <div
        className={clsx(
          "lobby-contests",
          lobbyContestsHidden && "lobby-contests--hidden"
        )}
      >
        {isWideScreenAndContestNotChosen && (
          <div className="lobby-contests__wrapper">
            <div className="lobby-contests__scroll-wrapper">
              <SearchBar
                mobileSortPath={PathsLobby.SORT_ABSOLUTE}
                mobileSearchPath={PathsLobby.SEARCH_ABSOLUTE}
              />
              <ScrollWrapper variant="dark" className="lobby-contests__contests-scroll">
                {contests.map((contestItem) => (
                  <Button
                    variant="clear"
                    key={contestItem.contest.id}
                    onClick={() => onContestClickHandler(contestItem.contest.id)}
                    className="lobby-contests__contest-bar"
                  >
                    <ContestBar
                      contestItem={contestItem}
                      active={selectedContestId === +contestItem.contest.id}
                      loading={[RequestState.PENDING, RequestState.IDLE].includes(
                        requestLobbyContestsState
                      )}
                    />
                  </Button>
                ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
        {isWideScreenAndContestChosen && (
          <div className="lobby-contests__tabs">
            <RouterNavbar
              navigationClassName="lobby-contests__navigation"
              tabs={LOBBY_DETAILS_NAV_TABS.map((label) => {
                return {
                  label: uppercaseFirstLetter(label),
                  url: detailsPathConverter(label),
                  uniqueStringPath: label,
                };
              })}
            />
            <Outlet />
          </div>
        )}
      </div>
    </>
  );
};
