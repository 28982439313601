import { userStatsMock } from "helpers/mocks/user-details-mock";
import axios from "utils/axios";
import { UserRequestPayload } from "types/auth";
import { UserStatsResponse } from "types/request";
import { UserResponse } from "types/user";

export const getUserDetails = async () => {
  const userResponse = await axios.get<UserResponse>("/UserRetrieval/GetUser", {
    params: { skipCache: true },
  });
  return userResponse.data;
};

export const getUserStats = (): UserStatsResponse => {
  // TODO add route for getting user details
  return userStatsMock;
};

export const updateUser = async (payload: UserRequestPayload) => {
  const { username: userName, ...restPayload } = payload;
  const userResponse = await axios.post<UserResponse>(
    "/UserModification/UpdateUser",
    {
      ...restPayload,
      userName,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return userResponse.data;
};

export const updateUserProfileImage = async (formData: FormData) => {
  const userResponse = await axios.post<UserResponse>(
    "UserModification/UpdateUserProfilePicture",
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return userResponse.data;
};

export const checkUsernameExists = async (username: string) => {
  const { data } = await axios.get<boolean>("UserRetrieval/DoesUsernameExist", {
    params: { username },
  });
  return data;
};
