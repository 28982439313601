import { useCallback, useEffect, useRef, useState } from "react";
import { BeforeInstallPromptEvent } from "types/install-prompt-event";
import { RelatedApp, StandaloneNavigator } from "types/navigator";

type PromptEvent = BeforeInstallPromptEvent | null;

interface PromptState {
  isPromptEventCalled: boolean;
  isAppInstalled: boolean;
  handleInstallPrompt: () => void;
}

export const useInstallationPrompt = (): PromptState => {
  const prompt = useRef<PromptEvent>(null);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [eventCalled, setEventCalled] = useState(false);

  const handleInstallPrompt = async () => {
    if (prompt.current) {
      prompt.current.prompt();
    } else {
      console.error("calling prompt without beforeinstallprompt event");
    }
  };

  const standaloneNavigator = navigator as StandaloneNavigator;

  const checkRelatedApps = useCallback(async () => {
    let relatedApps: RelatedApp[] = [];
    if (standaloneNavigator.getInstalledRelatedApps) {
      relatedApps = await standaloneNavigator.getInstalledRelatedApps();
    }
    setIsAppInstalled(relatedApps.length > 0);
  }, [standaloneNavigator]);

  useEffect(() => {
    const handlePrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      prompt.current = event;
      setEventCalled(true);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handlePrompt as (event: Event) => void
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handlePrompt as (event: Event) => void
      );
    };
  }, [checkRelatedApps]);

  useEffect(() => {
    checkRelatedApps();
  }, [checkRelatedApps]);

  return {
    isPromptEventCalled: eventCalled || isAppInstalled,
    isAppInstalled,
    handleInstallPrompt,
  };
};
