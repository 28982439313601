import { ContentWrapper } from "components/content-wrapper";
import { ImageLayout } from "components/image-layout";
import { ForgotPasswordContent } from "./forgot-password-content";
import "./forgot-password.scss";

export const ForgotPasswordPage: React.FC = () => {
  return (
    <div className="forgot-password">
      <ImageLayout />
      <ContentWrapper>
        <ForgotPasswordContent />
      </ContentWrapper>
    </div>
  );
};
