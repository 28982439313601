import clsx from "clsx";
import { Button } from "components/button";
import { CrossMarkCloseIcon } from "assets/icons";
import "./android-prompt.scss";

interface AndroidPromptProps {
  onClick: () => void;
  onDismiss: () => void;
  variant: "install" | "open";
  isLoggedUser?: boolean;
}

export const AndroidPrompt: React.FC<AndroidPromptProps> = ({
  onClick,
  onDismiss,
  variant,
  isLoggedUser,
}) => {
  return (
    <div
      data-testid="android-prompt"
      className={clsx("android-prompt", {
        "android-prompt--is-not-logged": !isLoggedUser,
      })}
    >
      <div
        className={clsx("android-prompt__text-wrapper", {
          "android-prompt__text-wrapper--is-not-logged": !isLoggedUser,
        })}
      >
        <img
          srcSet="images/logo-base.png, images/logo-base@2x.png 2x"
          src="images/logo-base.png"
          alt="logo"
          className={clsx("android-prompt__logo", {
            "android-prompt__logo--is-not-logged": !isLoggedUser,
          })}
        />
        {variant === "install" ? (
          <div className="android-prompt__text">
            <Button
              className={clsx("android-prompt__button", {
                "android-prompt__button--is-not-logged": !isLoggedUser,
              })}
              onClick={onClick}
              type="button"
              variant="tertiary"
            >
              Bet Max Action Install App
            </Button>
          </div>
        ) : (
          // Open PWA app if already installed
          <div className="android-prompt__text">
            <a
              className="android-prompt__pwa-link"
              href={window.location.href}
              target="_blank"
              rel="noreferrer"
            >
              Bet Max Action Open app
            </a>
          </div>
        )}
      </div>
      <Button
        data-testid="button-clear"
        className={clsx("android-prompt__cancel-button", {
          "android-prompt__cancel-button--is-not-logged": !isLoggedUser,
        })}
        variant="clear"
        onClick={onDismiss}
      >
        <CrossMarkCloseIcon />
      </Button>
    </div>
  );
};
