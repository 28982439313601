import { MyContestItem, MyContestResponse } from "types/request";

export const mapMyContestsResponse = (
  contestResponse: MyContestResponse[]
): MyContestItem[] => {
  return contestResponse.map((contest) => {
    return {
      contestId: contest.ContestId,
      currentPlaceByUnits: contest.CurrentPlaceByUnits,
      currentPlaceByWlt: contest.CurrentPlaceByWlt,
      currentPlaceByBudget: contest.CurrentPlaceByBudget,
      percentileByUnits: contest.PercentileByUnits,
      percentileByWlt: contest.PercentileByWlt,
      picksCompleted: contest.PicksCompleted,
      remainingBudget: contest.RemainingBudget,
      units: contest.Units,
      userId: contest.UserId,
      userName: contest.UserName,
      pictureUri: contest.PictureUri,
      wltPercent: 0,
    };
  });
};
