import {
  FocusEvent,
  FormEvent,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEvent,
  MouseEvent,
} from "react";
import clsx from "clsx";
import { moveCursorByEvent } from "helpers/move-cursor";
import "./code-input.scss";

interface CodeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onLeftArrowClick?: () => void;
  onRightArrowClick?: () => void;
  replaceMode?: boolean;
}

export const CodeInput = forwardRef<HTMLInputElement, CodeInputProps>(
  (
    {
      onLeftArrowClick,
      onRightArrowClick,
      replaceMode,
      maxLength = 1,
      className,
      ...restProps
    },
    ref
  ) => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" && onLeftArrowClick) {
        event.preventDefault();
        onLeftArrowClick();
      } else if (event.key === "ArrowRight" && onRightArrowClick) {
        onRightArrowClick();
      }
    };

    const handleMaxLengthCheck = (event: FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      if (value.length > maxLength) {
        event.currentTarget.value = replaceMode
          ? value.slice(1)
          : value.slice(0, maxLength);
      }
    };

    const placeCursor = (
      event: FocusEvent<HTMLInputElement, Element> | MouseEvent<HTMLInputElement>
    ) => {
      moveCursorByEvent(event, replaceMode ? "end" : "start");
    };

    return (
      <input
        data-testid="code-input"
        className={clsx("code-input", className)}
        type="text"
        inputMode="numeric"
        ref={ref}
        onKeyDown={handleKeydown}
        onInput={handleMaxLengthCheck}
        onClick={placeCursor}
        maxLength={replaceMode ? maxLength + 1 : maxLength}
        onFocus={placeCursor}
        {...restProps}
      />
    );
  }
);

CodeInput.displayName = "CodeInput";
