import clsx from "clsx";
import { formatToCurrency } from "helpers/format-to-currency";
import { formatToPosition } from "helpers/format-to-position";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { PersonFilledIcon } from "assets/icons";
import { ArrowDownIcon } from "assets/icons/arrow-down-icon";
import "./leaderboard-users-card.scss";

interface LeaderboardUsersCardProps {
  userName: string;
  pictureUri: string;
  currentPlaceByBudget: number;
  remainingBudget: number;
  onClick: () => void;
  isLoggedUser?: boolean;
  isDisabled?: boolean;
  activeAccordion: null | boolean;
}

export const LeaderboardUsersCard: React.FC<LeaderboardUsersCardProps> = ({
  activeAccordion,
  currentPlaceByBudget,
  remainingBudget,
  userName,
  pictureUri,
  onClick,
  isLoggedUser,
  isDisabled,
}) => {
  const isMobile = useMediaQuery(MediaQueryBreakpoints.MOBILE);

  return (
    <button
      onClick={onClick}
      disabled={isDisabled || (isLoggedUser && isMobile)}
      className={clsx("leaderboard-users-card", {
        "leaderboard-users-card--current-user": isLoggedUser,
      })}
    >
      <div className="leaderboard-users-card__wrapper">
        <div className="leaderboard-users-card__standing-wrapper">
          <span className="leaderboard-users-card__standing">
            {formatToPosition(currentPlaceByBudget)}
          </span>
          <span className="leaderboard-users-card__standing-label">PLACE</span>
        </div>
        <div className="leaderboard-users-card__user">
          {pictureUri ? (
            <img
              src={pictureUri}
              alt="profile"
              className="leaderboard-users-card__user-image"
            />
          ) : (
            <PersonFilledIcon className="leaderboard-users-card__user-image" />
          )}
          <div className="leaderboard-users-card__user-details">
            <span className="leaderboard-users-card__username">{`@${userName}`}</span>
          </div>
        </div>
      </div>
      <div className="leaderboard-users-card__wrapper">
        <div className="leaderboard-users-card__points-wrapper">
          <span className="leaderboard-users-card__points">
            {formatToCurrency({ value: Math.round(remainingBudget), withDollar: false })}
          </span>
          <span className="leaderboard-users-card__points-label">TD</span>
        </div>
        <ArrowDownIcon
          className={clsx("leaderboard-users-card__arrow", {
            "leaderboard-users-card__arrow--rotated": activeAccordion,
          })}
        />
      </div>
    </button>
  );
};
