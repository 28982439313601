import { forwardRef } from "react";
import clsx from "clsx";
import { InputProps } from "components/inputs";
import "./radio-button.scss";

export const RadioButton = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...restProps }, ref) => {
    return (
      <div className="radio-button">
        <input
          {...restProps}
          className={clsx("radio-button__input", className)}
          type="radio"
          ref={ref}
        />
      </div>
    );
  }
);

RadioButton.displayName = "RadioButton";
