import { ReactNode } from "react";
import { LoaderSpinner } from "components/loader-spinner";
import { selectProfileImageRequestState } from "store/auth";
import { useAppSelector } from "hooks/store";
import { RequestState } from "types/request";
import "./avatar-provider.scss";

interface AvatarProviderProps {
  children: ReactNode;
}

export const AvatarProvider: React.FC<AvatarProviderProps> = ({ children }) => {
  const requestState = useAppSelector(selectProfileImageRequestState);

  return (
    <>
      {[RequestState.PENDING, RequestState.IDLE].includes(requestState) ? (
        <div className="avatar-provider">
          <LoaderSpinner size="small" />
        </div>
      ) : (
        children
      )}
    </>
  );
};
