import { Navigate } from "react-router-dom";
import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { Paths } from "types/router";
import { AdminPanelContent } from "./admin-panel-content";
import "./admin-panel.scss";

export const AdminPanelPage: React.FC = () => {
  const { roles } = useAppSelector(selectAuthPayload);

  if (!roles.includes("Admin")) {
    return <Navigate to={Paths.LOBBY} />;
  }

  return (
    <div className="admin-panel">
      <AdminPanelContent />
    </div>
  );
};
