import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  OnboardingBudget,
  OnboardingFinal,
  OnboardingTournament,
  OnboardingWelcome,
} from "components/onboarding";
import { Stepper } from "components/stepper";
import { useIntroduceUser } from "hooks/use-introduce-user";
import { useLobbyOrOnboardingRedirect } from "hooks/use-lobby-or-onboarding-redirect";
import { Paths } from "types/router";
import "./onboarding.scss";

enum OnboardingStep {
  Tournament,
  Budget,
  Final,
}

export const Onboarding: React.FC = () => {
  useLobbyOrOnboardingRedirect();
  const [isStarted, setIsStarted] = useState(false);
  const [activeStep, setActiveStep] = useState(OnboardingStep.Tournament);

  const navigate = useNavigate();
  const introduceUser = useIntroduceUser();

  let element: ReactNode;

  const handleFinalStep = async () => {
    await introduceUser();
    navigate(Paths.LOBBY);
  };

  switch (activeStep) {
    case OnboardingStep.Tournament:
      element = (
        <OnboardingTournament onNextClick={() => setActiveStep(OnboardingStep.Budget)} />
      );
      break;
    case OnboardingStep.Budget:
      element = (
        <OnboardingBudget onNextClick={() => setActiveStep(OnboardingStep.Final)} />
      );
      break;
    case OnboardingStep.Final:
      element = <OnboardingFinal onNextClick={() => handleFinalStep()} />;
      break;
    default:
      element = null;
      break;
  }

  return (
    <div className="onboarding">
      <div className="onboarding__wrapper">
        <div className="onboarding__content">
          {isStarted ? (
            <Stepper activeStep={activeStep} numberOfSteps={3} element={element} />
          ) : (
            <OnboardingWelcome onNextClick={() => setIsStarted(true)} />
          )}
        </div>
      </div>
    </div>
  );
};
