import { SVGProps } from "react";

export const OnlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox=" 0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="m1 9 2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8 3 3 3-3a4.237 4.237 0 0 0-6 0zm-4-4 2 2a7.074 7.074 0 0 1 10 0l2-2C15.14 9.14 8.87 9.14 5 13z"
      fill="currentColor"
    />
  </svg>
);
