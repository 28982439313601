import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { Input, InputProps } from "components/inputs";
import { SearchIcon } from "assets/icons";
import "./search-input.scss";

interface SearchInputProps extends InputProps {
  variant?: "clean" | "bar";
  paramName: string;
}
export const SearchInput: React.FC<SearchInputProps> = ({
  paramName,
  variant = "clean",
  className,
  ...restProps
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const inputChangeHandler = (value: string) => {
    const params = new URLSearchParams();
    if (value) {
      params.append(paramName, value);
    } else {
      params.delete(paramName);
    }
    setSearchParams(params);
  };

  return (
    <Input
      value={searchParams.get(paramName) || ""}
      icon={SearchIcon}
      className={clsx("search-input", `search-input--${variant}`, className)}
      onChange={(e) => inputChangeHandler(e.target.value)}
      {...restProps}
    />
  );
};
