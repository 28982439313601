import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";
import { formatToCurrency } from "helpers/format-to-currency";
import { getCssVariable } from "helpers/get-css-variable";
import { CategoryChartType } from "types/request";
import { ArrowIcon } from "assets/icons/arrow-icon";
import "./linear-chart.scss";

const AREA_CHART_WIDTH = 300;
const AREA_CHART_HEIGHT = 200;
const ARROW_ICON_SIZE = 17;
const AREA_OPACITY_START = 0.8;
const AREA_OPACITY_END = 0;

export const LinearChart: React.FC<CategoryChartType> = ({
  chartPoints,
  chartCategory,
  income,
  percentageIncome,
}) => {
  return (
    <div className="linear-chart">
      <AreaChart
        width={AREA_CHART_WIDTH}
        height={AREA_CHART_HEIGHT}
        data={chartPoints}
        margin={{ right: 30, top: 55, left: -10 }}
      >
        <defs>
          <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={getCssVariable("--chart-color-linear")}
              stopOpacity={AREA_OPACITY_START}
            />
            <stop
              offset="95%"
              stopColor={getCssVariable("--chart-color-linear")}
              stopOpacity={AREA_OPACITY_END}
            />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" axisLine={false} tickLine={false} />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip />

        <Area
          type="monotone"
          dataKey="value"
          stroke={getCssVariable("--chart-color-linear")}
          fillOpacity={1}
          fill="url(#gradientColor)"
        />
      </AreaChart>
      <div className="linear-chart__title">
        <div className="linear-chart__percentage">
          {`+${percentageIncome}%`}
          <ArrowIcon
            transform="rotate(90)"
            height={ARROW_ICON_SIZE}
            width={ARROW_ICON_SIZE}
          />
        </div>
        <div>
          <div className="linear-chart__wins-title">{`${chartCategory} WINS`}</div>
          <div className="linear-chart__income">
            {formatToCurrency({ value: income, withDollar: true })}
          </div>
        </div>
      </div>
    </div>
  );
};
