import { Link } from "react-router-dom";
import { ContestBar } from "components/contest/contest-bar";
import { getContestUrl } from "helpers/get-contest-url";
import { MyContestSmTab } from "types/contest";
import { ContestsItemType } from "types/request";
import "./section-items.scss";

interface SectionItemsProps {
  contests: ContestsItemType[];
  showHeaderOnSm?: boolean;
  wide?: boolean;
}

export const SectionItems: React.FC<SectionItemsProps> = ({ contests, wide = false }) => {
  return (
    <div className="section-items">
      {contests.map((contestItem) => {
        const { contest } = contestItem;
        return (
          <Link
            className="section-items__link"
            to={getContestUrl(contest.status, contest.id, MyContestSmTab.SLATE)}
            key={contest.id}
          >
            <ContestBar contestItem={contestItem} wide={wide} />
          </Link>
        );
      })}
    </div>
  );
};
