import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { getGames } from "services/my-contests-service";
import { Button } from "components/button";
import { LabeledInput } from "components/inputs";
import { LoaderSpinner } from "components/loader-spinner";
import { Select } from "components/select";
import { addDayToDateBoundaries } from "helpers/add-day-to-date-boundaries";
import { DateFormats, ERROR_ADMIN_PANEL } from "helpers/constants";
import { getDaysBetweenDates } from "helpers/getDaysBetweenDates";
import { validateGame } from "helpers/validate-game";
import { useGetCategories } from "hooks/use-get-categories";
import { GetGamesResponse, Sport } from "types/contest";
import { AdminPanelCreateForm } from "pages/admin-panel/admin-panel-create-form";
import "./admin-panel-content.scss";

interface AdminPanelInputs {
  contestName: string;
  sport: string;
  startDate: string;
  endDate: string;
}

export const AdminPanelContent = () => {
  const todaysDate = dayjs().format(DateFormats.DATE);
  const nextMonthDate = dayjs().add(1, "M").format(DateFormats.DATE);
  const {
    handleSubmit,
    control,
    register,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<AdminPanelInputs>({
    defaultValues: {
      contestName: "",
      sport: Sport.MLB,
      startDate: todaysDate,
      endDate: nextMonthDate,
    },
    mode: "onTouched",
  });
  const [games, setGames] = useState<GetGamesResponse[]>([]);

  const dateOfStart = watch("startDate");
  const dateOfEnd = watch("endDate");

  const { isLoading, categories } = useGetCategories(dateOfStart, dateOfEnd);

  useEffect(() => {
    if (categories.length) {
      const prevValue = getValues("sport");
      if (!categories.some((category) => category.value === prevValue)) {
        setValue("sport", categories[0].value);
      }
    }
  }, [categories, getValues, setValue]);

  const onSubmit = async ({ startDate, endDate, sport }: AdminPanelInputs) => {
    if (startDate < todaysDate || endDate < todaysDate) {
      toast.error("Invalid set of dates");
      return;
    }
    const days = getDaysBetweenDates(startDate, endDate);
    if (days >= 0) {
      const { usersStartDate, usersEndDate } = addDayToDateBoundaries(startDate, endDate);
      const daysOfExpandedSetOfDates = getDaysBetweenDates(usersStartDate, usersEndDate);
      const response = await getGames({
        gameDate: usersStartDate,
        sport: sport,
        days: daysOfExpandedSetOfDates,
      });
      const filteredResponse = (response ?? []).filter((game) =>
        validateGame(game, startDate, endDate)
      );

      if (filteredResponse?.length) {
        setGames(filteredResponse);
      } else {
        toast.error("No games to load");
      }
    } else {
      toast.error("Invalid set of dates");
    }
  };

  return (
    <div className="admin-panel-content">
      {!!games.length ? (
        <AdminPanelCreateForm
          games={games}
          contestType={getValues("sport")}
          onCancel={() => setGames([])}
          onCreated={() => {
            setGames([]);
            reset();
          }}
        />
      ) : (
        <form className="admin-panel-content__form" onSubmit={handleSubmit(onSubmit)}>
          <LabeledInput
            labelText="Start date of games"
            type="date"
            min={todaysDate}
            {...register("startDate", {
              required: ERROR_ADMIN_PANEL,
            })}
          />
          <LabeledInput
            labelText="End date of games"
            type="date"
            min={dateOfStart}
            {...register("endDate", {
              required: ERROR_ADMIN_PANEL,
            })}
          />
          <div className="admin-panel-content__input-wrapper">
            <label className="profile-details__label">Contest type</label>
            <Controller
              control={control}
              name="sport"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={(selectedOption) => onChange(selectedOption.value)}
                  options={categories}
                  noOptionsMessage="No games available"
                  disabled={!categories.length}
                />
              )}
            />
          </div>
          {isLoading ? (
            <div className="admin-panel-content__loader-wrapper">
              <LoaderSpinner size="tiny" />
            </div>
          ) : (
            <Button
              variant="primary-rounded"
              className="admin-panel-content__submit"
              type="submit"
              disabled={!!Object.values(errors).length}
            >
              Find
            </Button>
          )}
        </form>
      )}
    </div>
  );
};
