import React from "react";
import clsx from "clsx";
import { ContestSection } from "components/contest";
import { ContestSectionContent } from "components/contest/contest-section/contest-section-content";
import { selectMyContests } from "store/my-contest-slice";
import { useAppSelector } from "hooks/store";
import { ContestsItemType } from "types/request";
import "./profile-contests-view.scss";

interface ContestsViewProps {
  showTitleOnSm?: boolean;
  className?: string;
  wide?: boolean;
}

export const ContestsView: React.FC<ContestsViewProps> = ({
  showTitleOnSm,
  className,
  wide = false,
}) => {
  const { inProgressContests, previousContests } = useAppSelector(selectMyContests);

  const sections: { contests: ContestsItemType[]; title: string }[] = [
    {
      contests: inProgressContests,
      title: "Current",
    },
    {
      contests: previousContests,
      title: "Previous",
    },
  ];

  return (
    <div
      data-testid="contests-view"
      className={clsx("contests-view__tab-content-wrapper", className)}
    >
      <div className="contests-view__tab-content">
        {sections.map(({ title, contests }) => (
          <ContestSection title={title} key={title} showHeaderOnSm={showTitleOnSm}>
            <ContestSectionContent contests={contests} wide={wide} />
          </ContestSection>
        ))}
      </div>
    </div>
  );
};
