import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { fetchContests } from "store/app-slice";
import { setIsIntroduced, setRequestState } from "store/auth";
import { BooleanValue } from "types/boolean";
import { RequestState } from "types/request";
import { UserFields } from "types/user";
import { useAppDispatch } from "./store";

export const useIntroduceUser = () => {
  const dispatch = useAppDispatch();

  const handleError = (message: string) => {
    dispatch(setRequestState(RequestState.ERROR));
    toast.error(message);
  };

  const introduceUser = async () => {
    dispatch(fetchContests());
    dispatch(setRequestState(RequestState.PENDING));
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.updateUserAttributes(user, {
          [UserFields.IS_INTRODUCED]: BooleanValue.YES,
        })
          .then(() => {
            dispatch(setIsIntroduced(true));
            dispatch(setRequestState(RequestState.SUCCESS));
          })
          .catch((error) => handleError(error.message));
      })
      .catch((error) => handleError(error.message));
  };

  return introduceUser;
};
