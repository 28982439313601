import { OnboardingPersonSVG } from "assets/onboarding-person";
import { OnboardingWelcomeBoardSVG } from "assets/onboarding-welcome-board";
import { OnboardingWelcomeLogoSVG } from "assets/onboarding-welcome-logo";
import "./onboarding-welcome.scss";

interface OnboardingWelcomeProps {
  onNextClick: () => void;
}

export const OnboardingWelcome: React.FC<OnboardingWelcomeProps> = ({ onNextClick }) => {
  return (
    <div className="welcome">
      <div>
        <div className="welcome__title">Welcome</div>
        <div className="welcome__subtitle">
          Thanks for joining BMA, the worlds first Free-to-Play Daily Betting Contest
          Platform! Click below to learn how to play!
        </div>
      </div>
      <div className="welcome__content">
        <OnboardingWelcomeBoardSVG className="welcome__board" />
        <OnboardingWelcomeLogoSVG className="welcome__logo" />
        <OnboardingPersonSVG className="welcome__person" />
      </div>
      <button onClick={onNextClick} className="welcome__button">
        GET STARTED
      </button>
    </div>
  );
};
