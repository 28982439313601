import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { checkUsernameExists } from "services/user-service";
import { BottomNavigation } from "components/bottom-navigation";
import { Button } from "components/button";
import { ChangePassword } from "components/change-password";
import { CommonForm } from "components/common-form";
import { CONTACT_OPTIONS } from "components/contact-info";
import { PhoneInput } from "components/inputs/phone-input";
import { ProfileLabeledInput } from "components/profile-details/profile-labeled-input";
import { ProfileImageSelector } from "components/profile-image-selector";
import { Select } from "components/select";
import {
  ERROR_EMAIL_EMPTY,
  ERROR_EMAIL_INVALID,
  ERROR_INVALID,
  ERROR_MAX_LENGTH,
  ERROR_MAX_LENGTH_USERNAME,
  ERROR_MIN_LENGTH,
  ERROR_REQUIRED,
  ERROR_USERNAME_PATTERN,
  REGEX_EMAIL,
  REGEX_FIRST_AND_LAST_NAME,
  REGEX_PHONE_NUMBER,
  REGEX_USERNAME,
} from "helpers/constants";
import { validatePhoneNumber } from "helpers/validate-phone-number";
import { EditProfileTextInputs } from "types/profile-details";
import { Paths } from "types/router";
import { PreferredContact } from "types/user";
import "./profile-details.scss";

interface ProfileDetailsInputs extends EditProfileTextInputs {
  profileImage: File | null;
}

interface ProfileDetailsProps {
  onNextClicked: () => void;
  onProfileImageChange?: (profileImage: File | null) => void;
  onUserDataChange: (data: EditProfileTextInputs) => void;
  cancelPath: Paths;
  initialState?: ProfileDetailsInputs;
  className?: string;
  isEditProfile?: boolean;
}

export const ProfileDetails: React.FC<ProfileDetailsProps> = ({
  onNextClicked,
  onProfileImageChange,
  onUserDataChange,
  cancelPath,
  initialState,
  className,
  isEditProfile,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ProfileDetailsInputs>({
    defaultValues: initialState || {
      username: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      profileImage: null,
      preferredContact: PreferredContact.EMAIL,
    },
    mode: "onTouched",
  });

  const [isChangePasswordActive, setIsChangePasswordActive] = useState(false);

  const onSubmit = async (
    data: ProfileDetailsInputs,
    event?: React.BaseSyntheticEvent<object, unknown, unknown>
  ) => {
    if (event) {
      event.preventDefault();
    }
    const { profileImage, ...restData } = data;
    if (!isEditProfile) {
      const usernameExists = await checkUsernameExists(data.username);
      if (usernameExists) {
        setError("username", {
          message: "This username already exists",
        });
        return;
      }
    }

    onUserDataChange(restData);
    if (onProfileImageChange) {
      onProfileImageChange(profileImage);
    }
    onNextClicked();
  };

  const isError = (key: keyof ProfileDetailsInputs) => {
    return errors[key] !== undefined;
  };

  return (
    <div className={clsx("profile-details", className)}>
      <CommonForm
        title="Your Profile"
        subtitle="Your username will appear on the leader board to your opponents, not your full name"
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <BottomNavigation
            nextButtonActive={!Object.values(errors).length}
            isSubmitType
            cancelPath={cancelPath}
            cancelText="CANCEL"
            submitText={isEditProfile ? "SAVE" : "NEXT"}
          />
        }
      >
        <div className="profile-details__controls">
          <div
            className={clsx("profile-details__section", "profile-details__section--row")}
          >
            {isEditProfile && (
              <Controller
                control={control}
                name="profileImage"
                render={({ field: { onChange, value } }) => (
                  <ProfileImageSelector
                    onImageChange={onChange}
                    onDeleteImage={() => onChange(null)}
                    value={value}
                  />
                )}
              />
            )}
            <ProfileLabeledInput
              className="profile-details__username-input"
              disabled={isEditProfile}
              labelText="Username"
              error={isError("username")}
              errorText={errors.username?.message}
              isLongError={errors.username?.message === ERROR_USERNAME_PATTERN}
              {...register("username", {
                minLength: {
                  value: 5,
                  message: ERROR_MIN_LENGTH,
                },
                maxLength: {
                  value: 30,
                  message: ERROR_MAX_LENGTH_USERNAME,
                },
                required: {
                  value: true,
                  message: ERROR_REQUIRED,
                },
                pattern: {
                  value: REGEX_USERNAME,
                  message: ERROR_USERNAME_PATTERN,
                },
              })}
            />
          </div>
          <div className="profile-details__section">
            <ProfileLabeledInput
              labelText="Name"
              error={isError("firstName")}
              errorText={errors.firstName?.message}
              {...register("firstName", {
                required: {
                  value: true,
                  message: ERROR_REQUIRED,
                },
                maxLength: {
                  value: 255,
                  message: ERROR_MAX_LENGTH,
                },
                pattern: {
                  value: REGEX_FIRST_AND_LAST_NAME,
                  message: ERROR_INVALID,
                },
              })}
            />
            <ProfileLabeledInput
              labelText="Lastname"
              error={isError("lastName")}
              errorText={errors.lastName?.message}
              {...register("lastName", {
                required: {
                  value: true,
                  message: ERROR_REQUIRED,
                },
                maxLength: {
                  value: 255,
                  message: ERROR_MAX_LENGTH,
                },
                pattern: {
                  value: REGEX_FIRST_AND_LAST_NAME,
                  message: ERROR_INVALID,
                },
              })}
            />
          </div>
          {isEditProfile && (
            <div className="profile-details__section">
              <div className="profile-details__labeled-input">
                <label className="profile-details__label">Phone</label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      onChange={onChange}
                      value={value}
                      error={!!errors.phoneNumber}
                      errorText={errors.phoneNumber?.message}
                      className={"profile-details__phone-input"}
                    />
                  )}
                  rules={{
                    required: false,
                    pattern: {
                      value: REGEX_PHONE_NUMBER,
                      message: ERROR_INVALID,
                    },
                    validate: validatePhoneNumber,
                  }}
                />
              </div>
              <ProfileLabeledInput
                labelText="Email"
                placeholder="Email"
                disabled
                {...register("email", {
                  required: ERROR_EMAIL_EMPTY,
                  pattern: {
                    value: REGEX_EMAIL,
                    message: ERROR_EMAIL_INVALID,
                  },
                })}
                error={!!errors.email}
                errorText={errors.email?.message}
                autoComplete="email"
              />
              <div className="profile-details__labeled-input">
                <label className="profile-details__label">Prefer contact method</label>
                <Controller
                  control={control}
                  name="preferredContact"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(selectedOption) => onChange(selectedOption.value)}
                      options={CONTACT_OPTIONS}
                    />
                  )}
                />
              </div>
              <Button
                className="profile-details__change-password-button"
                variant="link"
                fontSize="sm-regular"
                type="button"
                onClick={() => setIsChangePasswordActive(true)}
              >
                CHANGE YOUR ACCOUNT PASSWORD
              </Button>
            </div>
          )}
        </div>
      </CommonForm>
      <div
        className={clsx("profile-details__change-password-wrapper", {
          "profile-details__change-password-wrapper--inactive": !isChangePasswordActive,
        })}
      >
        <ChangePassword onClose={() => setIsChangePasswordActive(false)} />
      </div>
    </div>
  );
};
