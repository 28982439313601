import { SVGProps } from "react";

export const OnboardingTournamentImageSVG = ({
  ...restProps
}: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={346.192} height={376.96} {...restProps}>
    <g data-name="Group 265">
      <g data-name="Group 245">
        <g data-name="Group 236" transform="translate(94.159 195.516)">
          <path
            data-name="Path 680"
            d="m58.873 95.429-10.244 84.923H45.27l10.243-84.923Z"
            fill="#d2dcff"
          />
          <path
            data-name="Path 681"
            d="m13.603 95.428-1.152 9.551-9.091 75.373H0l9.136-75.746 1.107-9.177Z"
            fill="#d2dcff"
          />
          <path
            data-name="Path 682"
            d="M58.873 180.351h-3.36l-1.416-11.745-1.68-13.926-5.525-45.807-1.622-13.445h3.36l1.668 13.83 3.8 31.495 1.68 13.926Z"
            fill="#d2dcff"
          />
          <path
            data-name="Path 683"
            d="M104.137 180.351h-3.36l-7.95-65.915-2.292-19.008h3.36l2.337 19.375Z"
            fill="#d2dcff"
          />
          <g data-name="Group 235" fill="#d2dcff">
            <path data-name="Rectangle 240" d="M10.407 38.659h3.36v51.37h-3.36z" />
            <path data-name="Rectangle 241" d="M56.059 38.659h3.359v51.37h-3.359z" />
          </g>
          <path
            data-name="Path 684"
            d="M95.304 90.029H49.145a2.7 2.7 0 0 1 2.7 2.7 2.7 2.7 0 0 1-2.7 2.7h46.159a2.7 2.7 0 0 0 2.7-2.7 2.7 2.7 0 0 0-2.7-2.7Z"
            fill="#d2dcff"
          />
          <path
            data-name="Path 685"
            d="M51.845 92.729a2.7 2.7 0 0 0-2.7-2.7H9.291a2.7 2.7 0 0 0-2.7 2.7 2.7 2.7 0 0 0 2.7 2.7h39.854a2.7 2.7 0 0 0 2.7-2.7Z"
            fill="#86a2e2"
          />
          <rect
            data-name="Rectangle 242"
            width={62.026}
            height={38.659}
            rx={3.763}
            transform="translate(3.9)"
            fill="#d2dcff"
          />
          <path
            data-name="Path 686"
            d="m13.603 95.428-1.152 9.551-3.314-.373 1.106-9.178Z"
            fill="#aec3ff"
          />
          <path
            data-name="Path 687"
            d="m48.628 95.428 1.668 13.83-3.407-.384-1.621-13.446Z"
            fill="#aec3ff"
          />
          <path
            data-name="Path 688"
            d="M96.231 114.808c-1.084-.116-2.221-.238-3.4-.367l-2.293-19.008h3.36Z"
            fill="#aec3ff"
          />
          <path
            data-name="Path 689"
            d="M10.407 38.659h3.36v7.1l-3.36-.372Z"
            fill="#aec3ff"
          />
          <path
            data-name="Path 690"
            d="M59.418 38.659v12.149l-3.359-.372V38.659Z"
            fill="#aec3ff"
          />
        </g>
        <g data-name="Group 244" transform="translate(102.787 127.73)">
          <g data-name="Group 242" transform="translate(24.664)">
            <g data-name="Group 237">
              <path
                data-name="Path 691"
                d="M44.36 20.537c-13.09 2.182-29.4-2.69-31.445-5.792-4.74 7.222-7.147 7-7.147 7v5.416l-1.07-1.07c-.33-.747-.854-1.487-1.638-1.487-4.31 0-4.612 11.988 2.643 12.681.143.47.29.909.441 1.306v19.188h16.362v-7.547a11.911 11.911 0 0 0 3.122.476c6.451 0 17.444-9.486 18.69-24.617.075-2.86.042-5.554.042-5.554Z"
                fill="#fcbeb7"
              />
            </g>
            <path
              data-name="Path 692"
              d="M24.5 0C13.216 0 10.959 6.018 10.959 6.018c-4.815 0-12.187 8.8-7.749 18.582l.023.022 2.535 2.535v-5.416s2.407.226 7.147-7c2.044 3.1 18.355 7.974 31.445 5.792 0 0 .033 2.694-.042 5.55v.011C49.208 11.952 39.395 0 24.5 0Z"
              fill="#311643"
            />
            <g data-name="Group 238">
              <path
                data-name="Path 693"
                d="M19.647 30.217a.9.9 0 0 1-.9-.9V27.63a.902.902 0 0 1 1.805 0v1.687a.9.9 0 0 1-.905.9Z"
                fill="#311643"
              />
            </g>
            <g data-name="Group 239">
              <path
                data-name="Path 694"
                d="M36.381 30.217a.9.9 0 0 1-.9-.9V27.63a.9.9 0 0 1 1.8 0v1.687a.9.9 0 0 1-.9.9Z"
                fill="#311643"
              />
            </g>
            <g data-name="Group 240">
              <path
                data-name="Path 695"
                d="M26.282 40.755a5.163 5.163 0 0 1-3.373-1.158 4.618 4.618 0 0 1-1.2-1.487.414.414 0 0 1 .754-.342 4.218 4.218 0 0 0 4.516 2.118.414.414 0 0 1 .1.822 6.825 6.825 0 0 1-.797.047Z"
                fill="#311643"
              />
            </g>
            <circle
              data-name="Ellipse 84"
              cx={4.464}
              cy={4.464}
              r={4.464}
              transform="translate(9.191 28.775)"
              fill="#f49795"
            />
            <path
              data-name="Path 696"
              d="M43.854 29.532a31.792 31.792 0 0 1-2.712 8.164 4.463 4.463 0 1 1 2.712-8.164Z"
              fill="#f49795"
            />
            <g data-name="Group 241" transform="translate(13.322 19.663)">
              <circle
                data-name="Ellipse 85"
                cx={5.498}
                cy={5.498}
                r={5.498}
                transform="translate(.828 .827)"
                fill="none"
              />
              <circle
                data-name="Ellipse 86"
                cx={5.498}
                cy={5.498}
                r={5.498}
                transform="rotate(-9.25 19.499 -103.14)"
                fill="none"
              />
              <path
                data-name="Path 697"
                d="M23.059 0a6.334 6.334 0 0 0-6.289 5.648 4.862 4.862 0 0 0-4.161 0 6.335 6.335 0 1 0 .031.914 4.131 4.131 0 0 1 4.1 0 6.325 6.325 0 1 0 6.32-6.564ZM6.32 11.823a5.5 5.5 0 1 1 5.5-5.5 5.5 5.5 0 0 1-5.495 5.5Zm16.739 0a5.5 5.5 0 1 1 5.5-5.5 5.5 5.5 0 0 1-5.5 5.5Z"
                fill="#fff"
              />
            </g>
            <path
              data-name="Path 698"
              d="M22.506 57.775v-7.547a36.411 36.411 0 0 1-11.078-5.883Z"
              fill="#f9afab"
            />
          </g>
          <path
            data-name="Path 699"
            d="m93.658 186.785-3.416-.468-3.235-26.822h3.36Z"
            fill="#ebedf4"
          />
          <path
            data-name="Path 701"
            d="m84.585 84.286 2.967 5.688H71.738Z"
            fill="#fcbeb7"
          />
          <path
            data-name="Path 702"
            d="M54.295 233.306v12.246a2.586 2.586 0 0 0 2.586 2.586h34.734a.6.6 0 0 0 .6-.6v-2.257a2.782 2.782 0 0 0-1.626-2.532l-20.084-9.445Z"
            fill="#2f3d6c"
          />
          <path
            data-name="Path 703"
            d="m54.295 226.339.008 6.967h16.2l1.12-6.967Z"
            fill="#fcbeb7"
          />
          <path
            data-name="Path 704"
            d="m47.145 50.658 21.993 9.934a14.42 14.42 0 0 1 6.967 6.7l8.483 16.992-12.847 5.688 1.064 37.561H8.785l2.795-35.08-11.577.075V73.335a14.735 14.735 0 0 1 9.057-13.6l21.752-9.082s9.499 6.241 16.333.005Z"
            fill="#89aeff"
          />
          <g data-name="Group 243">
            <path
              data-name="Path 705"
              d="M51.723 52.646a18.528 18.528 0 0 1-11.713 4.127 19.052 19.052 0 0 1-12.439-4.764l-.7.293a19.873 19.873 0 0 0 13.139 5.133 19.189 19.189 0 0 0 12.4-4.493Z"
              fill="#fff"
            />
          </g>
          <path
            data-name="Path 706"
            d="M88.381 233.306v12.246a2.586 2.586 0 0 0 2.586 2.586h34.734a.6.6 0 0 0 .6-.6v-2.257a2.783 2.783 0 0 0-1.625-2.532l-20.084-9.445Z"
            fill="#2f3d6c"
          />
          <path
            data-name="Path 707"
            d="m88.381 226.339.009 6.967h16.2l1.12-6.967Z"
            fill="#fcbeb7"
          />
          <path
            data-name="Path 708"
            d="M42.866 127.535v.654a29.771 29.771 0 0 0 29.771 29.794h13.831l1.913 68.356h17.329l12.777-81.5a14.981 14.981 0 0 0-14.8-17.3Z"
            fill="#6277d7"
          />
          <path
            data-name="Path 709"
            d="M118.671 142.552a8.294 8.294 0 1 1-7.39-12.955 15 15 0 0 1 7.39 12.955Z"
            fill="#4a5ea3"
          />
          <path
            data-name="Path 710"
            d="m84.583 142.232 1.885 15.751H75.121Z"
            fill="#4a5ea3"
          />
          <path
            data-name="Path 711"
            d="M8.78 127.535v.654a29.773 29.773 0 0 0 29.772 29.794h13.83l1.914 68.356h17.329l12.777-81.5a14.982 14.982 0 0 0-14.8-17.3Z"
            fill="#6277d7"
          />
          <path
            data-name="Path 712"
            d="m0 92.53.616 21.273a10.631 10.631 0 0 0 10.627 10.324h37.03l2.487-8.661-32.664-5.383-.231-17.668Z"
            fill="#fcbeb7"
          />
          <path
            data-name="Path 713"
            d="M10.981 119.703a5.477 5.477 0 0 1-1.947 4.194 10.644 10.644 0 0 1-7.994-7.409 5.49 5.49 0 0 1 9.941 3.215Z"
            fill="#f9afab"
          />
          <path
            data-name="Path 714"
            d="m17.865 92.415 5.015 18.456-4.784-.788-.231-17.668"
            fill="#4a62d3"
          />
          <path
            data-name="Path 715"
            d="m46.196 114.714-5.838 9.413h7.915l2.486-8.661Z"
            fill="#f9afab"
          />
          <path
            data-name="Path 716"
            d="M109.829 89.974H61.545a4.6 4.6 0 0 0-4.422 3.332l-8.848 30.821H28.696a1.704 1.704 0 1 0 0 3.408h76.46l9.1-31.691a4.6 4.6 0 0 0-4.427-5.87Z"
            fill="#4a5ea3"
          />
          <path
            data-name="Path 717"
            d="M84.583 142.232a8.295 8.295 0 1 1-7.406-12.646 15.007 15.007 0 0 1 7.406 12.646Z"
            fill="#4a5ea3"
          />
          <ellipse
            data-name="Ellipse 87"
            cx={2.616}
            cy={1.792}
            rx={2.616}
            ry={1.792}
            transform="rotate(-84.95 102.05 11.822)"
            fill="#89aeff"
          />
          <path
            data-name="Path 718"
            d="M9.713 67.551A4.857 4.857 0 0 1 .471 69.64a14.748 14.748 0 0 1 4.062-6.935 4.32 4.32 0 0 1 .323-.011 4.857 4.857 0 0 1 4.857 4.857Z"
            fill="#4a62d3"
          />
          <path
            data-name="Path 719"
            d="M75.802 66.725a4.858 4.858 0 0 1-7.01-6.288l.343.155a14.429 14.429 0 0 1 6.667 6.133Z"
            fill="#4a62d3"
          />
        </g>
      </g>
      <g data-name="Group 255" transform="translate(225.086 126.637)">
        <path
          data-name="Rectangle 243"
          fill="#d2dcff"
          d="M48.612 56.523h5.458v188.341h-5.458z"
        />
        <rect
          data-name="Rectangle 244"
          width={85.09}
          height={5.459}
          rx={2.652}
          transform="translate(8.796 244.864)"
          fill="#d2dcff"
        />
        <path
          data-name="Path 745"
          d="M102.683 56.523H0L17.768 0h67.148Z"
          fill="#d2dcff"
        />
        <path
          data-name="Path 746"
          d="M54.072 56.523v32.679c-1.845.626-3.672 1.247-5.458 1.855V56.523Z"
          fill="#aec3ff"
        />
        <path
          data-name="Path 747"
          d="m6.295 36.5 90.108.044 1.3 4.151H4.972Z"
          fill="#aec3ff"
        />
      </g>
      <g data-name="Group 256">
        <path
          data-name="Path 748"
          d="M340.051 360.957v-3.65a2.043 2.043 0 0 0-2.043-2.043h-84.691a7.737 7.737 0 0 0-7.737 7.737v6.223a7.737 7.737 0 0 0 7.737 7.737h84.691a2.044 2.044 0 0 0 2.043-2.044v-3.65a2.043 2.043 0 0 0-2.043-2.043h-81.579a3.112 3.112 0 0 1-3.112-3.112 3.111 3.111 0 0 1 3.112-3.111h81.579a2.044 2.044 0 0 0 2.043-2.044Z"
          fill="#86a2e2"
        />
        <path
          data-name="Path 749"
          d="M339.229 295.865v-3.65a2.043 2.043 0 0 0-2.043-2.043h-84.691a7.737 7.737 0 0 0-7.737 7.737v6.223a7.737 7.737 0 0 0 7.737 7.737h84.691a2.044 2.044 0 0 0 2.043-2.044v-3.65a2.043 2.043 0 0 0-2.043-2.043h-81.579a3.112 3.112 0 0 1-3.112-3.112 3.111 3.111 0 0 1 3.112-3.111h81.579a2.044 2.044 0 0 0 2.043-2.044Z"
          fill="#d2dcff"
        />
        <path
          data-name="Path 750"
          d="M334 339.26v-3.65a2.044 2.044 0 0 0-2.043-2.044h-72.589a7.737 7.737 0 0 0-7.737 7.737v6.224a7.737 7.737 0 0 0 7.737 7.737h72.589A2.044 2.044 0 0 0 334 353.22v-3.65a2.043 2.043 0 0 0-2.043-2.043h-69.478a3.112 3.112 0 0 1-3.111-3.112 3.111 3.111 0 0 1 3.111-3.112h69.478A2.044 2.044 0 0 0 334 339.26Z"
          fill="#aec3ff"
        />
        <path
          data-name="Path 751"
          d="M346.192 274.757v-3.649a2.044 2.044 0 0 0-2.044-2.044h-72.591a7.737 7.737 0 0 0-7.737 7.737v6.223a7.737 7.737 0 0 0 7.737 7.737h72.589a2.044 2.044 0 0 0 2.044-2.043v-3.65a2.044 2.044 0 0 0-2.044-2.044h-69.477a3.112 3.112 0 0 1-3.112-3.107 3.112 3.112 0 0 1 3.112-3.112h69.477a2.044 2.044 0 0 0 2.046-2.048Z"
          fill="#aec3ff"
        />
        <path
          data-name="Path 752"
          d="M331.309 341.303v6.224h-68.83a3.112 3.112 0 0 1-3.111-3.112 3.111 3.111 0 0 1 3.111-3.112Z"
          fill="#eff1fa"
        />
        <path
          data-name="Path 753"
          d="M325.23 363.001v6.223h-68.829a3.112 3.112 0 0 1-3.112-3.112 3.111 3.111 0 0 1 3.112-3.111Z"
          fill="#eff1fa"
        />
        <path
          data-name="Path 754"
          d="M324.408 297.909v6.223h-68.829a3.112 3.112 0 0 1-3.112-3.112 3.111 3.111 0 0 1 3.112-3.111Z"
          fill="#eff1fa"
        />
        <path
          data-name="Path 755"
          d="M343.501 276.801v6.223h-68.83a3.112 3.112 0 0 1-3.114-3.107 3.112 3.112 0 0 1 3.112-3.112Z"
          fill="#eff1fa"
        />
        <path
          data-name="Path 756"
          d="M346.192 317.562v-3.645a2.044 2.044 0 0 0-2.044-2.043h-72.591a7.737 7.737 0 0 0-7.737 7.737v6.223a7.737 7.737 0 0 0 7.737 7.737h72.589a2.044 2.044 0 0 0 2.044-2.043v-3.65a2.044 2.044 0 0 0-2.044-2.044h-69.477a3.112 3.112 0 0 1-3.112-3.111 3.112 3.112 0 0 1 3.112-3.112h69.477a2.044 2.044 0 0 0 2.046-2.049Z"
          fill="#86a2e2"
        />
        <path
          data-name="Path 757"
          d="M343.501 319.606v6.223h-68.83a3.112 3.112 0 0 1-3.112-3.111 3.112 3.112 0 0 1 3.112-3.112Z"
          fill="#eff1fa"
        />
        <path
          data-name="Path 758"
          d="M307.481 258.2h12.47a1.026 1.026 0 0 1 1.018 1.148l-1.055 8.812a1.025 1.025 0 0 1-1.018.9h-10.36a1.026 1.026 0 0 1-1.019-.9l-1.055-8.812a1.026 1.026 0 0 1 1.019-1.148Z"
          fill="#d2dcff"
        />
        <path
          data-name="Path 759"
          d="M317.996 254.605v-4.007l-2.4 4.555v-7.4l-1.884 4.657-1.884-4.657v7.4l-2.4-4.555v4.007l-3.493-5.616 1.539 9.212h12.47l1.539-9.212Z"
          fill="#fe9ecc"
        />
      </g>
      <g data-name="Group 260" transform="translate(0 328.608)">
        <path
          data-name="Rectangle 249"
          fill="#d2dcff"
          d="M26.033 0h47.259v47.259H26.033z"
        />
        <path data-name="Rectangle 250" fill="#aec3ff" d="M0 0h26.033v47.259H0z" />
        <path data-name="Rectangle 251" fill="#86a2e2" d="M0 8.081h26.033v1.384H0z" />
        <path
          data-name="Rectangle 252"
          fill="#aec3ff"
          d="M26.033 8.081h47.259v1.384H26.033z"
        />
        <rect
          data-name="Rectangle 253"
          width={18.585}
          height={1.23}
          rx={0.598}
          transform="translate(40.37 15)"
          fill="#86a2e2"
        />
      </g>
      <g data-name="Group 261">
        <path
          data-name="Rectangle 254"
          fill="#4a5ea3"
          d="m83.801 315.471 24.155 11.702-10.14 20.932-24.155-11.702z"
        />
        <path
          data-name="Rectangle 255"
          fill="#4a5ea3"
          d="m55.811 321.931 26.624-3.355 2.908 23.072-26.625 3.355z"
        />
        <path
          data-name="Rectangle 256"
          fill="#d2dcff"
          d="M69.178 328.608h47.259v47.259H69.178z"
        />
        <path
          data-name="Rectangle 257"
          fill="#aec3ff"
          d="M43.145 328.608h26.033v47.259H43.145z"
        />
        <path
          data-name="Path 763"
          d="m43.145 328.609-13.523 14.486h29.373l10.183-14.486Z"
          fill="#86a2e2"
        />
        <path
          data-name="Path 764"
          d="m116.437 328.609 9.652 14.673h-47.19l-9.721-14.743Z"
          fill="#aec3ff"
        />
        <path
          data-name="Path 765"
          d="m79.204 318.983-1.9 9.571 5.135-9.979Z"
          fill="#2f3d6c"
        />
      </g>
      <g data-name="Group 262" transform="translate(25.498)">
        <rect
          data-name="Rectangle 258"
          width={216.815}
          height={59.7}
          rx={5.075}
          fill="#6277d7"
        />
        <rect
          data-name="Rectangle 259"
          width={126.672}
          height={7.303}
          rx={2.608}
          transform="translate(21.161 26.199)"
          fill="#d2dcff"
        />
      </g>
      <g data-name="Group 264" transform="translate(260.994)">
        <rect
          data-name="Rectangle 260"
          width={59.7}
          height={59.7}
          rx={5.075}
          fill="#6277d7"
        />
        <g data-name="Group 263">
          <path
            data-name="Path 766"
            d="M44.966 46.318a1.882 1.882 0 0 1-1.336-.554l-6.286-6.286a14.84 14.84 0 1 1 .826-21.754 14.868 14.868 0 0 1 1.709 18.943l6.423 6.425a1.89 1.89 0 0 1-1.336 3.226ZM27.677 19.537a8.683 8.683 0 1 0 6.142 14.822c.062-.062.122-.124.182-.187a1.441 1.441 0 0 1 .212-.266 1.377 1.377 0 0 1 .123-.11 8.681 8.681 0 0 0-6.659-14.259Z"
            fill="#d2dcff"
          />
        </g>
      </g>
    </g>
  </svg>
);
