export interface RegisterUser {
  email: string;
  password: string;
  birthDate: {
    year: string;
    month: string;
    day: string;
  };
  username: string;
  firstName: string;
  lastName: string;
}

export enum PreferredContact {
  EMAIL = "email",
  PHONE = "phone",
}

export enum UserFields {
  ID = "username",
  EMAIL = "email",
  USERNAME = "preferred_username",
  PASSWORD = "password",
  FIRST_NAME = "given_name",
  LAST_NAME = "family_name",
  BIRTHDATE = "birthdate",
  PHONE_NUMBER = "custom:phone_number",
  LIVES_IN_US = "custom:lives_in_us",
  PREFERRED_CONTACT = "custom:preferred_contact",
  IS_INTRODUCED = "custom:is_introduced",
  EMAIL_VERIFIED = "email_verified",
}

export interface User {
  userId: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  livesInUs: boolean;
  phoneNumber?: string;
  isVerified: boolean;
  isIntroduced: boolean;
  profileImgUrl: string;
}

export interface AvatarPayload {
  profileImgUrl: string | null;
}

export interface UserResponse {
  Id: number;
  Email: string;
  FirstName?: string;
  LastName?: string;
  DateOfBirth?: string;
  UserName: string;
  PhoneNumber?: string;
  LastActivity: string;
  LastEngagement?: string;
  Updated: string;
  Created: string;
  UtmCampaign?: string;
  PromoCode?: string;
  ReferralCode?: string;
  ReferralCodeParent?: string;
  IsActive: true;
  EmailVerified: true;
  EulaAccepted: true;
  IsSuspended: true;
  IsDefaultUserName: true;
  PictureUri?: string;
}
