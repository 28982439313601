import { forwardRef, InputHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import { Input } from "components/inputs";
import "./profile-labeled-input.scss";

interface ProfileLabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  error?: boolean;
  errorText?: string;
  isLongError?: boolean;
  customPasswordError?: ReactNode;
}

export const ProfileLabeledInput = forwardRef<HTMLInputElement, ProfileLabeledInputProps>(
  (
    {
      labelText,
      error,
      errorText,
      isLongError,
      customPasswordError,
      className,
      ...inputProps
    },
    ref
  ) => {
    return (
      <div className={clsx("profile-labeled-input", className)}>
        <label className="profile-labeled-input__label">{labelText}</label>
        <Input
          {...inputProps}
          placeholder={labelText}
          rootClassName="profile-labeled-input__input-root"
          className="profile-labeled-input__input"
          error={error}
          errorText={errorText}
          isLongError={isLongError}
          ref={ref}
          customPasswordError={customPasswordError}
        />
      </div>
    );
  }
);

ProfileLabeledInput.displayName = "ProfileLabeledInput";
