export const formatToPosition = (place: number) => {
  if (place === 0) {
    return "-";
  }
  let suffix = "";
  const endOfNumber = place % 100;

  switch (place % 10) {
    case 1:
      if (endOfNumber === 11) suffix = "th";
      else suffix = "st";
      break;
    case 2:
      if (endOfNumber === 12) suffix = "th";
      else suffix = "nd";
      break;
    case 3:
      if (endOfNumber === 13) suffix = "th";
      else suffix = "rd";
      break;
    default:
      suffix = "th";
      break;
  }
  return `${place}${suffix}`;
};
