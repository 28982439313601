import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuContent, MenuWrapper } from "components/menu";
import { ScreenDarken } from "components/screen-darken";
import { Topbar } from "components/topbar";
import {
  selectShowScreenDarkenLeaderboard,
  selectShowScreenDarkenMenu,
  setShowScreenDarkenLeaderboard,
  setShowScreenDarkenMenu,
} from "store/app-slice";
import { getCssVariable } from "helpers/get-css-variable";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useLogout } from "hooks/use-logout";
import { useOnClickAway } from "hooks/use-on-click-away";
import { AllPaths, Paths, PathsProfile } from "types/router";
import "./menu-layout.scss";

const PAGES_WITHOUT_TOPBAR: string[] = [
  Paths.PROFILE,
  PathsProfile.STATS_ABSOLUTE,
  Paths.ONBOARDING,
];

const PAGES_WITHOUT_MENU = [Paths.ONBOARDING];

export const MenuLayout: React.FC = ({ children }) => {
  const [isSidebarOpen, setIsSideBarOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const showScreenDarkenMenu = useAppSelector(selectShowScreenDarkenMenu);
  const showScreenDarkenLeaderboard = useAppSelector(selectShowScreenDarkenLeaderboard);
  const logout = useLogout();

  const handleMenuButtonClick = () => {
    setIsSideBarOpen(!isSidebarOpen);
    dispatch(setShowScreenDarkenMenu(!showScreenDarkenMenu));
    dispatch(setShowScreenDarkenLeaderboard(false));
  };

  const handleClick = async (path?: AllPaths) => {
    if (path === Paths.SIGN_IN) {
      dispatch(setShowScreenDarkenMenu(false));
      dispatch(setShowScreenDarkenLeaderboard(false));
      await logout();
    } else {
      if (isSidebarOpen) {
        dispatch(setShowScreenDarkenMenu(false));
        dispatch(setShowScreenDarkenLeaderboard(false));
      }
      setIsSideBarOpen(false);
      setIsSideBarOpen(false);
    }
  };

  const sideBarRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const breakpointLg = Number(getCssVariable("$breakpoint-lg"));
  useOnClickAway([sideBarRef, menuButtonRef], handleClick, breakpointLg);

  return (
    <div className="menu-layout">
      <MenuWrapper>
        <MenuContent ref={sideBarRef} isOpen={isSidebarOpen} onClose={handleClick} />
      </MenuWrapper>
      <div className="menu-layout__main">
        <Topbar
          ref={menuButtonRef}
          handleMenuButtonClick={handleMenuButtonClick}
          mobileHidden={PAGES_WITHOUT_TOPBAR.some((path) => pathname.includes(path))}
          noMenu={PAGES_WITHOUT_MENU.some((path) => pathname.includes(path))}
        />
        <div className="menu-layout__content">
          <ScreenDarken isActive={showScreenDarkenMenu || showScreenDarkenLeaderboard} />
          {children}
        </div>
      </div>
    </div>
  );
};
