import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { BottomNavigation } from "components/bottom-navigation";
import { CommonForm } from "components/common-form";
import { RadioButton } from "components/inputs";
import { PhoneInput } from "components/inputs/phone-input";
import { Select } from "components/select";
import { selectRegisterUser } from "store/register-slice";
import {
  ERROR_PHONE_NUMBER_INVALID,
  ERROR_REQUIRED_USA_RESIDENT,
  UNKNOWN_ERROR_FALLBACK,
} from "helpers/constants/errors";
import { REGEX_PHONE_NUMBER } from "helpers/constants/regex";
import { validatePhoneNumber } from "helpers/validate-phone-number";
import { useAppSelector } from "hooks/store";
import { BooleanValue } from "types/boolean";
import { Paths } from "types/router";
import { Option } from "types/select";
import { PreferredContact, UserFields } from "types/user";
import "./contact-info.scss";

export const CONTACT_OPTIONS: Option<PreferredContact>[] = [
  {
    label: "Email",
    value: PreferredContact.EMAIL,
  },
  {
    label: "Phone",
    value: PreferredContact.PHONE,
  },
];

interface ContactInfoInput {
  preferredContact: PreferredContact;
  phoneNumber?: string;
  livesInUS: BooleanValue;
}

interface ContactInfoProps {
  onSignUpClicked: () => void;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({ onSignUpClicked }) => {
  const { email, username, password, birthDate, firstName, lastName } =
    useAppSelector(selectRegisterUser);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ContactInfoInput>({
    defaultValues: {
      preferredContact: PreferredContact.EMAIL,
      livesInUS: BooleanValue.YES,
    },
    mode: "onChange",
  });

  const signUp = async (inputData: ContactInfoInput) => {
    const result = await Auth.signUp({
      [UserFields.ID]: email,
      [UserFields.PASSWORD]: password,
      attributes: {
        [UserFields.USERNAME]: username,
        [UserFields.BIRTHDATE]: `${birthDate.year}-${birthDate.month}-${birthDate.day}`,
        [UserFields.FIRST_NAME]: firstName,
        [UserFields.LAST_NAME]: lastName,
        [UserFields.PHONE_NUMBER]: inputData.phoneNumber,
        [UserFields.LIVES_IN_US]: inputData.livesInUS,
        [UserFields.PREFERRED_CONTACT]: inputData.preferredContact,
        [UserFields.IS_INTRODUCED]: BooleanValue.NO,
      },
    }).catch((error) => {
      toast.error(error.message || UNKNOWN_ERROR_FALLBACK);
      return undefined;
    });
    return result !== undefined;
  };

  const onSubmit = async (
    data: ContactInfoInput,
    event?: React.BaseSyntheticEvent<object, unknown, unknown>
  ) => {
    if (event) {
      event.preventDefault();
    }
    const signedUp = await signUp({ ...data });
    if (signedUp) {
      onSignUpClicked();
    }
  };

  const validateLivesInUS = (data: BooleanValue) =>
    data === BooleanValue.YES ? true : ERROR_REQUIRED_USA_RESIDENT;

  return (
    <div className="contact-info">
      <CommonForm
        title="Contact info"
        onSubmit={handleSubmit((data, event) => onSubmit(data, event))}
        footer={
          <BottomNavigation
            submitText="SIGN UP"
            cancelText="CANCEL"
            cancelPath={Paths.SIGN_UP}
            isSubmitType
          />
        }
      >
        <div className="contact-info__container">
          <div>
            <label className="contact-info__label">Prefer contact method</label>
            <Controller
              control={control}
              name="preferredContact"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={(selectedOption) => onChange(selectedOption.value)}
                  options={CONTACT_OPTIONS}
                  className="contact-info__select"
                />
              )}
            />
          </div>
          <label className="contact-info__label">Phone</label>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                onChange={onChange}
                value={value}
                error={errors.phoneNumber !== undefined}
                errorText={errors.phoneNumber?.message}
              />
            )}
            rules={{
              required: false,
              pattern: {
                value: REGEX_PHONE_NUMBER,
                message: ERROR_PHONE_NUMBER_INVALID,
              },
              validate: (value) => validatePhoneNumber(value),
            }}
          />
          <div>
            <label className="contact-info__label">Do you live in the US?</label>
            <div className="contact-info__checkboxes-wrapper">
              <Controller
                name="livesInUS"
                control={control}
                render={() => (
                  <>
                    <div className="contact-info__radio-wrapper">
                      <RadioButton {...register("livesInUS")} value={BooleanValue.YES} />
                      Yes
                    </div>
                    <div className="contact-info__radio-wrapper">
                      <RadioButton {...register("livesInUS")} value={BooleanValue.NO} />
                      No
                    </div>
                    <span className="contact-info__span-error">
                      {errors.livesInUS?.message}
                    </span>
                  </>
                )}
                rules={{
                  required: true,
                  validate: validateLivesInUS,
                }}
              />
            </div>
          </div>
        </div>
      </CommonForm>
    </div>
  );
};
