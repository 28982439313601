import { ButtonHTMLAttributes } from "react";
import clsx from "clsx";
import { LoaderSpinner } from "components/loader-spinner";
import "./button.scss";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "tertiary" | "link" | "primary-rounded" | "clear";
  fontSize?: "sm" | "sm-regular" | "md";
  loader?: boolean;
  childrenClassName?: string;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  variant = "primary",
  fontSize = "md",
  loader,
  childrenClassName,
  ...restProps
}) => {
  return (
    <button
      className={clsx(
        "button",
        `button--${variant}`,
        `button--${fontSize}-size`,
        className
      )}
      {...restProps}
    >
      {loader && (
        <div data-testid="spinner-wrapper" className="button__spinner">
          <LoaderSpinner size="tiny" />
        </div>
      )}
      <div
        data-testid="children-wrapper"
        className={clsx(
          "button__children",
          childrenClassName,
          loader && "button__children--loader"
        )}
      >
        {children}
      </div>
    </button>
  );
};
