import React from "react";
import NumberFormat from "react-number-format";
import clsx from "clsx";
import { Input } from "components/inputs";
import { REGEX_WHITESPACES } from "helpers/constants";
import "./phone-input.scss";

interface PhoneInputProps {
  onChange: (value: string) => void;
  value?: string;
  errorText?: string;
  error?: boolean;
  className?: string;
}
const PHONE_NUMBER_SPACE_PLACE = 5;

export const PhoneInput: React.FC<PhoneInputProps> = ({
  onChange,
  value,
  errorText,
  error,
  className,
}) => {
  return (
    <div className={clsx("phone-input", className)}>
      <NumberFormat
        customInput={Input}
        format="( # # # )  # # # - # # # #"
        allowEmptyFormatting
        mask="_"
        onValueChange={(formatValue) => {
          const inputValue = formatValue.formattedValue.replace(REGEX_WHITESPACES, "");
          onChange(
            `${inputValue.substring(0, PHONE_NUMBER_SPACE_PLACE)} ${inputValue.substring(
              PHONE_NUMBER_SPACE_PLACE,
              inputValue.length
            )}`
          );
        }}
        value={value}
        className={clsx("phone-input__number-format", {
          "phone-input__number-format--error": error,
        })}
        errorText={errorText}
      />
    </div>
  );
};

PhoneInput.displayName = "PhoneInput";
