import { Navigate, Routes } from "react-router-dom";
import { AdminPanelPage, Onboarding, ProfilePage } from "pages";
import {
  ContestDetails,
  ContestPrizes,
  ContestRules,
  ContestTournaments,
} from "components/contest";
import { LeaderboardUsers } from "components/leaderboard";
import { MyBetsCurrentUser } from "components/my-bets";
import { ContestsView } from "components/profile-contests-view";
import { StatsView } from "components/profile-stats-view";
import { SlatesTable } from "components/slates-table";
import { useAppRoutes } from "hooks/use-app-routes";
import { Paths, PathsLobby, PathsMyContests, PathsProfile } from "types/router";
import { TopbarTitle } from "types/topbar";
import { EditProfile } from "pages/edit-profile";
import { Lobby } from "pages/lobby";
import { MyAccountPage } from "pages/my-account";
import { MyContestsPage } from "pages/my-contests";

const mockTournamentRulesData = {
  rules: [
    {
      label: "Minimum Risk",
      text: ["Total $TD balance", "Minimum Bet: 5% of original $TD"],
    },
    {
      label: "Submission",
      text: [
        "Once submitted, bets are locked.",
        "Once a game starts all bets related to the game become public.",
      ],
    },
  ],
  entry: 25,
};

export const AppRoutes = () => {
  const routes = useAppRoutes([
    { path: Paths.ONBOARDING, element: <Onboarding /> },
    { path: PathsLobby.ROOT, element: <Navigate to={Paths.LOBBY} /> },
    {
      path: PathsLobby.PARAM_CATEGORY,
      element: <Navigate to={Paths.LOBBY} />,
    },
    {
      path: PathsLobby.PARAM_CATEGORY_CONTESTS_ID,
      element: <Lobby />,
      children: [
        {
          path: PathsLobby.DETAILS_WILD_CARD,
          element: <ContestDetails />,
        },
        {
          path: PathsLobby.PRIZES,
          element: <ContestPrizes />,
        },
        {
          path: PathsLobby.GAMES,
          element: <ContestTournaments />,
        },
        {
          path: PathsLobby.RULES,
          element: <ContestRules {...mockTournamentRulesData} />,
        },
      ],
      title: TopbarTitle.LOBBY,
    },
    {
      path: PathsMyContests.ROOT,
      element: <Navigate to={Paths.CONTESTS} />,
      title: TopbarTitle.CONTESTS,
    },
    {
      path: PathsMyContests.PARAM_CONTESTS,
      element: <MyContestsPage />,
      title: TopbarTitle.CONTESTS,
    },
    {
      path: PathsMyContests.PARAM_CATEGORY_CONTEST_ID,
      element: <MyContestsPage />,
      title: TopbarTitle.CONTESTS,
      children: [
        {
          path: PathsMyContests.SLATE,
          element: <SlatesTable />,
        },
        {
          path: PathsMyContests.MY_BETS,
          element: <MyBetsCurrentUser />,
        },
        {
          path: PathsMyContests.LEADERBOARD,
          element: <LeaderboardUsers />,
        },
      ],
    },
    { path: Paths.ACCOUNT, element: <MyAccountPage />, title: TopbarTitle.ACCOUNT },

    { path: Paths.EDIT_PROFILE, element: <EditProfile /> },
    { path: PathsProfile.ROOT, element: <Navigate to={Paths.PROFILE} /> },
    {
      path: PathsProfile.ROOT,
      element: <ProfilePage />,
      children: [
        { path: PathsProfile.CONTESTS, element: <ContestsView showTitleOnSm /> },
        { path: PathsProfile.STATS, element: <StatsView /> },
      ],
      title: TopbarTitle.PROFILE,
    },
    { path: Paths.ADMIN_PANEL, element: <AdminPanelPage />, title: TopbarTitle.ADMIN },
  ]);

  return <Routes>{routes}</Routes>;
};
