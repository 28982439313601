import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { CONTEST_PARAM } from "components/contests-mobile-search-view";
import { SearchInput } from "components/inputs";
import { SortDropdown } from "components/sort-dropdown";
import { AllPaths } from "types/router";
import { SearchIcon, SortIcon } from "assets/icons";
import "./search-bar.scss";

interface SearchBarProps {
  mobileSortPath: AllPaths;
  mobileSearchPath: AllPaths;
  className?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  mobileSortPath,
  mobileSearchPath,
  className,
}) => {
  return (
    <div className={clsx("search-bar", className)}>
      <Link className="search-bar__mobile" to={mobileSearchPath}>
        <SearchIcon className="search-bar__icon" />
      </Link>
      <SearchInput
        className="search-bar__input"
        icon={SearchIcon}
        iconColor="black"
        placeholder="Search"
        rootClassName="search-bar__input-root"
        variant="bar"
        paramName={CONTEST_PARAM}
      />
      <SortDropdown className="search-bar__dropdown-wide" />
      <Link className="search-bar__dropdown-mobile" to={mobileSortPath}>
        <SortIcon />
      </Link>
    </div>
  );
};
