import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Amplify } from "aws-amplify";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { AppRouter } from "router";
import { ConfigProvider } from "components/config-provider";
import { store } from "store/main-store";
import { AxiosInterceptor } from "utils/axios";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./service-worker-registration";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

Amplify.configure({
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_dFLneyZ4L",
    userPoolWebClientId: "6ihq045vn31gjmke1algq5t09q",
    oauth: {
      domain: "bma.auth.us-west-2.amazoncognito.com",
    },
  },
});

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="root__toast"
        />
        <AppRouter />
        <AxiosInterceptor />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
