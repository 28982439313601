import { ReactNode } from "react";
import "./content-wrapper.scss";

interface ContentWrapperProps {
  children: ReactNode;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  return <div className="content-wrapper">{children}</div>;
};
