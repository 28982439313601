import { LiHTMLAttributes, SVGProps } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { ComingSoonLabel } from "components/coming-soon-label";
import "./menu-option.scss";

interface MenuOptionProps extends LiHTMLAttributes<HTMLLIElement> {
  icon: React.FC<SVGProps<SVGSVGElement>>;
  hideIconOnMd?: boolean;
  text: string;
  rootPath: string;
  comingSoon?: boolean;
  isDisabled: boolean;
}

export const MenuOption: React.FC<MenuOptionProps> = ({
  icon,
  text,
  className,
  children,
  hideIconOnMd,
  rootPath,
  comingSoon,
  isDisabled,
  ...restProps
}) => {
  const Icon = icon;

  const pathname = useLocation().pathname;
  return (
    <li
      className={clsx("menu-option", {
        "menu-option--smallerOnMd": hideIconOnMd,
        "menu-option--active": pathname.includes(rootPath),
        "menu-option--disabled": comingSoon || isDisabled,
      })}
      {...restProps}
    >
      <Icon
        className={clsx("menu-option__icon", {
          "menu-option__icon--disabled": hideIconOnMd,
        })}
      />
      {children}
      <span className="menu-option__text">{text}</span>
      {comingSoon && <ComingSoonLabel className="menu-option__coming-soon" />}
    </li>
  );
};
