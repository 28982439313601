import clsx from "clsx";
import { MyBetsCard } from "components/my-bets/my-bets-card";
import { formatToPoints } from "helpers/format-to-points";
import { getWagerModifier } from "helpers/get-wager-modifier";
import { getWagerVariant } from "helpers/get-wager-variant";
import { ContestParlayItem } from "types/request";
import "./my-bets.scss";

interface MyBetsProps {
  totalWager: number;
  totalPayout: number;
  itemToMap: ContestParlayItem | null;
  className?: string;
}

export const MyBets: React.FC<MyBetsProps> = ({
  totalPayout,
  totalWager,
  itemToMap,
  className,
}) => {
  const leaderboardUserBets = itemToMap;
  return (
    <div className={clsx("my-bets", className)}>
      <div className="my-bets__wrapper">
        {itemToMap &&
          leaderboardUserBets?.wagers.map((bet) => {
            const {
              moneyLine,
              overPayout,
              underPayout,
              homeTeam,
              awayTeam,
              amount,
              amountWon,
              gameDate,
              wagerId,
            } = bet;

            const wagerVariant = getWagerVariant(bet);
            const wagerModifier = getWagerModifier(bet);

            return (
              <MyBetsCard
                wagerModifier={wagerModifier}
                variant={wagerVariant}
                moneyLine={moneyLine || overPayout || underPayout}
                homeTeam={homeTeam}
                awayTeam={awayTeam}
                wager={amount}
                payout={amountWon}
                date={gameDate}
                key={wagerId}
              />
            );
          })}
        <div className="my-bets__footer">
          <div className="my-bets__footer-values">
            <span>
              {formatToPoints({
                value: totalWager,
                separator: "comma",
                withSuffix: true,
              })}
            </span>
            <span>
              {formatToPoints({
                value: totalPayout,
                separator: "comma",
                withSuffix: true,
              })}
            </span>
          </div>
          <div className="my-bets__footer-labels">
            <span>TOTAL WAGER</span>
            <span>TOTAL PAYOUT</span>
          </div>
        </div>
      </div>
    </div>
  );
};
