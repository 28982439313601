import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BirthdateSelector } from "components/birthdate-selector";
import { CodeVerification } from "components/code-verification";
import { ContactInfo } from "components/contact-info";
import { ContentWrapper } from "components/content-wrapper";
import { ImageLayout } from "components/image-layout";
import { ProfileDetails } from "components/profile-details";
import { Stepper } from "components/stepper";
import { selectRegisterUser, setUserDetails } from "store/register-slice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { ProfileDetailsTextInputs } from "types/profile-details";
import { Paths } from "types/router";
import { PreferredContact } from "types/user";
import "./sign-up-steps.scss";

enum SignUpStep {
  BIRTHDAY,
  PROFILE_DETAILS,
  CONTACT_INFO,
  SUMMARY,
}

export const SignUpSteps: React.FC = () => {
  const [activeStep, setActiveStep] = useState(SignUpStep.BIRTHDAY);
  const { email, password } = useAppSelector(selectRegisterUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate(Paths.SIGN_UP);
    }
  }, [email, password, navigate]);

  const handleChangeUserData = (data: ProfileDetailsTextInputs) => {
    dispatch(setUserDetails({ ...data }));
  };

  const handleSignUp = () => {
    setActiveStep(SignUpStep.SUMMARY);
  };

  let element: ReactNode;
  switch (activeStep) {
    case SignUpStep.BIRTHDAY:
      element = (
        <BirthdateSelector
          onNextClicked={() => setActiveStep(SignUpStep.PROFILE_DETAILS)}
        />
      );
      break;
    case SignUpStep.PROFILE_DETAILS:
      element = (
        <ProfileDetails
          onNextClicked={() => setActiveStep(SignUpStep.CONTACT_INFO)}
          onUserDataChange={handleChangeUserData}
          initialState={{
            username: "",
            firstName: "",
            lastName: "",
            profileImage: null,
            email: null,
            preferredContact: PreferredContact.EMAIL,
          }}
          cancelPath={Paths.SIGN_UP}
        />
      );
      break;
    case SignUpStep.CONTACT_INFO:
      element = <ContactInfo onSignUpClicked={handleSignUp} />;
      break;
    case SignUpStep.SUMMARY:
      element = <CodeVerification email={email} password={password} />;
      break;
    default:
      element = null;
      break;
  }

  return (
    <div className="sign-up-steps">
      <ImageLayout />
      <ContentWrapper>
        <div className="sign-up-steps__content">
          <Stepper
            activeStep={activeStep}
            numberOfSteps={3}
            element={element}
            hideSteps={activeStep === SignUpStep.SUMMARY}
          />
        </div>
      </ContentWrapper>
    </div>
  );
};
