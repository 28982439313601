import { BaseSyntheticEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { BottomNavigation } from "components/bottom-navigation";
import { SendAgainButton } from "components/button";
import { CodeSelector } from "components/code-selector";
import { CommonForm } from "components/common-form";
import { AnimatedLabeledInput } from "components/inputs/animated-labeled-input";
import { PasswordError } from "components/password-error";
import {
  ERROR_PASSWORD_EMPTY,
  ERROR_PASSWORD_INVALID,
  ERROR_PASSWORD_NOT_MATCH,
  ERROR_REQUIRED,
  REGEX_PASSWORD,
  UNKNOWN_ERROR_FALLBACK,
} from "helpers/constants";
import { isCodeIncludingSpace } from "helpers/is-code-including-space";
import { useLogout } from "hooks/use-logout";
import { Paths } from "types/router";
import "./reset-password-content.scss";

interface ResetPasswordContentInput {
  verificationCode: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ResetPasswordContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const email = location.state as string;

  useEffect(() => {
    if (!email) {
      navigate(Paths.FORGOT_PASSWORD);
    }
  }, [email, navigate, location]);

  const {
    register,
    handleSubmit,
    getFieldState,
    watch,
    trigger,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ResetPasswordContentInput>({
    reValidateMode: "onBlur",
    defaultValues: {
      verificationCode: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const onSubmit = async (
    { newPassword, verificationCode }: ResetPasswordContentInput,
    event?: BaseSyntheticEvent<object, unknown, unknown>
  ) => {
    if (event) {
      event.preventDefault();
    }

    if (email) {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
        .then(() => {
          toast.success("Password has been changed successfully");
          navigate(Paths.SIGN_IN);
        })
        .catch((error) => toast.error(error.message));
    }
    await logout();
  };

  const resendCode = () => {
    if (email) {
      Auth.forgotPassword(email)
        .then(() => toast.success("Code has been send"))
        .catch((error) => toast.error(error.message || UNKNOWN_ERROR_FALLBACK));
    }
  };

  const handlePasswordChange = () => {
    const { isTouched } = getFieldState("confirmNewPassword");
    if (isTouched) {
      trigger("confirmNewPassword");
    }
  };

  const handleClipboardEvent = (verificationCode: string) => {
    setValue("verificationCode", verificationCode);
  };

  return (
    <div className="reset-password-content">
      <CommonForm
        onSubmit={handleSubmit(onSubmit)}
        className="reset-password-content__form"
        title="Reset password"
        subtitle={
          <>
            Type your new password here. Verification code has been send to{" "}
            <span className="reset-password-content__subtitle">{email}</span>
          </>
        }
        footer={
          <div className="reset-password-content__footer">
            <BottomNavigation
              submitText="UPDATE"
              cancelText="CANCEL"
              cancelPath={Paths.SIGN_IN}
              isSubmitType
            />
          </div>
        }
      >
        <div className="reset-password-content__body">
          <div className="reset-password-content__code-selector">
            <span className="reset-password-content__label">Verification code</span>
            <Controller
              data-testid="code-input"
              control={control}
              name="verificationCode"
              rules={{
                validate: (value) => isCodeIncludingSpace(value),
                minLength: { value: 6, message: ERROR_REQUIRED },
                required: ERROR_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <CodeSelector
                  onChange={onChange}
                  onCodePaste={(verificationCode) => {
                    handleClipboardEvent(verificationCode);
                    trigger("verificationCode");
                  }}
                  value={value}
                  numberOfDigits={6}
                  error={!!errors.verificationCode}
                  errorText={errors.verificationCode?.message}
                  errorTextClassName="reset-password-content__error-message"
                />
              )}
            />
          </div>
          <AnimatedLabeledInput
            labelText="New password"
            placeholder="New Password"
            autoComplete="new-password"
            type="password"
            error={!!errors.newPassword}
            errorText={errors.newPassword?.message}
            {...register("newPassword", {
              required: ERROR_PASSWORD_EMPTY,
              pattern: {
                value: REGEX_PASSWORD,
                message: ERROR_PASSWORD_INVALID,
              },
              onChange: () => handlePasswordChange(),
            })}
            customPasswordError={
              <PasswordError passwordValue={getValues("newPassword")} />
            }
          />
          <AnimatedLabeledInput
            labelText="Confirm password"
            placeholder="Confirm Password"
            autoComplete="new-password"
            type="password"
            error={!!errors.confirmNewPassword}
            errorText={errors.confirmNewPassword?.message}
            {...register("confirmNewPassword", {
              validate: (value) => {
                if (watch("newPassword") !== value) {
                  return ERROR_PASSWORD_NOT_MATCH;
                }
              },
              onChange: () => handlePasswordChange(),
            })}
          />
          <span className="reset-password-content__resend-code">
            Did the code not arrive?
            <SendAgainButton onClick={resendCode} />
          </span>
        </div>
      </CommonForm>
    </div>
  );
};
