import { useEffect } from "react";

export const useOnClickAway = (
  refs: React.RefObject<HTMLElement | null>[],
  callback: () => void,
  windowWidth?: number
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!windowWidth || window.innerWidth < windowWidth) {
        const clickedAtLeast1Ref = refs.some((ref) => {
          return ref.current && ref.current.contains(event.target as Node);
        });

        if (!clickedAtLeast1Ref) {
          callback();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, callback, windowWidth]);
};
