import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "components/button";
import { AnimatedLabeledInput } from "components/inputs/animated-labeled-input";
import { PasswordError } from "components/password-error";
import { selectRegisterUser, setEmailAndPassword } from "store/register-slice";
import { checkUserNotExists } from "helpers/check-user-exist";
import {
  ERROR_EMAIL_EMPTY,
  ERROR_EMAIL_INVALID,
  ERROR_PASSWORD_EMPTY,
  ERROR_PASSWORD_INVALID,
  ERROR_PASSWORD_NOT_MATCH,
  REGEX_EMAIL,
  REGEX_PASSWORD,
} from "helpers/constants";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useLobbyOrOnboardingRedirect } from "hooks/use-lobby-or-onboarding-redirect";
import { MediaQueryBreakpoints, useMediaQuery } from "hooks/use-media-query";
import { Paths } from "types/router";
import { LockIcon, MailIcon } from "assets/icons";
import "./sign-up-content.scss";

interface RegisterInput {
  email: string;
  password: string;
  confirmPassword: string;
}

export const SignUpContent = () => {
  useLobbyOrOnboardingRedirect();
  const { email, password } = useAppSelector(selectRegisterUser);

  const {
    register,
    handleSubmit,
    getFieldState,
    watch,
    trigger,
    getValues,
    formState,
    formState: { errors },
    reset,
  } = useForm<RegisterInput>({
    defaultValues: { email: "", password: "", confirmPassword: "" },
    criteriaMode: "all",
    reValidateMode: "onBlur",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(MediaQueryBreakpoints.DESKTOP);

  const onSubmit = async (data: RegisterInput) => {
    const isUserNotExist = await checkUserNotExists(data.email);
    if (isUserNotExist) {
      dispatch(setEmailAndPassword({ email: data.email, password: data.password }));
      navigate(Paths.SIGN_UP_STEPS);
    } else {
      toast.error("User already exists");
    }
  };

  const handlePasswordChange = () => {
    const confirmPasswordState = getFieldState("confirmPassword");
    const passwordState = getFieldState("password");
    if (passwordState.isTouched) {
      trigger("password");
    }
    if (confirmPasswordState.isTouched) {
      trigger("confirmPassword");
    }
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ email, password });
    }
  }, [reset, formState, email, password]);

  return (
    <>
      <div className="sign-up-content">
        <div className="sign-up-content__logo-wrapper">
          <img
            srcSet="images/logo-base.png, images/logo-base@2x.png 2x"
            src="images/logo-base.png"
            alt="logo"
            className="sign-up-content__logo"
          />
        </div>
        <form className="sign-up-content__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="sign-up-content__title">Sign Up</div>
          <AnimatedLabeledInput
            labelText="Email"
            {...register("email", {
              required: ERROR_EMAIL_EMPTY,
              pattern: {
                value: REGEX_EMAIL,
                message: ERROR_EMAIL_INVALID,
              },
            })}
            placeholder="Email"
            icon={MailIcon}
            error={!!errors.email}
            errorText={errors.email?.message}
            rounded
            autoComplete="email"
          />
          <AnimatedLabeledInput
            labelText="Password"
            {...register("password", {
              required: ERROR_PASSWORD_EMPTY,
              pattern: {
                value: REGEX_PASSWORD,
                message: ERROR_PASSWORD_INVALID,
              },
              onChange: () => handlePasswordChange(),
            })}
            placeholder="Password"
            type="password"
            icon={LockIcon}
            error={!!errors.password}
            errorText={errors.password?.message}
            rounded
            customPasswordError={
              <PasswordError
                passwordValue={getValues("password")}
                altTextColor={!isDesktop}
              />
            }
            autoComplete="new-password"
          />
          <AnimatedLabeledInput
            labelText="Confirm password"
            {...register("confirmPassword", {
              required: ERROR_PASSWORD_EMPTY,
              validate: (value) => {
                if (watch("password") !== value) {
                  return ERROR_PASSWORD_NOT_MATCH;
                }
              },
              onChange: () => handlePasswordChange(),
            })}
            placeholder="Confirm Password"
            type="password"
            icon={LockIcon}
            error={!!errors.confirmPassword}
            errorText={errors.confirmPassword?.message}
            rounded
            autoComplete="new-password"
          />
          <Button type="submit" variant="secondary" className="sign-up-content__button">
            CREATE ACCOUNT
          </Button>
          <div className="sign-up-content__terms-and-privacy">
            By proceeding you also agree to the{" "}
            <Link to={Paths.TERMS} className="sign-up-content__link-terms">
              Terms of Service{" "}
            </Link>
            &{" "}
            <Link to={Paths.TERMS} className="sign-up-content__link-terms">
              Privacy Policy
            </Link>
          </div>
        </form>
        <Link to={Paths.SIGN_IN} className="sign-up-content__link-login">
          Already have an account? Sign In
        </Link>
      </div>
    </>
  );
};
