import { useNavigate } from "react-router-dom";
import { ProfileDetails } from "components/profile-details";
import { selectAuthPayload, updateAPIUser, updateProfileImage } from "store/auth";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { EditProfileTextInputs } from "types/profile-details";
import { Paths } from "types/router";
import { PreferredContact } from "types/user";
import "./edit-profile.scss";

export const EditProfile: React.FC = () => {
  const { userId, username, firstName, lastName, email, phoneNumber, preferredContact } =
    useAppSelector(selectAuthPayload);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangeProfileImage = (file: File | null) => {
    const formData = new FormData();
    if (file) {
      formData.append("image", file, file.name);
    } else {
      formData.append("image", "");
    }
    dispatch(updateProfileImage(formData));
  };

  const handleChangeUserData = (data: EditProfileTextInputs) => {
    dispatch(updateAPIUser({ data, userId }));
  };

  return (
    <div className="edit-profile">
      <ProfileDetails
        isEditProfile
        className="edit-profile__content"
        onNextClicked={() => navigate(Paths.PROFILE)}
        onProfileImageChange={(newProfileImage) =>
          handleChangeProfileImage(newProfileImage)
        }
        onUserDataChange={(newUserData) => {
          handleChangeUserData(newUserData);
        }}
        initialState={{
          username: username || "",
          firstName: firstName || "",
          lastName: lastName || "",
          phoneNumber: phoneNumber || "",
          email: email || "",
          profileImage: null,
          preferredContact: preferredContact || PreferredContact.EMAIL,
        }}
        cancelPath={Paths.PROFILE}
      />
    </div>
  );
};
