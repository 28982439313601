import { ContestsPickDetails } from "./request";

export enum ContestStatus {
  ACTIVE = "Active",
  COMPLETED = "Completed",
  UPCOMING = "Upcoming",
}

export enum MyContestMainTab {
  IN_PROGRESS = "in-progress",
  PREVIOUS = "previous",
}

export enum MyContestSmTab {
  SLATE = "slate",
  MY_BETS = "my-bets",
  LEADERBOARD = "leaderboard",
}

export interface TeamSlate {
  teamName: string;
  teamImageUrl: string;
  payout: number;
  teamPointSpread: number;
  moneyLine: number;
  overUnder: number;
  noSpreadTeamMoneyline: number;
}

export enum TeamType {
  HOME = "home",
  AWAY = "away",
  OVER = "over",
  UNDER = "under",
}

export interface ClickedSlateData {
  betType: WagerTypes;
  team: TeamType;
  tournament: ContestsPickDetails;
  contestId: number;
}

export interface GetGames {
  gameDate: string;
  sport: string;
  days: number;
}

export interface GetGamesResponse {
  AwayAbbrev: string;
  AwayConference: string;
  AwayDivision: string;
  AwayLocation: string;
  AwayLogoUrl: string;
  AwayNickName: string;
  AwayTeamID: string;
  AwayTeamMoneyLine: number;
  AwayTeamOutcome: string;
  AwayTeamScore: number;
  AwayTeamShortName: string;
  Created: string;
  GameDate: string;
  GameID: string;
  GameName: string;
  HomeAbbrev: string;
  HomeConference: string;
  HomeDivision: string;
  HomeLocation: string;
  HomeLogoUrl: string;
  HomeNickName: string;
  HomeTeamID: string;
  HomeTeamMoneyLine: number;
  HomeTeamOutcome: string;
  HomeTeamScore: number;
  HomeTeamShortName: string;
  ID: number;
  Interval: string;
  Label: string;
  NoSpreadAwayTeamMoneyLine: number;
  NoSpreadHomeTeamMoneyLine: number;
  On: string;
  OverPayout: number;
  OverUnder: number;
  Period: number;
  PeriodLabel: string;
  PointSpread: number;
  Score: string;
  ScoreDifferential: number;
  ScoreLine: string;
  Season: string;
  Slug: string;
  Sport: string;
  Status: string;
  Timestamp: number;
  UnderPayout: number;
  Updated: string;
  Vendor: string;
  WinningTeam: string;
  WinningTeamID: string;
}

export interface CreateContest {
  contestType: string;
  maxContestants: number;
  prizePool: number;
  perContestantBudget: number;
  name: string;
  contestPicks: { gameId: string }[];
  acceptingEntries: boolean;
  distributedPrizes: string;
}

export enum Sport {
  NFL = "NFL",
  NBA = "NBA",
  MLB = "MLB",
  MULTISPORT = "All",
}

export interface CreateContestResponse {
  id: number;
  name: string;
  status: string;
  contestType: string;
  startDT: string;
  endDT: string;
  entryDeadline: string;
  picksDue: string;
  perContestantBudget: number;
  maxContestants: number;
  prizePool: number;
  numPicksReq: number;
  acceptingEntries: boolean;
  created: string;
  updated: string;
  distributedPrizes?: string;
}

export enum WagerTypes {
  MONEYLINE = "MoneyLine",
  POINTS_SPREAD = "PointSpread",
  TOTAL = "Total",
}

export enum APIWagerTypes {
  SPREAD = "1",
  MONEYLINE = "0",
  TOTAL = "2",
}

export enum MyBetVariants {
  PENDING = "pending",
  WON = "won",
  LOST = "lost",
}

export enum WagerGameStatuses {
  CLOSED = "closed",
  UPCOMING = "upcoming",
}
