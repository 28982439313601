import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { joinContest } from "services/my-contests-service";
import { Button } from "components/button";
import { LoaderSpinner } from "components/loader-spinner";
import {
  selectContests,
  selectContestsRequestState,
  selectSelectedContest,
  setUpcomingContests,
} from "store/app-slice";
import { fetchMyContests } from "store/my-contest-slice";
import { formatToCurrency } from "helpers/format-to-currency";
import { setSpecificContestEntered } from "helpers/set-specific-contest-entered";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { RequestState } from "types/request";
import "./contest-footer.scss";

interface ContestFooterProps {
  entry?: number;
  free?: boolean;
}

export const ContestFooter: React.FC<ContestFooterProps> = ({ entry, free = true }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { upcomingContests } = useAppSelector(selectContests);
  const selectedContest = useAppSelector(selectSelectedContest);
  const isFull =
    !!selectedContest &&
    selectedContest?.contest.maxContestants <=
      selectedContest?.contestantStats.totalEntries;
  const requestLobbyContestsState = useAppSelector(selectContestsRequestState);
  const areContestsLoading = [RequestState.PENDING, RequestState.IDLE].includes(
    requestLobbyContestsState
  );
  if (!selectedContest) {
    return null;
  }
  const { hasEntered } = selectedContest.contestantStats;
  const entryValue =
    free || !entry ? "FREE" : formatToCurrency({ value: entry, withDollar: true });

  const onClickHandler = async () => {
    if (hasEntered) {
      navigate(`/my-contests/in-progress/${selectedContest.contest.id}/slate`, {
        replace: true,
      });

      return;
    }
    try {
      await joinContest(selectedContest.contest.id);
      dispatch(fetchMyContests());
    } catch {
      toast.error("Failed to join contest");
      return;
    }

    navigate(`/my-contests/in-progress/${selectedContest.contest.id}/slate`, {
      replace: true,
    });
    const newContests = setSpecificContestEntered(
      upcomingContests,
      selectedContest.contest.id
    );
    toast.success("You have joined the tournament successfully");
    dispatch(setUpcomingContests({ upcomingContests: newContests }));
  };

  let contentToRender: JSX.Element | null;
  if (areContestsLoading) {
    contentToRender = (
      <div className="footer__loader-wrapper">
        <LoaderSpinner size="tiny" />
      </div>
    );
  } else if (!hasEntered && isFull) {
    contentToRender = <div className="footer__full">FULL</div>;
  } else {
    contentToRender = (
      <Button className="footer__button" onClick={onClickHandler}>
        {hasEntered ? "CONTINUE" : "ENTER"}
      </Button>
    );
  }

  return (
    <div className="footer">
      <div className="footer__entry">
        <div className="footer__value">{entryValue}</div>
        <div className="footer__property">ENTRY</div>
      </div>
      {contentToRender}
    </div>
  );
};
