import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { BottomNavigation } from "components/bottom-navigation";
import { SendAgainButton } from "components/button";
import { CodeSelector } from "components/code-selector";
import { CommonForm } from "components/common-form";
import {
  fetchSession,
  fetchSignIn,
  selectAuthPayload,
  selectRequestState,
} from "store/auth";
import { setSelectedMyContest } from "store/my-contest-slice";
import { resetState } from "store/register-slice";
import { ERROR_REQUIRED, UNKNOWN_ERROR_FALLBACK } from "helpers/constants/errors";
import { isCodeIncludingSpace } from "helpers/is-code-including-space";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { RequestState } from "types/request";
import { Paths } from "types/router";
import "./code-verification.scss";

interface CodeVerificationInput {
  verificationCode: string;
}

export interface CodeVerificationProps {
  email: string;
  password: string;
}

export const CodeVerification: React.FC<CodeVerificationProps> = ({
  email,
  password,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<CodeVerificationInput>({
    criteriaMode: "firstError",
    defaultValues: {
      verificationCode: "",
    },
  });

  const { userId, isVerified } = useAppSelector(selectAuthPayload);
  const requestState = useAppSelector(selectRequestState);

  const handleSignIn = () => {
    if (!userId) {
      dispatch(fetchSession());
      dispatch(setSelectedMyContest(null));
      navigate(Paths.ONBOARDING);
    }
  };
  const getCode = (data: CodeVerificationInput) => {
    return Object.values(data).reduce((a, b) => a.concat(b));
  };

  const onSubmit = async (
    data: CodeVerificationInput,
    event?: React.BaseSyntheticEvent<object, unknown, unknown>
  ) => {
    if (event) {
      event.preventDefault();
    }

    await Auth.confirmSignUp(email, getCode(data))
      .then(() => {
        toast.success("Code has been verified");
        dispatch(
          fetchSignIn({
            email: email,
            password: password,
            onSignIn: handleSignIn,
            onNotConfirmedUser: () => dispatch(resetState()),
          })
        );
      })
      .catch((error) => toast.error(error.message || UNKNOWN_ERROR_FALLBACK));
  };

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then(() => toast.success("Code has been send"))
      .catch((error) => toast.error(error.message || UNKNOWN_ERROR_FALLBACK));
  };

  if (requestState === RequestState.ERROR) {
    return <Navigate to={Paths.SIGN_IN} />;
  }

  if (isVerified) {
    return <Navigate to={Paths.LOBBY} />;
  }

  const handleClipboardEvent = (verificationCode: string) => {
    setValue("verificationCode", verificationCode);
  };

  return (
    <div className="code-verification">
      <CommonForm
        title="The sign up verification"
        subtitle={
          <div>
            Code have been send to{" "}
            <span className="code-verification__subtitle">{email}</span>
          </div>
        }
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <BottomNavigation
            submitText="VERIFY"
            nextButtonActive={!errors.verificationCode}
            cancelPath={Paths.SIGN_IN}
            cancelText="CANCEL"
            isSubmitType
          />
        }
      >
        <div className="code-verification__content">
          <Controller
            control={control}
            name="verificationCode"
            rules={{
              validate: (value) => isCodeIncludingSpace(value),
              minLength: { value: 6, message: ERROR_REQUIRED },
              required: ERROR_REQUIRED,
            }}
            render={({ field: { onChange, value } }) => (
              <CodeSelector
                onChange={onChange}
                onCodePaste={(verificationCode) => {
                  handleClipboardEvent(verificationCode);
                  trigger("verificationCode");
                }}
                value={value}
                numberOfDigits={6}
                error={!!errors.verificationCode}
                errorText={errors.verificationCode?.message}
                errorTextClassName="code-verification__error-message"
              />
            )}
          />
          <span className="code-verification__resend-code">
            Did the code not arrive?
            <SendAgainButton onClick={resendCode} />
          </span>
        </div>
      </CommonForm>
    </div>
  );
};
