import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getContestsByStatus } from "services/my-contests-service";
import { RootState } from "store/main-store";
import { ClickedSlateData, ContestStatus } from "types/contest";
import { ContestsItemType, RequestState } from "types/request";
import { SortOption, SortOptions } from "types/sort-contests";

export interface AppStoreState {
  payload: AppPayload;
  requestState: RequestState;
}

interface AppPayload {
  topbarTitle: string;
  activeContests: ContestsItemType[];
  completedContests: ContestsItemType[];
  upcomingContests: ContestsItemType[];
  allContests: ContestsItemType[];
  openContests: ContestsItemType[];
  selectedContest: ContestsItemType | null;
  selectedSlate: ClickedSlateData | null;
  previousPage: string | null;
  showScreenDarkenMenu: boolean;
  showScreenDarkenLeaderboard: boolean;
  sortOption: SortOption;
}

export const initialState: AppStoreState = {
  payload: {
    topbarTitle: "",
    activeContests: [],
    completedContests: [],
    upcomingContests: [],
    allContests: [],
    openContests: [],
    selectedContest: null,
    selectedSlate: null,
    previousPage: null,
    showScreenDarkenMenu: false,
    showScreenDarkenLeaderboard: false,
    sortOption: SortOptions[0],
  },
  requestState: RequestState.IDLE,
};

export const fetchContests = createAsyncThunk(
  "app/fetchContests",
  async (_, thunkAPI) => {
    try {
      const getEveryStatusContests = await Promise.all([
        getContestsByStatus(ContestStatus.COMPLETED),
        getContestsByStatus(ContestStatus.ACTIVE),
        getContestsByStatus(ContestStatus.UPCOMING),
      ]);

      const [completedContests, activeContests, upcomingContests] =
        getEveryStatusContests;
      const allContests = [...completedContests, ...activeContests, ...upcomingContests];
      const openContests = [...activeContests, ...upcomingContests];

      return {
        completedContests,
        activeContests,
        upcomingContests,
        allContests,
        openContests,
      };
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTopbarTitle: (state, action: PayloadAction<string>) => {
      state.payload.topbarTitle = action.payload;
      state.payload.previousPage = null;
    },
    setPreviousPage: (state, action: PayloadAction<string>) => {
      state.payload.previousPage = action.payload;
    },
    setShowScreenDarkenMenu: (state, action: PayloadAction<boolean>) => {
      state.payload.showScreenDarkenMenu = action.payload;
    },
    setShowScreenDarkenLeaderboard: (state, action: PayloadAction<boolean>) => {
      state.payload.showScreenDarkenLeaderboard = action.payload;
    },
    setUpcomingContests: (
      state,
      action: PayloadAction<{
        upcomingContests: ContestsItemType[];
      }>
    ) => {
      const { upcomingContests } = action.payload;

      state.payload.upcomingContests = upcomingContests;
    },
    setSelectedContest: (state, action: PayloadAction<ContestsItemType>) => {
      state.payload.selectedContest = action.payload;
    },
    setSelectedSlate: (state, action: PayloadAction<ClickedSlateData | null>) => {
      state.payload.selectedSlate = action.payload;
    },
    setSortOption: (state, action: PayloadAction<SortOption>) => {
      state.payload.sortOption = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContests.pending, (state) => {
      state.requestState = RequestState.PENDING;
    });
    builder.addCase(fetchContests.fulfilled, (state, action) => {
      state.requestState = RequestState.SUCCESS;
      state.payload = { ...state.payload, ...action.payload };
    });
    builder.addCase(fetchContests.rejected, (state) => {
      state.requestState = RequestState.ERROR;
    });
  },
});

export const {
  setTopbarTitle,
  setSelectedContest,
  setPreviousPage,
  setShowScreenDarkenMenu,
  setShowScreenDarkenLeaderboard,
  setSortOption,
  setSelectedSlate,
  setUpcomingContests,
} = appSlice.actions;

export const selectTopbarTitle = (state: RootState) =>
  state.appReducer.payload.topbarTitle;
export const selectPreviousPage = (state: RootState) =>
  state.appReducer.payload.previousPage;
export const selectShowScreenDarkenMenu = (state: RootState) =>
  state.appReducer.payload.showScreenDarkenMenu;
export const selectShowScreenDarkenLeaderboard = (state: RootState) =>
  state.appReducer.payload.showScreenDarkenLeaderboard;
export const selectContests = (state: RootState) => {
  const { activeContests, completedContests, upcomingContests } =
    state.appReducer.payload;
  return { activeContests, completedContests, upcomingContests };
};
export const selectSelectedSlate = (state: RootState) =>
  state.appReducer.payload.selectedSlate;

export const selectAllContests = (state: RootState) => {
  const { allContests } = state.appReducer.payload;
  return allContests;
};
export const selectUpcomingContests = (state: RootState) =>
  state.appReducer.payload.upcomingContests;

export const selectOpenContests = (state: RootState) => {
  const { openContests } = state.appReducer.payload;
  return openContests;
};

export const selectSelectedContest = (state: RootState) =>
  state.appReducer.payload.selectedContest;

export const selectSortOption = (state: RootState) => state.appReducer.payload.sortOption;

export const selectContestsRequestState = (state: RootState) =>
  state.appReducer.requestState;

const persistedReducer = appSlice.reducer;

export default persistedReducer;
