import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { createContest } from "services/my-contests-service";
import { Button } from "components/button";
import { LabeledInput } from "components/inputs";
import { fetchContests } from "store/app-slice";
import { accumulateObjectFields } from "helpers/accumulate-object-fields";
import {
  DateFormats,
  ERROR_ADMIN_PANEL,
  REGEX_NON_DIGIT_WITH_LEADING_ZERO,
} from "helpers/constants";
import { formatToCurrency } from "helpers/format-to-currency";
import { formatToPosition } from "helpers/format-to-position";
import { parsePrizesObject } from "helpers/parse-prizes";
import { useAppDispatch } from "hooks/store";
import { GetGamesResponse, Sport } from "types/contest";
import { Paths } from "types/router";
import { AddIcon } from "assets/icons";
import { MinusIcon } from "assets/icons/minus-icon";
import "./admin-panel-create-form.scss";

interface AdminPanelCreateFormProps {
  games: GetGamesResponse[];
  contestType: string;
  onCancel: () => void;
  onCreated: () => void;
}

interface AdminPanelCreateFormInputs {
  name: string;
  contestType: Sport;
  maxContestants: number;
  prizePool: number;
  perContestantBudget: number;
  pickedGames: string[];
  acceptingEntries: boolean;
}

export const AdminPanelCreateForm: React.FC<AdminPanelCreateFormProps> = ({
  games,
  onCancel,
  contestType,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<AdminPanelCreateFormInputs>({
    defaultValues: {
      name: "",
      maxContestants: 20,
      prizePool: 10_000,
      perContestantBudget: 100_000,
      pickedGames: [],
      acceptingEntries: true,
    },
    mode: "onTouched",
  });

  const pickedGamesWatch = watch("pickedGames");
  const areAllGamesPicked = pickedGamesWatch.length === games.length;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [prizes, setPrizes] = useState([{ place: "", prize: "" }]);

  const handleChange = useCallback(
    (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const newPrizes = [...prizes];

      newPrizes[index] = {
        ...newPrizes[index],
        place: (index + 1).toString(),
        prize: event.target.value.replace(REGEX_NON_DIGIT_WITH_LEADING_ZERO, ""),
      };

      setPrizes(newPrizes);
    },
    [prizes]
  );

  const deletePrizeInput = useCallback(
    (index: number) => {
      const newPrizes = [...prizes];
      newPrizes.splice(index, 1);

      setPrizes(newPrizes);
    },
    [prizes]
  );

  const addPrizeInput = () => {
    setPrizes([...prizes, { place: "", prize: "" }]);
  };

  const totalPrizes = useMemo(() => {
    const filteredPrizes = prizes.filter(({ prize }) => prize !== "");

    return accumulateObjectFields(filteredPrizes, "prize", 0);
  }, [prizes]);

  const onSubmit = async ({
    maxContestants,
    prizePool,
    perContestantBudget,
    name,
    acceptingEntries,
    pickedGames,
  }: AdminPanelCreateFormInputs) => {
    const contestPicks = pickedGames.map((pickedGame) => ({ gameId: pickedGame }));

    const prizesString = parsePrizesObject(prizes);

    if (contestPicks.length) {
      const createdContest = await createContest({
        contestType,
        maxContestants,
        prizePool,
        perContestantBudget,
        name,
        contestPicks,
        acceptingEntries,
        distributedPrizes: prizesString,
      });

      if (!!createdContest) {
        toast.success("Contest has been created");
        dispatch(fetchContests());
        navigate(Paths.LOBBY);
      } else {
        toast.error("Couldn't create the contest");
      }
    } else {
      toast.error("Pick at least 1 game");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="admin-panel-create-form">
      <LabeledInput
        maxLength={43}
        labelText="Contest name"
        error={!!errors.name}
        errorText={errors.name?.message}
        {...register("name", {
          required: ERROR_ADMIN_PANEL,
        })}
      />
      <div className="admin-panel-create-form__budget-contestants-wrapper">
        <LabeledInput
          labelText="Max contestants"
          type="number"
          error={!!errors.maxContestants}
          errorText={errors.maxContestants?.message}
          {...register("maxContestants", {
            valueAsNumber: true,
            required: ERROR_ADMIN_PANEL,
            validate: (value) => value > 0,
          })}
        />
        <LabeledInput
          labelText="Contest budget"
          type="number"
          error={!!errors.perContestantBudget}
          errorText={errors.perContestantBudget?.message}
          {...register("perContestantBudget", {
            valueAsNumber: true,
            required: ERROR_ADMIN_PANEL,
            validate: (value) => value > 0,
          })}
        />
      </div>

      <div className="admin-panel-create-form__place-label">
        Prize Pool: {formatToCurrency({ value: totalPrizes, withDollar: true })}
      </div>
      {prizes.map((element, index) => (
        <div className="admin-panel-create-form__prizes" key={index}>
          <label className="admin-panel-create-form__place-label">
            {formatToPosition(index + 1)}
          </label>
          <LabeledInput
            value={element.prize}
            wrapperClassName="admin-panel-create-form__prizes-input"
            type="text"
            maxLength={30}
            onChange={(event) => handleChange(index, event)}
          />
          {index ? (
            <Button
              variant="clear"
              type="button"
              onClick={() => deletePrizeInput(index)}
              className="admin-panel-create-form__prize-delete"
            >
              <MinusIcon />
            </Button>
          ) : null}
        </div>
      ))}
      <Button
        variant="clear"
        onClick={() => addPrizeInput()}
        type="button"
        className="admin-panel-create-form__add-prize-button"
        childrenClassName="admin-panel-create-form__button-wrapper"
      >
        <div className="admin-panel-create-form__add-circle">
          <AddIcon color="White" />
        </div>
        <p className="admin-panel-create-form__add-text">Add place</p>
      </Button>

      <div className="admin-panel-create-form__acceptance">
        <label className="profile-details__label">Accepting entries</label>
        <input type="checkbox" {...register("acceptingEntries")} />
      </div>
      <div>
        <div>
          <label className="profile-details__label">Pick games:</label>
          <label className="admin-panel-create-form__label">
            <span>Choose all</span>
            <input
              type="checkbox"
              checked={pickedGamesWatch.length === games.length}
              onChange={() => {
                if (areAllGamesPicked) {
                  setValue("pickedGames", []);
                } else {
                  setValue(
                    "pickedGames",
                    games.map((game) => game.GameID)
                  );
                }
              }}
            />
          </label>
        </div>
        <div className="admin-panel-create-form__games">
          <div className="admin-panel-create-form__games-content">
            {games.map(({ GameID, GameDate, Label }) => {
              const usersOffsetInHours = Math.round(dayjs().utcOffset() / 60);
              const gameDate =
                usersOffsetInHours >= 0
                  ? dayjs(GameDate).add(usersOffsetInHours, "h")
                  : dayjs(GameDate).subtract(Math.abs(usersOffsetInHours), "h");
              return (
                <label key={GameID} className="admin-panel-create-form__label">
                  <span>{`${Label} - ${gameDate
                    .tz()
                    .format(DateFormats.DATE_AND_TIME)}`}</span>
                  <input type="checkbox" {...register("pickedGames")} value={GameID} />
                </label>
              );
            })}
          </div>
        </div>
      </div>
      <div className="admin-panel-create-form__navigation">
        <Button
          variant="link"
          className="admin-panel-create-form__cancel"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="primary-rounded" className="admin-panel-create-form__submit">
          Submit
        </Button>
      </div>
    </form>
  );
};
