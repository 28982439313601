import { formatToPosition } from "./format-to-position";

export const parsePrizesString = (prizesString: string) => {
  if (prizesString === "") {
    return [];
  }
  const mappedPrizes = prizesString.split("|");

  return mappedPrizes.map((prize) => {
    const [place, prizeValue] = prize.split(":");

    return {
      place: parseFloat(formatToPosition(parseInt(place))),
      prize: parseFloat(prizeValue),
    };
  });
};

export const parsePrizesObject = (prizes: { place: string; prize: string }[]) => {
  let distributedPrizesString = "";

  prizes.forEach(({ prize, place }, index) => {
    if (prize !== "") {
      distributedPrizesString = `${distributedPrizesString}|${place}:${prize}`;
    } else {
      distributedPrizesString = `${distributedPrizesString}|${index + 1}:0`;
    }
  });

  distributedPrizesString = distributedPrizesString.slice(
    1,
    distributedPrizesString.length
  );

  return distributedPrizesString;
};
