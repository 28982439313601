import { selectAuthPayload } from "store/auth";
import { useAppSelector } from "hooks/store";
import { MenuPath } from "types/menu";
import { Paths, PathsLobby, PathsMyContests, PathsProfile } from "types/router";
import {
  AddIcon,
  AwardIcon,
  BankIcon,
  HomeIcon,
  LogoutIcon,
  NoteIcon,
  PersonFilledIcon,
} from "assets/icons";

export const usePaths = () => {
  const { roles } = useAppSelector(selectAuthPayload);

  const paths: readonly MenuPath[] = [
    {
      rootPath: PathsLobby.ROOT,
      path: Paths.LOBBY,
      text: "Lobby",
      icon: HomeIcon,
    },
    {
      rootPath: PathsMyContests.ROOT,
      path: Paths.CONTESTS,
      text: "My Contests",
      icon: AwardIcon,
    },
    {
      rootPath: PathsProfile.ROOT,
      path: Paths.PROFILE,
      text: "Profile",
      icon: PersonFilledIcon,
    },
    {
      rootPath: Paths.ACCOUNT,
      path: Paths.ACCOUNT,
      text: "My Account",
      icon: BankIcon,
      comingSoon: true,
    },
    {
      rootPath: Paths.ADMIN_PANEL,
      path: Paths.ADMIN_PANEL,
      text: "Create contest",
      icon: AddIcon,
      isVisible: roles.includes("Admin"),
    },
    {
      rootPath: Paths.TERMS,
      path: Paths.TERMS,
      text: "Terms & Privacy",
      icon: NoteIcon,
      hideIconOnMd: true,
    },
    {
      rootPath: Paths.SIGN_IN,
      path: Paths.SIGN_IN,
      text: "Logout",
      icon: LogoutIcon,
    },
  ] as const;

  return paths;
};
