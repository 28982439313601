import { RouteProps } from "react-router-dom";
import { AppRouteWrapperProps } from "components/app-routes";

export type AllPaths = Paths | PathsProfile | PathsLobby | PathsMyContests;

export enum Paths {
  HOME = "/",
  LOBBY = "/lobby/popular/unselected/details",
  CONTESTS = "/my-contests/in-progress",
  PROFILE = "/profile/contests",
  ACCOUNT = "/my-account",
  TERMS = "/terms",
  SIGN_UP = "/sign-up",
  SIGN_IN = "/sign-in",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  ONBOARDING = "/onboarding",
  SIGN_UP_STEPS = "/sign-up-steps",
  CODE_VERIFICATION = "/code-verification",
  EDIT_PROFILE = "/edit-profile",
  ADMIN_PANEL = "/admin-panel",
}

export enum PathsProfile {
  ROOT = "/profile",
  STATS = "stats",
  STATS_ABSOLUTE = "/profile/stats",
  CONTESTS = "contests",
}
export enum PathsLobby {
  ROOT = "lobby",
  DETAILS = "details",
  DETAILS_WILD_CARD = "details/*",
  PRIZES = "prizes",
  GAMES = "games",
  RULES = "rules",
  SORT = "sort",
  SEARCH = "search",
  PARAM_CATEGORY = "/lobby/:category",
  PARAM_CATEGORY_CONTESTS_ID = "/lobby/:category/:contestId/*",
  SORT_ABSOLUTE = "/lobby/popular/unselected/details/sort",
  SEARCH_ABSOLUTE = "/lobby/popular/unselected/details/search",
}

export enum PathsMyContests {
  ROOT = "/my-contests",
  IN_PROGRESS_ABSOLUTE = "/my-contests/in-progress",
  PREVIOUS_ABSOLUTE = "/my-contests/previous",
  ACTIVE = "in-progress",
  PREVIOUS = "previous",
  PARAM_CONTESTS = "/my-contests/:contestType/",
  PARAM_CATEGORY_CONTEST_ID = "/my-contests/:contestType/:contestId/*",
  SLATE = "slate",
  MY_BETS = "my-bets",
  LEADERBOARD = "leaderboard",
  SORT = "sort",
  SEARCH = "search",
  SORT_ABSOLUTE = "/my-contests/in-progress/sort",
  SEARCH_ABSOLUTE = "/my-contests/in-progress/search",
}

export interface AppRoute extends Omit<RouteProps, "element">, AppRouteWrapperProps {
  children?: RouteProps[];
}
