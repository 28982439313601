import { Navigate } from "react-router-dom";
import { CodeVerification } from "components/code-verification";
import { selectRegisterUser } from "store/register-slice";
import { useAppSelector } from "hooks/store";
import { Paths } from "types/router";
import "./code-verification.scss";

export const CodeVerificationPage: React.FC = () => {
  const { email, password } = useAppSelector(selectRegisterUser);

  return (
    <div className="code-verification-page">
      {!email ? (
        <Navigate to={Paths.SIGN_IN} replace />
      ) : (
        <CodeVerification email={email} password={password} />
      )}
    </div>
  );
};
