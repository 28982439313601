import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  SVGProps,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";
import { Button } from "components/button";
import "./input.scss";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  iconColor?: "gray" | "black";
  rootClassName?: string;
  error?: boolean;
  errorText?: string;
  isLongError?: boolean;
  rounded?: boolean;
  customPasswordError?: ReactNode;
  childInputRef?: ForwardedRef<HTMLInputElement>;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      icon,
      iconColor = "gray",
      children,
      className,
      rootClassName,
      name,
      type,
      error,
      errorText,
      isLongError,
      rounded,
      customPasswordError,
      childInputRef,
      ...restProps
    },
    ref
  ) => {
    const Icon = icon;
    const [inputType, setInputType] = useState(type);

    useEffect(() => {
      setInputType(type);
    }, [type]);
    const onPasswordButtonClick = () =>
      setInputType((prev) => (prev === "password" ? "text" : "password"));
    return (
      <div className={clsx("input", rootClassName)}>
        <div
          data-testid="input-wrapper"
          className={clsx(
            "input__input-wrapper",
            { "input__input-wrapper--error": error },
            { "input__input-wrapper--rounded": rounded },
            className
          )}
          ref={ref}
        >
          {Icon && (
            <Icon
              data-testid="input-icon"
              className={clsx("input__icon", `input__icon--${iconColor}`)}
            />
          )}
          <input
            ref={childInputRef}
            data-testid="input-field"
            className="input__field"
            name={name}
            type={inputType}
            {...restProps}
          >
            {children}
          </input>
          {type === "password" && (
            <Button
              className="input__password-button"
              variant="clear"
              onClick={onPasswordButtonClick}
              type="button"
            >
              {inputType === "password" ? "SHOW" : "HIDE"}
            </Button>
          )}
        </div>
        <div className="input__error-message-wrapper">
          {customPasswordError || (
            <div
              data-testid="error-message"
              className={clsx("input__error-message", {
                "input__error-message--long": isLongError,
              })}
            >
              {errorText}
            </div>
          )}
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";
