import dayjs from "dayjs";
import { GetGamesResponse } from "types/contest";
import { DateFormats } from "./constants";

const REQUIRED_API_DATA: (keyof GetGamesResponse)[] = [
  "GameID",
  "HomeNickName",
  "AwayNickName",
  "GameDate",
  "GameName",
  "HomeLogoUrl",
  "AwayLogoUrl",
];

export const validateGame = (
  game: GetGamesResponse,
  startDate: string,
  endDate: string
) => {
  const {
    AwayTeamMoneyLine,
    HomeTeamMoneyLine,
    PointSpread,
    NoSpreadAwayTeamMoneyLine,
    NoSpreadHomeTeamMoneyLine,
    OverPayout,
    UnderPayout,
    OverUnder,
    GameDate,
  } = game;
  const isSpreadValid = !!AwayTeamMoneyLine && !!HomeTeamMoneyLine && !!PointSpread;
  const isMoneyLineValid = !!NoSpreadAwayTeamMoneyLine && !!NoSpreadHomeTeamMoneyLine;
  const isTotalValid = !!OverPayout && !!UnderPayout && !!OverUnder;

  const isStartDateValid =
    startDate === dayjs().format(DateFormats.DATE)
      ? dayjs().add(30, "m").isBefore(dayjs(GameDate))
      : dayjs(startDate).isBefore(dayjs.tz(GameDate, "UTC"));

  const isEndDateValid = dayjs
    .tz(endDate)
    .add(1, "day")
    .isAfter(dayjs.tz(GameDate, "UTC"));

  return (
    isStartDateValid &&
    isEndDateValid &&
    (isSpreadValid || isMoneyLineValid || isTotalValid) &&
    REQUIRED_API_DATA.every((item) => !!game[item])
  );
};
