import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import "./router-navbar.scss";

interface NavBarItemType {
  label: string;
  url: string;
  uniqueStringPath: string;
  isActionPerforming?: ReactNode;
}

interface RouterNavbarProps {
  tabs: NavBarItemType[];
  className?: string;
  navigationClassName?: string;
}
export const RouterNavbar: React.FC<RouterNavbarProps> = ({
  tabs,
  navigationClassName,
}) => {
  const activeNavLinkRef = useRef<HTMLAnchorElement | null>(null);
  const location = useLocation();
  const [runnerPosition, setRunnerPosition] = useState<{
    x: number;
    width: number;
  }>({ x: 0, width: 0 });

  const updateRunnerBoundaries = useCallback(() => {
    const activeElement = activeNavLinkRef.current;
    if (activeElement) {
      const activeElementRects = activeElement.getBoundingClientRect();
      setRunnerPosition({
        x: activeElement.offsetLeft,
        width: activeElementRects.width,
      });
    }
  }, []);

  useEffect(() => {
    updateRunnerBoundaries();
  }, [location.pathname, updateRunnerBoundaries]);

  useEffect(() => {
    window.addEventListener("resize", updateRunnerBoundaries);

    return () => window.removeEventListener("resize", updateRunnerBoundaries);
  }, [updateRunnerBoundaries]);

  return (
    <div className={clsx("router-navbar", navigationClassName)}>
      {tabs.map(({ url, label, uniqueStringPath, isActionPerforming }) => {
        const isActive = location.pathname.includes(uniqueStringPath);
        return (
          <Link
            key={url}
            className={clsx(
              "router-navbar__link",
              isActive && "router-navbar__link--active"
            )}
            to={url}
            ref={(refElement) => {
              if (isActive) {
                activeNavLinkRef.current = refElement;
              }
            }}
          >
            {label}
            {isActionPerforming && (
              <div
                className={clsx("router-navbar__dot", {
                  "router-navbar__dot--active": isActive,
                })}
              />
            )}
          </Link>
        );
      })}
      <div
        className="router-navbar__runner"
        style={{
          width: `${runnerPosition.width}px`,
          left: `${runnerPosition.x - 1}px`,
        }}
      />
    </div>
  );
};
