import { MyBets } from "components/my-bets";
import { selectParlay } from "store/my-contest-slice";
import { sumPayoutAndWager } from "helpers/sum-payout-and-wager";
import { useAppSelector } from "hooks/store";
import "./my-bets-current-user.scss";

export const MyBetsCurrentUser = () => {
  const parlay = useAppSelector(selectParlay);

  const totalWagerAndPayout = sumPayoutAndWager(parlay?.wagers || []);
  return (
    <MyBets
      totalPayout={totalWagerAndPayout.totalPayout}
      totalWager={totalWagerAndPayout.totalWager}
      itemToMap={parlay}
    />
  );
};
