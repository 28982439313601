import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileDetailsTextInputs } from "types/profile-details";
import { RegisterUser } from "types/user";
import { RootState } from "./main-store";

export const initialState: RegisterUser = {
  email: "",
  password: "",
  birthDate: {
    year: "",
    month: "",
    day: "",
  },
  username: "",
  firstName: "",
  lastName: "",
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setEmailAndPassword: (
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setBirthDate: (
      state,
      action: PayloadAction<{ year: string; month: string; day: string }>
    ) => {
      state.birthDate = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<ProfileDetailsTextInputs>) => {
      const { username, firstName, lastName } = action.payload;
      state.username = username;
      state.firstName = firstName;
      state.lastName = lastName;
    },
    resetState: () => initialState,
  },
});

export const { setEmailAndPassword, setEmail, setBirthDate, setUserDetails, resetState } =
  registerSlice.actions;

export const selectRegisterUser = (state: RootState) => state.registerStateReducer;

export const registerStateReducer = registerSlice.reducer;
