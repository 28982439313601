import { SVGProps } from "react";

export const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path d="M9 11h8.175l-2.55-2.55L16 7l5 5-5 5-1.375-1.45 2.55-2.55H9Zm3-8v2H5v14h7v2H5q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3Z" />
  </svg>
);
