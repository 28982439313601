import { ContentWrapper } from "components/content-wrapper";
import { ImageLayout } from "components/image-layout";
import { ResetPasswordContent } from "./reset-password-content";
import "./reset-password.scss";

export const ResetPasswordPage = () => {
  return (
    <div className="reset-password">
      <ImageLayout />
      <ContentWrapper>
        <ResetPasswordContent />
      </ContentWrapper>
    </div>
  );
};
